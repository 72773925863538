import request from "@/common/utils/request.js";
import { WebClient } from "@/api/webClient";
const webClient = new WebClient();
export class AnswerClient extends WebClient {
  /* --------试卷包-------------------------------- */
  /** 试卷包列表
   * status 2未购买 1已购买
   */
  async selectPageList(
    pageSize,
    pageNum,
    kind,
    majorId,
    typeId,
    userId,
    courseId,
    name
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        typeId: typeId,
        userId: userId,
        courseId,
        name,
      }
    );
    const data = await request({
      url: `/ques/business/getTenantPackageListToPc`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 试卷包列表
   * status 2未购买 1已购买
   */
  async getNewTenantPackageListToPc(
    pageSize,
    pageNum,
    kind,
    stage,
    majorId,
    typeId,
    userId,
    courseId,
    name
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        zStage: stage,
        majorId: majorId,
        typeId: typeId,
        userId: userId,
        courseId,
        name,
      }
    );
    const data = await request({
      url: `/ques/business/getNewTenantPackageListToPc`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 我的试卷包
   * status 2未购买 1已购买
   */
  async getPcMyPackage(
    pageSize,
    pageNum,
    kind,
    majorId,
    typeId,
    userId,
    IsPurchase
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        typeId: typeId,
        userId: userId,
        IsPurchase: IsPurchase,
      }
    );
    const data = await request({
      url: `/ques/business/getPcMyPackage`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 我的错题库
   * status 2未购买 1已购买
   */
  async getMistakesPaperList(
    pageSize,
    pageNum,
    kind,
    majorId,
    typeId,
    userId,
    IsPurchase
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      }
      // {
      //   kindId: kind,
      //   majorId: majorId,
      //   typeId: typeId,
      //   userId: userId,
      //   IsPurchase: IsPurchase
      // }
    );
    const data = await request({
      url: `/ques/paperSubjectMistake/getMistakesPaperList`,
      method: "GET",
      params: params,
    });
    return data;
  }

  /** 我的错题库
   * 移除错题集
   */
  async deleteMistakeId(subjectMistakeId) {
    const data = await request({
      url: `/ques/paperSubjectMistake/deleteMistakeId?subjectMistakeId=${subjectMistakeId}`,
      method: "POST",
    });
    return data;
  }
  /** 我的错题库
   * status 2未购买 1已购买
   */
  async getMistakesSubjectList(paperId) {
    const data = await request({
      url: `/ques/paperSubjectMistake/getMistakesSubjectList?paperId=${paperId}`,
      method: "GET",
    });
    return data;
  }
  /** 试卷包详情
   * status 2未购买 1已购买
   */
  async getPackageDetails(typeId, userId, classId) {
    const params = this.combin(
      {
        typeId: typeId,
      },
      {
        userId: userId,
        classId: classId,
      }
    );
    const data = await request({
      url: `/ques/business/getPackageDetails`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /* --------试卷-------------------------------- */
  /** 试卷列表
   * status 2未购买 1已购买
   */
  async getQuestionPaperList(
    pageSize,
    pageNum,
    kind,
    majorId,
    typeId,
    userId,
    courseId,
    name
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        typeId: typeId,
        userId: userId,
        courseId,
        name,
      }
    );
    const data = await request({
      // baseURL: process.env.VUE_APP_BASE_juxue,
      url: `/ques/business/getQuestionPaperList`,
      method: "GET",
      params: params,
    });
    return data;
  }

  async getNewQuestionPaperList(params) {
    const data = await request({
      // baseURL: process.env.VUE_APP_BASE_juxue,
      url: `/ques/business/getNewQuestionPaperList`,
      method: "GET",
      params,
    });
    return data;
  }

  async selectNewPaperListJxu(
    pageSize,
    pageNum,
    kind,
    majorId,
    typeId,
    userId,
    courseId
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        typeId: typeId,
        userId: userId,
        courseId,
      }
    );
    const data = await request({
      // baseURL: process.env.VUE_APP_BASE_juxue,
      url: `/ques/questionPaper/selectNewPaperListJxu`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 我的试卷
   * status 2未购买 1已购买
   */
  async getPcMyPaper(
    pageSize,
    pageNum,
    kind,
    majorId,
    typeId,
    userId,
    IsPurchase
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        typeId: typeId,
        userId: userId,
        IsPurchase: IsPurchase,
      }
    );
    const data = await request({
      url: `/ques/business/getPcMyPaper`,
      method: "GET",
      params: params,
    });
    return data;
  }

  // 获取我的试卷包和试卷整合
  async getPcMyPackageAndPaper(params) {
    const data = await request({
      url: `/ques/business/getPcMyPackageAndPaper`,
      method: "GET",
      params,
    });
    return data;
  }
  /** 试卷详情
   * status 2未购买 1已购买
   */
  async getPaperDetails(typeId, userId, classId) {
    const params = this.combin(
      {
        typeId: typeId,
      },
      {
        userId: userId,
        classId: classId,
      }
    );
    const data = await request({
      url: `/ques/business/getPaperDetails`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /* --------虚拟考场-------------------------------- */
  /** 虚拟考场列表
   * showStatus  2 未购买  1  已购买
   */
  async getTenantPcRoomList(pageSize, pageNum, kind, majorId, type, userId) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        type: type,
        userId: userId,
      }
    );
    const data = await request({
      url: `/ques/business/getTenantPcRoomList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 我的考场
   * showStatus  2 未购买  1  已购买
   */
  async getPcMyRoom(pageSize, pageNum, kind, majorId, userId) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        userId: userId,
      }
    );
    const data = await request({
      url: `/ques/business/getPcMyRoom`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 虚拟考场详情
   * showStatus  2 未购买  1  已购买
   */
  async getPcRoomDetails(typeId, userId) {
    const params = this.combin(
      {
        typeId: typeId,
      },
      {
        userId: userId,
      }
    );
    const data = await request({
      url: `/ques/business/getPcRoomDetails`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /* --------考点练习-------------------------------- */
  /*  showStatus  2 未购买  1  已购买 */
  async getPcExamPlaceList(
    pageSize,
    pageNum,
    kind,
    majorId,
    courseId,
    chapterId,
    userId
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kindId: kind,
        majorId: majorId,
        chapterId: chapterId,
        courseId: courseId,
        userId: userId,
      }
    );
    const data = await request({
      url: `/ques/business/getPcExamPlaceList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 考点详情
   * showStatus  2 未购买  1  已购买
   */
  async getPcPlaceDetails(typeId, userId) {
    const params = this.combin(
      {
        typeId: typeId,
      },
      {
        userId: userId,
      }
    );
    const data = await request({
      url: `/ques/business/getPcPlaceDetails`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 试卷试题列表 */
  async selectPaperSubjectList(id, userId, classId, paperRoomId, examType) {
    const res = await request({
      url: `/ques/business/selectPaperSubjectList`,
      method: "GET",
      params: {
        paperId: id /*  */,
        classId: classId,
        userId: userId,
        paperRoomId: paperRoomId,
        examType: examType,
      },
    });
    return res;
  }
  /** 试卷试题列表 */
  async selectPartnershipPaperSubjectList(
    id,
    userId,
    classId,
    paperRoomId,
    examType
  ) {
    const res = await request({
      url: `/ques/business/selectPartnershipPaperSubjectList`,
      method: "GET",
      params: {
        paperId: id /*  */,
        classId: classId,
        userId: userId,
        paperRoomId: paperRoomId,
        examType: examType,
      },
    });
    return res;
  }
  /* --------课后作业-------------------------------- */
  /** 课后作业
   * showStatus  2 未购买  1  已购买
   */
  async myClassesWork(pageSize, pageNum, classesId, courseId, type, userId) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        courseId: courseId,
        classesId: classesId,
        type: type,
        userId: userId,
      }
    );
    const data = await request({
      url: `/kecheng/quesWork/myClassesWork`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /* -----试卷---提交答案-------------------------------- */
  async addPcSubjectAnswer(sub) {
    const fromData = JSON.parse(JSON.stringify(sub));
    for (const item of fromData.questionSubjectPaperVoList) {
      if (item.questionType == 1 || item.questionType == 6) {
        await item.questionUserAnswer.sort();
        item.questionUserAnswer = item.questionUserAnswer.join(",");
      } else if (item.questionType == 5) {
        for (const chil of item.childSubject) {
          if (chil.questionType == 1 || chil.questionType == 6) {
            if (chil.questionUserAnswer) {
              await chil.questionUserAnswer.sort();
            }
            chil.questionUserAnswer = chil.questionUserAnswer.join(",");
          }
        }
      }
    }
    const data = await request({
      url: `/ques/business/addPcSubjectAnswer`,
      method: "POST",
      data: fromData,
    });
    this.functionThen(data);
    return data;
  }
  /* -----试卷---保存进度-------------------------------- */
  async savePcSubjectAnswerSchedule(sub) {
    const fromData = JSON.parse(JSON.stringify(sub));
    for (const item of fromData.questionSubjectPaperVoList) {
      if (item.questionType == 1 || item.questionType == 6) {
        await item.questionUserAnswer.sort();
        item.questionUserAnswer = item.questionUserAnswer.join(",");
      } else if (item.questionType == 5) {
        for (const chil of item.childSubject) {
          if (chil.questionType == 1 || chil.questionType == 6) {
            if (chil.questionUserAnswer) {
              await chil.questionUserAnswer.sort();
            }
            chil.questionUserAnswer = chil.questionUserAnswer.join(",");
          }
        }
      }
    }
    const data = await request({
      url: `/ques/business/SavePcSubjectAnswerSchedule`,
      method: "POST",
      data: fromData,
    });
    this.functionThen(data);
    return data;
  }
  /** 查询试卷答案得分
   * isCorrect: 1 正确 0错误 */
  async getPcSubjectAnswer(paperId, roomId, examType) {
    const params = this.combin(
      {
        paperId: paperId,
        examType: examType,
      },
      {
        roomId: roomId,
      }
    );
    const data = await request({
      url: `/ques/business/getPcSubjectAnswer`,
      method: "GET",
      params: params,
    });
    return data;
  }

  async getPcNewSubjectAnswer(paperId, roomId, examType) {
    const params = this.combin(
      {
        paperId: paperId,
        examType: examType,
      },
      {
        roomId: roomId,
      }
    );
    const data = await request({
      url: `/ques/business/getPcNewSubjectAnswer`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /** 虚拟考场 查看试卷答案得分
   * isCorrect: 1 正确 0错误 */
  async goOverPcSubjectAnswer(paperId, roomId, examType) {
    const params = this.combin(
      {
        paperId: paperId,
        examType: examType,
      },
      {
        roomId: roomId,
      }
    );
    const data = await request({
      url: `/ques/business/goOverPcSubjectAnswer`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /* --------考点提交答案-------------------------------- */
  async getKdSubjectAnswer(sub) {
    const fromData = JSON.parse(JSON.stringify(sub));
    for (const item of fromData.questionSubjectPaperVoList) {
      if (item.questionType == 1) {
        item.questionUserAnswer.sort();
        item.questionUserAnswer = item.questionUserAnswer.join(",");
      } else if (item.questionType == 5) {
        for (const chil of item.childSubject) {
          if (chil.questionType == 1) {
            chil.questionUserAnswer.sort();
            chil.questionUserAnswer = chil.questionUserAnswer.join(",");
          }
        }
      }
    }
    const data = await request({
      url: `/ques/business/getKdSubjectAnswer`,
      method: "POST",
      data: fromData,
    });
    this.functionThen(data);
    return data;
  }
  /* ------------ */
  /** 我的知识套餐*/
  async myClassesList() {
    const data = await request({
      url: `/kecheng/quesWork/myClassesList`,
      method: "GET",
    });
    return data;
  }
  /** 知识套餐直播录播课(  */
  async pageListApi(classId, type) {
    const data = await request({
      url: `/kecheng/classescourse/pageListApi`,
      method: "GET",
      params: {
        classId: classId,
        type: type,
      },
    });
    return data;
  }
  /* --------每日一练-------------------------------- */
  /** 考点详情
   * showStatus  2 未购买  1  已购买
   */
  async selectRandomList(num) {
    const data = await request({
      url: `/ques/business/selectRandomList`,
      method: "GET",
      params: {
        num: num,
      },
    });
    return data;
  }
  /* 每日一练提交答案 */
  async addRandomSubjectAnswer(sub) {
    const fromData = JSON.parse(JSON.stringify(sub));
    for (const item of fromData.questionSubjectPaperVoList) {
      if (item.questionType == 1) {
        item.questionUserAnswer.sort();
        item.questionUserAnswer = item.questionUserAnswer.join(",");
      } else if (item.questionType == 5) {
        for (const chil of item.childSubject) {
          if (chil.questionType == 1) {
            chil.questionUserAnswer.sort();
            chil.questionUserAnswer = chil.questionUserAnswer.join(",");
          }
        }
      }
    }
    const data = await request({
      url: `/ques/business/addRandomSubjectAnswer`,
      method: "POST",
      data: fromData,
    });
    this.functionThen(data);
    return data;
  }
  async selectQuestionKeChengList(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/selectQuestionKeChengList`,
      method: "GET",
      params,
    });
    return data;
  }
  async getQuestionKeCheng(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/getQuestionKeCheng`,
      method: "GET",
      params,
    });
    return data;
  }
  async selectQuestionClassesList(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/selectQuestionClassesList`,
      method: "GET",
      params,
    });
    return data;
  }
  async selectVipSubjectAnswer(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/selectVipSubjectAnswerPC`,
      method: "GET",
      params,
    });
    return data;
  }
    async getPaperTypeList(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/getPaperTypeList`,
      method: "GET",
      params,
    });
    return data;
  }
  async collectSubject(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/collectSubject`,
      method: "POST",
      data: params,
    });
    this.functionThen(data);

    return data;
  }
  async addVipQuestionResultAnswer(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/addVipQuestionResultAnswer`,
      method: "POST",
      data: params,
    });
    this.functionThen(data);

    return data;
  }
  async getFallibilityData(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/getFallibilityData`,
      method: "GET",
      params,
    });

    return data;
  }
  async selectFallibilitySubjectIdListAnswerPC(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/selectFallibilitySubjectIdListAnswerPC`,
      method: "GET",
      params,
    });

    return data;
  }
  async getFallibilityTypeSubject(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/getFallibilityTypeSubject`,
      method: "GET",
      params,
    });

    return data;
  }
    async deleteFallibilityList(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/deleteFallibilityList`,
      method: "POST",
       params,
    });
    this.functionThen(data);

    return data;
  }
    async selectVipSubjectIdListAnswerPC(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/selectVipSubjectIdListAnswerPC`,
      method: "GET",
      params,
    });

    return data;
  }
      async getMakerTypeSubject(params) {
    const data = await request({
      url: `/kecheng/vipQuestion/getMakerTypeSubject`,
      method: "GET",
      params,
    });

    return data;
  }
        async selectVipQuestionResult(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/selectVipQuestionResult`,
      method: "GET",
      params,
    });

    return data;
  }
          async selectVipQuestionResultTime(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/selectVipQuestionResultTime`,
      method: "GET",
      params,
    });

    return data;
  }
            async deleteVipQuestionResult(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/deleteVipQuestionResult`,
      method: "POST",
      data:params,
    });
    this.functionThen(data);

    return data;
  }
              async selectVipResultAnalysisPC(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/selectVipResultAnalysisPC`,
      method: "GET",
      params,
    });

    return data;
  }
                async selectVipResultDay(params) {
    const data = await request({
      url: `/kecheng/vipQuestionResult/selectVipResultDay`,
      method: "GET",
      params,
    });

    return data;
  }
}
