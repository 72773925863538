<template>
  <!-- 底栏 -->
  <div class="foot">
    <div class="left">
      <el-image class="left-logo" :src="copyright.qyLogo" fit="contain"></el-image>
      <div class="left-line"></div>
      <!-- <div class="left-text">{{ copyright.name }}版权所有</div> -->
      <div class="left-text" v-html="copyright.copyright" />
    </div>
    <div class="font-line" />
    <div class="content">
      <div class="content-left">
        <div class="title">快速导航</div>
        <div class="text" :class="path == `/` ? 'on ' : ''" @click="gopage(`/`, false)">
          网校首页
        </div>
        <div class="text" :class="path == `/all/mycurriculum` ? 'on ' : ''" @click="gopage(`/all/mycurriculum`, true)">
          我的课程
        </div>
        <div class="text" :class="path == `/my/accountSetting` ? 'on ' : ''"
          @click="gopage(`/my/accountSetting?type=4`, true)">
          关于我们
        </div>
      </div>
      <div class="content-right">
        <div class="title">联系我们</div>
        <div class="box" v-if="copyright.mobile">
          <div class="box-title">
            <i class="iconfont icon-dianhua"></i>{{ copyright.mobile }}
          </div>
          <div class="text">工作时间：9:00-18:00</div>
        </div>
        <div class="box" v-if="copyright.kcServer">
          <div class="box-title">
            <i class="iconfont icon-youxiang"></i>{{ copyright.kcServer }}
          </div>
          <div class="text">课程咨询和服务</div>
        </div>
      </div>
    </div>
    <div v-if="copyright.wechatMpName" class="right">
      <!--      <div class="title">公众号</div> -->
      <div class="right-img">
        <el-image class="img" :src="copyright.wechatMpName" fit="contain"></el-image>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getDomain } from "@/api/cookies";
import { getInfo } from "@/api/cookies";
export default {
  data() {
    return {
      copyright: {},
      userInfo: null,
      path: "/",
    };
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      this.userInfo = getInfo();
      this.copyright = getDomain();
      this.path = newVal.path;
    },
  },
  async created() {
    this.path = window.location.pathname;
    this.userInfo = getInfo();
    this.copyright = getDomain();
  },
  methods: {
    gopage(path, type) {
      if (this.path == path) {
        return;
      }
      if (type) {
        let userInfo = getInfo();
        if (!userInfo) {
          Vue.prototype.goLoginView(false);
          return;
        }
        window.open(path, "_blank");
      } else {
        /* this.$router.push(path); */
        window.open(path, "_blank");
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 底栏
.foot {
  width: 100%;
  height: 240px;
  background: #232426;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  // margin-top: 20px;
  display: flex;

  // position: absolute;
  // bottom: 0px;
  .title {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    margin: 48px 0px 28px;
  }

  .left {
    width: 278px;
    margin-left: calc(50% - 480px);
    padding-top: 37px;

    .left-logo {
      height: 36px;
    }

    .left-line {
      width: 24px;
      height: 2px;
      background: #ff5e51;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 28px 0px;
    }

    .left-text {
      width: 100%;
      /*   font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #cccccc;
      line-height: 24px; */
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #cccccc;
      line-height: 18px;
    }
  }

  .font-line {
    width: 1px;
    height: 200px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 0.1;
    margin: auto 100px auto 50px;
  }

  .content {
    display: flex;

    .content-left {
      .on {
        color: #ff5e51 !important;
      }

      .text {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #cccccc;
        line-height: 12px;
        margin-top: 24px;
        cursor: pointer;

        &:nth-child(1) {
          margin-top: 0px !important;
        }
      }
    }

    .content-right {
      margin-left: 100px;

      .box {
        margin-bottom: 24px;

        .box-title {
          font-size: 14px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 14px;

          .iconfont {
            font-size: 11px;
            color: #cccccc;
            opacity: 1;
            margin-right: 6px;
          }
        }
      }

      .text {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #cccccc;
        line-height: 12px;
        margin-top: 8px;
        margin-left: 19px;
      }
    }
  }

  .right {
    margin-left: 100px;
    display: flex;
    align-items: center;

    .right-img {
      width: 100px;
      height: 100px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 10px;

      .img {
        width: 80px;
        height: 80px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
      }
    }
  }
}
</style>
