import request from '@/common/utils/request.js'
import router from '@/router/index'
import Vue from 'vue'
import { logOut } from '@/api/login'

function loginOut() {
  logOut()
}
// 个人中心 顶部数据
function getUserCourseStatistics(as) {
  return request({
    url: '/stu/Personal/getUserCourseStatistics',
    method: 'GET',
    params: {
      userid: as
    }
  })
}

// 个人主页 学习概况
function getUserCurTracking(as) {
  return request({
    url: '/stu/mycourse/getUserCurTracking',
    method: 'GET',
    params: {
      userid: as
    }
  })
}

// 个人中心 基本资料
function getPcUser(as) {
  return request({
    url: '/stu/Personal/getPcUser',
    method: 'GET',
    params: {
      uId: as
    }
  })
}

// 个人中心 基本资料 修改信息
function updatePcUser(as, bs, cs, ds, es, fs, gs, hs, is, js, ks, bd, ad) {
  return request({
    url: '/stu/Personal/updatePcUser',
    method: 'POST',
    params: {
      id: as,
      userName: bs,
      nickName: cs,
      mobile: ds,
      sex: es,
      address: fs,
      provinceAddress: gs,
      img: hs,
      cityAddress: is,
      areaAddress: js,
      email: ks,
      birthday: bd,
      autograph: ad
    }
  })
}

// 个人中心 基本资料 修改密码
function updatePcUserPassword(as, bs, cs, ds) {
  return request({
    url: '/stu/Personal/updatePcUserPassword',
    method: 'POST',
    params: {
      userid: as,
      usedPassword: Vue.prototype.encrypt(bs),
      newPassword: Vue.prototype.encrypt(cs),
      code: ds
    }
  })
}

// 个人中心 基本资料 修改密码(xin)
function updateMUserPassword(userid, bs, cs) {
  return request({
    url: '/stu/Personal/updateMUserPassword',
    method: 'POST',
    params: {
      userid: userid,
      usedPassword: Vue.prototype.encrypt(bs),
      newPassword: Vue.prototype.encrypt(cs)
    }
  })
}

// 个人中心 基本资料 修改账号
function updateStudentUserAccount(params) {
  return request({
    url: '/stu/Personal/updateStudentUserAccount',
    method: 'POST',
    params
  })
}

// 个人中心 基本资料 账号绑定
function pcUserBindAcc(mobile, sjToken) {
  return request({
    url: '/stu/Personal/pcUserBindMobile',
    method: 'POST',
    params: {
      mobile: mobile,
      sjToken: sjToken
    }
  })
}

/** 个人中心 获取学生订单信息
 * // type (0待付款/1已付款/2已退款)
 * entrants_status  1 正常显示  0 过期*/
function getClassOrderList(pageSize, pageNum, userid, orderState, tenantid) {
  return request({
    url: '/kecheng/pc/classes/getClassOrderList',
    method: 'GET',
    params: {
      // 学生Id
      userid: userid,
      tenantid: tenantid,
      // 类型 1知识套餐
      orderState: orderState,
      pageSize: pageSize,
      pageNum: pageNum
    }
  })
}

// 获取订单信息 (个人中心订单跳订单支付页)
function getUidOrder(as) {
  return request({
    url: '/stu/Personal/getUidOrder',
    method: 'GET',
    params: {
      // 订单号
      // orderNumber:as
      orderNumber: as
    }
  })
}

// 获取学生消息
function getInformList(userid, formName, pageSize, pageNum) {
  return request({
    url: '/stu/Personal/getInformList',
    method: 'GET',
    params: {
      // 学生Id
      userid: userid,
      // 消息类型
      formName: formName,
      pageSize: pageSize,
      pageNum: pageNum
    }
  })
}

// 获取学生直播课信息
function getUidClassList(as, bs) {
  return request({
    url: '/kecheng/pc/classes/getUidClassList',
    method: 'GET',
    params: {
      // 学生Id
      userid: as,
      // 当前时间
      updateTime: bs
    }
  })
}
// 获取学生直播课信息
function getUnderwayNewPcAllList(userId, startTime, endTime) {
  return request({
    url: '/kecheng/pc/classes/getUnderwayNewPcAllList',
    method: 'GET',
    params: {
      // 学生Id
      userId: userId,
      // 开始时间
      startTime: startTime,
      // 结束时间
      endTime: endTime
    }
  })
}
// 获取学生直播课程下直播课信息信息
function getPlaybackByLiveid(as) {
  return request({
    url: '/stu/Personal/getPlaybackByLiveid',
    method: 'GET',
    params: {
      // 直播课Id
      liveId: as
    }
  })
}

// 获取学生直播课信息
function getUnderwayPcAllList(as, bs) {
  return request({
    url: '/kecheng/pc/classes/getUnderwayPcAllList',
    method: 'GET',
    params: {
      // 学生Id
      userid: as,
      // 当前时间
      updateTime: bs
    }
  })
}

export {
  getUserCurTracking,
  getUserCourseStatistics,
  getPcUser,
  updatePcUser,
  updatePcUserPassword,
  updateMUserPassword,
  pcUserBindAcc,
  getClassOrderList,
  getUidOrder,
  getInformList,
  getUidClassList,
  getUnderwayNewPcAllList,
  getPlaybackByLiveid,
  loginOut,
  getUnderwayPcAllList,
  updateStudentUserAccount
}
