import Vue from "vue";
export class WebClient extends Vue {
  async functionThen(data) {
    if (data.code) {
      this.$notify({
        title: "错误",
        message: data.msg,
        type: "error",
        duration: 2000,
      });
    } else {
      this.$notify({
        title: "操作成功",
        message: data.msg,
        type: "success",
        duration: 2000,
      });
    }
  }
  async functionNo(data) {
    if (data.code) {
      /*   this.$notify({
          title: "错误",
          message: data.msg,
          type: "error",
          duration: 2000
        }); */
     
    }
  }
  async functionShow(data) {
    if (data.code !== 0 && data.code !== 200) {
      this.$notify({
        title: "错误",
        message: data.msg,
        type: "error",
        duration: 2000,
      });
   
    }
  }
  combin(objA, objB) {
    let r = {};
    if (objA != null) {
      for (let name in objA) {
        if (objA[name] !== undefined) {
          r[name] = objA[name];
        }
      }
    }
    if (objB != null) {
      for (let name in objB) {
        if (objB[name] !== undefined && objB[name] !== null) {
          r[name] = objB[name];
        }
      }
    }

    return r;
  }
}