import request from "@/common/utils/request.js";

/** 我的课程
 *   type:1:知识套餐  2：直播录播
 */
function getClassCourseListById(userid, type, pageSize, pageNum, tenantid) {
  return request({
    url: "/kecheng/pc/classes/getMClassCourseListById",
    method: "GET",
    params: {
      // 学生Id
      userid: userid,
      //  1知识套餐
      type: type,
      tenantid: tenantid,
      pageSize: pageSize,
      pageNum: pageNum,
    },
  });
}

/** 添加学习记录
 *  记录类型1直播课/2录播课*/
function getLbClassCourse(
  courseType,
  uid,
  entrantsId,
  classesId,
  chapterId,
  groupId,
  kid,
  recordsConfig,
  isOutsourcing
) {
  return request({
    url: "/stu/mycourse/getLbClassCourse",
    method: "POST",
    params: {
      // 记录类型1直播课/2录播课
      courseType: courseType,
      //  学生Id
      userid: uid,
      // 机构Id
      tenantid: entrantsId,
      // 知识套餐Id
      classId: classesId && Number(classesId) != NaN ? classesId : "",
      // 课程章节Id
      chapterId: chapterId,
      // 章节组Id
      groupId: groupId,
      // 观看课程Id
      kid: kid,
      recordsConfig: recordsConfig,
      isOutsourcing: isOutsourcing,
    },
  });
}

// 获取评论列表
function selectPcList(as, bs) {
  return request({
    url: "/stu/sys/selectPcList",
    method: "GET",
    params: {
      // 1知识套餐 2直播 3录播 4公开 5资料包
      type: as,
      //  产品类型id
      typeId: bs,
    },
  });
}

// 班型学习率
/*
  classesId：班型ID
  studentId：学生ID
*/
function selectPcStuClassIdDetails(params) {
  return request({
    url: "/kecheng/stu/selectPcStuClassIdDetails",
    method: "GET",
    params,
  });
}

/**
 * 知识套餐学习率
 * @param data 参数
 * @returns {*} res
 */
function getPCClassRate(data) {
  return request({
    url: "/kecheng/stu/getPCStuClassRate",
    method: "POST",
    data,
  });
}
/* 获取学生我的课程(所有已购买班型)*/
function getNoPastMClassCourseList(userid, tenantid) {
  return request({
    url: "/kecheng/pc/classes/getNoPastMClassCourseList",
    method: "GET",
    params: {
      userid: userid,
      tenantid: tenantid,
    },
  });
}
// 精品课程学习率
/*
  classesId：班型ID
  studentId：学生ID
  type 1 直播 2 录播
*/
function selectStuCourseDetails(params) {
  return request({
    url: "/kecheng/studentStudy/selectStuCourseDetails",
    method: "GET",
    params,
  });
}
// 商城教材列表
/*
 */
function selectTextbookPcPage(params) {
  return request({
    url: "/kecheng/researchTextbook/selectTextbookPcPage",
    method: "GET",
    params,
  });
}
function selectTextbookPcJxuPage(params) {
  return request({
    url: "/kecheng/researchTextbook/selectTextbookPcJxuPage",
    method: "GET",
    params,
  });
}

// 购物车列表
/*
 */
function selectTextbookPcApiPage(params) {
  return request({
    url: "/stu/stuShoppingTrolley/selectTextbookPcApiPage",
    method: "GET",
    params,
  });
}
// 商城教材详情
/*
 */
function selectTextbookPcData(id) {
  return request({
    url: `/kecheng/researchTextbook/selectTextbookPcData?bookId=${id}`,
    method: "GET",
  });
}

// 教材评价
function getEvaluateList(id) {
  return request({
    url: `/kecheng/selfBookEvaluate/selectBookEvaluateStuList?bookId=${id}`,
    method: "get",
  });
}

// 生成订单
/*
 */
function addTextBookOrder(param,type) {
  return request({
    url: `/stu/stuShoppingTrolley/addTextBookOrder?type=${type}`,
    method: "POST",
    data: param,
  });
}
// 根据订单号查询
/*
 */
function selectTextBookOrder(orderNumber) {
  return request({
    url: `/stu/stuShoppingTrolley/selectTextBookOrder?orderNumber=${orderNumber}`,
    method: "GET",
  });
}
function updateStuShoppingTrolley(params) {
  return request({
    url: `/stu/stuShoppingTrolley/updateStuShoppingTrolley`,
    method: "POST",
    params,
  });
}
// 获取省市区
/*
 */
function getAreaListByParentId(params) {
  return request({
    url: `/stu/integralStudentOrder/getAreaListByParentId`,
    method: "POST",
    params,
  });
}

/* 商城教材详情运费
 */
function getCurSelfBookConfigPc(weight) {
  return request({
    url: `/kecheng/selfBookConfig/getCurSelfBookConfigPc?weight=${weight}`,
    method: "GET",
  });
}

// 购物车数量
//
function selectTextbookCount() {
  return request({
    url: "/stu/stuShoppingTrolley/selectTextbookCount",
    method: "GET",
  });
}

// 新增收货地址
/*
 */
function addStuShopAddress(params) {
  return request({
    url: `/stu/integralStudentOrder/addStuShopAddress`,
    method: "POST",
    params,
  });
}

// 修改收货地址
function updateStuShopAddress(data) {
  return request({
    url: `/stu/integralStudentOrder/updateStuShopAddress`,
    method: "POST",
    data,
  });
}

// 添加评价
function addSelfBookEvaluate(data) {
  return request({
    url: "/kecheng/selfBookEvaluate/addSelfBookEvaluate",
    method: "POST",
    data,
  });
}

// 查询评价详情

function getStudentEvaluateDetail(params) {
  return request({
    url: `/kecheng/selfBookEvaluate/selectBookEvaluateOrderList`,
    method: "GET",
    params,
  });
}

// 收藏列表
function getStuCollectProductList(params) {
  return request({
    url: "/kecheng/researchTextbook/getStuCollectProductList",
    method: "GET",
    params,
  });
}

// 查询是否收藏
function getCollectProductStatus(params) {
  return request({
    url: "/stu/studentCollectProduct/getCollectProductStatus",
    method: "GET",
    params,
  });
}

// 订单修改 新增收货地址
/*
 */
function updateStudentOrderAddress(params) {
  return request({
    url: `/stu/stuShoppingTrolley/updateStudentOrderAddress`,
    method: "POST",
    data: params,
  });
}
// 获取收货地址
/*
 */
function getUserAddress(params) {
  return request({
    url: `/stu/integralStudentOrder/getUserAddress`,
    method: "POST",
    params,
  });
}
// 新增收货地址
/*
 */
function setDefaultStuShopAddress(params) {
  return request({
    url: `/stu/integralStudentOrder/setDefaultStuShopAddress`,
    method: "POST",
    params,
  });
}
// 删除收货地址
function delStuShopAddress(addressId) {
  return request({
    url: `/stu/integralStudentOrder/delStuShopAddress?addressId=${addressId}`,
    method: "POST",
  });
}
// 收藏
function getCollectProduct(data) {
  return request({
    url: `/stu/studentCollectProduct/collectProduct`,
    method: "POST",
    data,
  });
}
// 订单取消
//
function cancelTextBookOrder(id) {
  return request({
    url: `/stu/stuShoppingTrolley/cancelTextBookOrder?orderId=${id}`,
    method: "POST",
  });
}

// 商城教材加入购物车
/*
 */
function addStuShoppingTrolley(params) {
  return request({
    url: `/stu/stuShoppingTrolley/addStuShoppingTrolley`,
    method: "POST",
    params,
  });
}
// 商城教材微信支付
/*
 */
function payWxTextBookOrder(outTradeNo) {
  return request({
    url: `/stu/stuShoppingTrolley/payWxTextBookOrder?outTradeNo=${outTradeNo}`,
    method: "POST",
  });
}
// 商城教材支付宝支付
/*
 */
function payZfbTextBookOrder(outTradeNo) {
  return request({
    url: `/stu/stuShoppingTrolley/payZfbTextBookOrder?outTradeNo=${outTradeNo}`,
    method: "POST",
  });
}
// 商城教材加入购物车
/*
 */
function deleteStuShoppingTrolley(data) {
  return request({
    url: `/stu/stuShoppingTrolley/deleteStuShoppingTrolley`,
    method: "POST",
    data,
  });
}
// 取消订单
/*
 */
function deleteTextBookOrder(orderId) {
  return request({
    url: `/stu/stuShoppingTrolley/deleteTextBookOrder?orderId=${orderId}`,
    method: "POST",
  });
}
// 物流信息
/*
 */
function getShipTraceInfo(orderId) {
  return request({
    url: `/stu/stuShoppingTrolley/getShipTraceInfo?orderId=${orderId}`,
    method: "POST",
  });
}
// 物流信息
/*
 */
function updateConsigneeStatus(orderId) {
  return request({
    url: `/stu/stuShoppingTrolley/updateConsigneeStatus?orderId=${orderId}`,
    method: "POST",
  });
}

/**
 * 根据班型（知识套餐）+年份+学生ID查询课程名的学习率
 * @param classesId 知识套餐ID
 * @param year	年份
 * @param studentId   学生ID
 * @returns
 */
function selectRateByClassIdYearStudentIdPC(params) {
  return request({
    url: "/kecheng/studyCenter/selectRateByClassIdYearStudentIdPC",
    method: "GET",
    params,
  });
}
/**
 * 根据班型（知识套餐）+年份+学生ID查询课程名的学习率
 * @param classesId 知识套餐ID
 * @param year	年份
 * @param studentId   学生ID
 * @returns
 */
// function getAreaListByParentId(params) {
//   return request({
//     url: '/integralStudentOrder/getAreaListByParentId',
//     method: 'POST',
//     params
//   })
// }

/**
 * 根据课程名+课程类型查询学生所有课程的学习率
 * @param keId 课程名ID
 * @param courseType	类型   1：直播   2：录播
 * @param studentId   学生ID
 * @returns
 */
function selectRateByKeIdCourseTypeStudentId(params) {
  return request({
    url: "/kecheng/studyCenter/selectRateByKeIdCourseTypeStudentId",
    method: "GET",
    params,
  });
}

/**
 * 根据课程ID+课类型+学生查询具体章节的学习率
 * @param keId 课程名ID
 * @param courseType	类型   1：直播   2：录播
 * @param studentId   学生ID
 * @returns
 */
function selectChapterRateByStatusAndkeIdStudentId(params) {
  return request({
    url: "/kecheng/studyCenter/selectChapterRateByStatusAndkeIdStudentId",
    method: "GET",
    params,
  });
}

/**
 * 精品课程--单科学习率--查看课程下的章节
 * @param type  直播 2   录播 3
 * @param courseId	课程ID
 * @param studentId   学生ID
 * @returns
 */
function selectStuCourseChapterDetails(params) {
  return request({
    url: "/kecheng/studentStudy/selectStuCourseChapterDetails",
    method: "GET",
    params,
  });
}
/**
 * 根据课程ID+录播章ID+学生查询具体录播章下的节的学习率列表
 * @param groupId  章ID
 * @param keId	课程ID
 * @param studentId   学生ID
 * @returns
 */
function selectChapterRateBykeIdStudentIdGroupId(params) {
  return request({
    url: "/kecheng/studyCenter/selectChapterRateBykeIdStudentIdGroupId",
    method: "GET",
    params,
  });
}
export {
  getCollectProduct,
  selectPcStuClassIdDetails,
  selectStuCourseDetails,
  getClassCourseListById,
  getLbClassCourse,
  selectPcList,
  getNoPastMClassCourseList,
  getPCClassRate,
  selectTextbookPcPage,
  selectTextbookPcJxuPage,
  selectTextbookPcData,
  getEvaluateList,
  getCurSelfBookConfigPc,
  addStuShoppingTrolley,
  getAreaListByParentId,
  addStuShopAddress,
  updateStuShopAddress,
  getUserAddress,
  addTextBookOrder,
  selectTextBookOrder,
  getShipTraceInfo,
  updateConsigneeStatus,
  setDefaultStuShopAddress,
  delStuShopAddress,
  selectTextbookPcApiPage,
  deleteStuShoppingTrolley,
  updateStuShoppingTrolley,
  payZfbTextBookOrder,
  payWxTextBookOrder,
  deleteTextBookOrder,
  updateStudentOrderAddress,
  selectRateByClassIdYearStudentIdPC,
  selectRateByKeIdCourseTypeStudentId,
  selectChapterRateByStatusAndkeIdStudentId,
  selectStuCourseChapterDetails,
  selectChapterRateBykeIdStudentIdGroupId,
  addSelfBookEvaluate,
  getStuCollectProductList,
  getCollectProductStatus,
  getStudentEvaluateDetail,
  cancelTextBookOrder,
  selectTextbookCount,
};
