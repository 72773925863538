<template>
  <div class="boxss">
    <div ref="gradient" class="boxk">
    </div>
    <div class="MaskLayer">
      <div class="navlist">
        <div>
          <div class="school_logo">
            <img style="width: 84px;height: 84px;" :src="schooltem.schoolBadge" alt="">
          </div>
          <div class="school_name">{{ schooltem.schoolName }}</div>
          <img style="width: 10px;height: auto;margin: 0 5px 0 10px;" src="~@/assets/img/TechnicalCollege/8.png" alt="">
          <div class="school_address">{{ schooltem.areaCityName }}</div>
          <div v-if="schooltem.schoolNatureName" class="bq bq1">{{ schooltem.schoolNatureName }}</div>
          <div v-if="schooltem.schoolStage" class="bq bq2">{{ schooltem.schoolStage }}</div>
          <div v-if="schooltem.schoolTypeName" class="bq bq3">{{ schooltem.schoolTypeName }}</div>
        </div>
        <div @click="$route.path == '/gxGathers/index' ? goNexts() : goNext(`/gxGathers/index?id=${id}&toid=${1}`)"
          class="nav_btn">报名咨询</div>

      </div>
    </div>
    <div style="height: 1px;"></div>
    <div class="meaulist">
      <div class="meau_list">
        <div @click="goNext(`/gxGathers/index?id=${id}`)" :class="isChoose == 1 ? 'meau_item choose' : 'meau_item'">首页
        </div>
        <div @click="goNext(`/gxGathers/school/synopsis?id=${id}`)"
          :class="isChoose == 2 ? 'meau_item choose' : 'meau_item'">学校简介</div>
        <div @click="goNext(`/gxGathers/school/enrollmentGuideList?id=${id}`)"
          :class="isChoose == 3 ? 'meau_item choose' : 'meau_item'">招生简章</div>
        <div @click="goNext(`/gxGathers/school/teacher?id=${id}`)"
          :class="isChoose == 4 ? 'meau_item choose' : 'meau_item'">师资团队</div>
        <div @click="goNext(`/gxGathers/school/school?id=${id}`)"
          :class="isChoose == 5 ? 'meau_item choose' : 'meau_item'">校园风采</div>
        <div @click="goNext(`/gxGathers/school/decs?id=${id}`)"
          :class="isChoose == 6 ? 'meau_item choose' : 'meau_item'">学校新闻</div>
        <div @click="goNext(`/gxGathers/school/question?id=${id}`)"
          :class="isChoose == 7 ? 'meau_item choose' : 'meau_item'">常见问题</div>
      </div>
    </div>



  </div>
</template>
<script>
import { loginOut } from "@/api/user";
import { Know } from "@/api/know";
let know = new Know();
import Vue from "vue";
export default {
  components: {},
  props: {
    id: ''
  },
  data() {
    return {
      userInfo: null,
      schooltem: {},
      newData: '',
      isChoose: 1
    };
  },
  watch: {
    $route: function (newVal, oldVal) {

      if (newVal.path == '/gxGathers/index') {
        this.isChoose = 1
      } else if (newVal.path == '/gxGathers/school/synopsis') {
        this.isChoose = 2
      } else if (newVal.path == '/gxGathers/school/enrollmentGuideList') {
        this.isChoose = 3
      } else if (newVal.path == '/gxGathers/school/teacher') {
        this.isChoose = 4
      } else if (newVal.path == '/gxGathers/school/school') {
        this.isChoose = 5
      } else if (newVal.path == '/gxGathers/school/decs') {
        this.isChoose = 6
      } else if (newVal.path == '/gxGathers/school/question') {
        this.isChoose = 7
      }
    },

  },
  async created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }


    this.selectSchoolPcData()

  },
  methods: {
    selectSchoolPcData() {
      know.selectSchoolPcData({
        id: this.id,
      })
        .then((res) => {
          this.schooltem = res.data
          this.$refs.gradient.style.background = `url(${this.schooltem.schoolBadge})`
        });
    },
    goNexts() {

      // 获取当前URL
      const currentURL = window.location.href;
      // 新的参数
      const newParam = `toid=1`;
      // 更新当前URL参数
      let updatedURL;
      if (currentURL.includes('?')) {
        updatedURL = `${currentURL}&${newParam}`;
      } else {
        updatedURL = `${currentURL}?${newParam}`;
      }
      console.log(updatedURL, 'updatedURL');
      // 执行页面跳转并刷新
      window.location.href = updatedURL;
      // window.location.reload();


    }

  },
  mounted() {
  },
};
</script>
<style lang="less">
.boxss {
  width: 100%;
  height: 130px;
  position: relative;

  .boxk {
    position: absolute;
    width: 100%;
    backdrop-filter: blur(200px);
    height: 80px;
    // z-index: 1;
    background: url('~@/assets/img/TechnicalCollege/14.png');
    background-position: 100% 100%;
  }

  .MaskLayer {
    position: absolute;
    height: 80px;
    z-index: 99;
    backdrop-filter: blur(60px);
    width: 100%;

    .navlist {
      width: 1200px;
      height: 80px;

      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      >div {
        display: flex;
        align-items: center;
        position: relative;
      }

      .school_logo {
        background: url('~@/assets/img/TechnicalCollege/7.png');
        background-size: 100% 100%;
        flex-shrink: 0;
        position: absolute;
        width: 141px;
        height: 168px;
        left: 0;
        flex: 1; // top: -10px;
        align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -38px;
      }

      .school_name {
        margin-left: 163px;

        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
      }

      .school_address {

        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 12px;
        color: #FFFFFF;
        margin-right: 30px;
      }

      .bq {
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 12px;
        padding: 2px 11px;
        background: #F3F7FF;
        border-radius: 11px;
        margin-right: 10px;
      }

      .bq1 {
        background: #F3F7FF;
        color: #3D6CC8;
      }

      .bq2 {
        background: #FAF4EC;
        color: #DF932F;
      }

      .bq3 {
        background: #FFF1F1;
        color: #BC3535;
      }
    }

    .nav_btn {
      width: 112px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 20px;

      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      font-size: 14px;
      color: #DF3838;
      justify-content: center;
      cursor: pointer;
    }
  }

  .meaulist {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    margin-top: 80px;

    .meau_list {
      width: 1200px;
      margin: 0 auto;
      padding-left: 163px;
      display: flex;

      .meau_item {
        height: 48px;
        line-height: 48px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 16px;
        color: #555555;
        margin-right: 50px;

        cursor: pointer;

        &:hover {
          color: #1061FF;
          border-bottom: 2px solid #1061FF;
          ;
        }
      }

      .choose {
        color: #1061FF;
        border-bottom: 2px solid #1061FF;

      }
    }
  }
}
</style>
