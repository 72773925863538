<template>
  <div class="certificateView">
    <navigation3 class="navigation3" />
    <router-view class="router-box" :key="$route.fullPath" />
    <!-- <submit style="margin-bottom: 20px; z-index: 99;position: relative;"></submit> -->
    <footer class="footer">
      <FootersPartner />
    </footer>
  </div>
</template>

<script>
import {
  getProjectClassList,
  getProjectClassLis,
  selectInformationPcList,
} from "@/api/home";
import { getDomain } from "@/api/cookies";
import navigation3 from "./nav.vue";
import FootersPartner from "@/components/footersPartner.vue";
import submit from "@/views/gxGather/graduate/submit.vue";
export default {
  components: { navigation3, FootersPartner, submit },
  data() {
    return {
      is_huadong: true,
      id: '',
    };
  },
  created() {
    this.id = localStorage.getItem('projectTypeId');

    // this.getList();
  },
  mounted() { },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.certificateView {
  // background: #fff;
}
</style>