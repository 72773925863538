<template>
  <div class="mainbox">
    <img src="~@/assets/img/TechnicalCollege/3.png" alt="">
  </div>
</template>

<script>
import {
  getProjectClassList,
  getProjectClassLis,
  selectInformationPcList,
} from "@/api/home";
import { getDomain } from "@/api/cookies";
export default {
  components: {},
  data() {
    return {};
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>

<style lang="less" scoped>
.mainbox {
  width: 100%;
  min-height: 400px;
  // background: pink;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>