import request from "@/common/utils/request.js";
import { WebClient } from "@/api/webClient";
const webClient = new WebClient();
import { setReady } from "@/api/cookies";
import {
  getClass,
  getLbClassCourseById,
  getClassCourseById,
  getDetail,
} from "@/api/home";
import { DataClient } from "@/api/dataClient";
const dataClient = new DataClient();
import { getInfo, getDomain } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
const answerClient = new AnswerClient();
export class Know extends WebClient {
  /* 获取知识点详情 */
  async getkonDetail(id, type, classId) {
    const userInfo = getInfo();
    let detail = {};
    if (type == 1) {
      await getClass(id).then((res) => {
        if (res.code == 0 || res.code == 200) {
          res.data.BX.title = res.data.BX.tiitle;
          detail = res.data.BX;
          detail.IsPurchase = res.data.IsPurchase;
        }
      });
    } else if (type == 2) {
      await getLbClassCourseById(id, classId).then((res) => {
        if (res.code == 0 || res.code == 200) {
          detail = res.data.details;
          detail.IsPurchase = res.data.IsPurchase;
        }
      });
    } else if (type == 3) {
      await getClassCourseById(id, classId).then((res) => {
        if (res.code == 0 || res.code == 200) {
          detail = res.data.details;
          detail.IsPurchase = res.data.IsPurchase;
        }
      });
    } else if (type == 4) {
      await getDetail(id, classId).then((res) => {
        if (res.code == 0 || res.code == 200) {
          detail = res.data.GKK;
        }
      });
    } else if (type == 5) {
      await dataClient.getDocPackageDetails(id).then((res) => {
        if (res.code == 0 || res.code == 200) {
          detail = res.data;
        }
      });
    } else if (type == 6) {
      await answerClient
        .getPackageDetails(id, userInfo.id, classId)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            res.data.paper.title = res.data.paper.name
              ? res.data.paper.name
              : "";
            res.data.paper.img = res.data.paper.image
              ? res.data.paper.image
              : "";
            res.data.paper.price = res.data.paper.packagePrice
              ? res.data.paper.packagePrice
              : 0;
            res.data.paper.IsPurchase = !!(
              res.data.paper.status && res.data.paper.status == 1
            );
            detail = res.data.paper;
          }
        });
    } else if (type == 7) {
      await getPcAudioTeachDetail(id, classId).then((res) => {
        if (res.code == 0 || res.code == 200) {
          res.msg.title = res.msg.name;
          res.msg.img = res.msg.image;
          res.msg.price = res.msg.entrantsPrice;
          detail = res.msg;
          detail.IsPurchase = res.msg.IsPurchase;
        }
      });
    } else if (type == 8) {
      await getImgCourse(id, classId).then((res) => {
        if (res.code == 0 || res.code == 200) {
          res.msg.title = res.msg.imageTextTeach.name;
          res.msg.img = res.msg.imageTextTeach.image;
          res.msg.price = res.msg.imageTextTeach.entrantsPrice;
          detail = res.msg.imageTextTeach;
          detail.IsPurchase = res.msg.IsPurchase;
        }
      });
    } else if (type == 10) {
      await getPcFaceTeachDetail(id, classId).then((res) => {
        if (res.code == 0 || res.code == 200) {
          res.msg.title = res.msg.faceTeach.name;
          res.msg.img = res.msg.faceTeach.image;
          res.msg.price = res.msg.faceTeach.entrantsPrice;
          detail = res.msg.faceTeach;
          detail.IsPurchase = res.msg.IsPurchase;
        }
      });
    } else if (type == 12) {
      await answerClient
        .getPaperDetails(id, userInfo.id, classId)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            res.data.paper.title = res.data.paper.name;
            res.data.paper.img = res.data.paper.image;
            res.data.paper.IsPurchase = res.data.paper.status == 1;
            res.data.paper.price = res.data
              ? product
                ? entrantsPrice
                  ? res.data.product.entrantsPrice
                  : res.data.paper.originalPrice
                : null
              : null;
            detail = res.data.paper;
          }
        });
    } else if (type == 15) {
      await answerClient.getPcRoomDetails(id).then((res) => {
        if (res.code == 0 || res.code == 200) {
          res.data.VirtualRoom.title = res.data.VirtualRoom.name;
          res.data.VirtualRoom.img = res.data.VirtualRoom.image;
          res.data.VirtualRoom.price = res.data.VirtualRoom.tenantPrice;
          detail = res.data.VirtualRoom;
        }
      });
    } else if (type == 16) {
      await this.getCoursePackage(id).then((res) => {
        // if (res.code == 0||res.code == 200) {
        res.title = res.name;
        res.img = res.image;
        res.price = res.entrantsPrice;
        detail = res;
        // }
      });
    }
    return detail;
  }
  /** 新人有礼接口(所有都在这一个接口)*/
  async getPcPackageByTenant(bigKind) {
    return request({
      url: "/market/giftPackage/getPcPackageByTenant",
      method: "GET",
      params: {
        bigKind: bigKind,
        userId: getInfo() ? getInfo().id : undefined,
        tenantId: getDomain().eId,
      },
    });
  }
  /** 新人有礼接口*/
  async getPcPackageTypeList() {
    return request({
      url: "/market/giftPackage/getPcPackageTypeList",
      method: "GET",
      params: {
        userId: getInfo() ? getInfo().id : undefined,
        tenantId: getDomain().eId,
      },
    });
  }
  //  双十二 立即领取状态 和 领取
  async getSudentJXUStatus(data) {
    return request({
      url: "/stu/front/getSudentJXUStatus",
      method: "POST",
      params: data,
    });
  }
  /** 新人有礼领取接口*/
  async getPackageByNewUser(id) {
    return request({
      url: "/market/giftPackage/getPackageByNewUser",
      method: "GET",
      params: {
        id: id,
      },
    });
  }
  /** 新人有礼单课领取接口*/
  async getCourseByNewUser(id, activityId, type, validity, img) {
    return request({
      url: "/market/giftPackage/getCourseByNewUser",
      method: "GET",
      params: {
        id: id,
        activityId: activityId,
        type: type,
        validity: validity,
        img: img,
      },
    });
  }
  /* 学习记录 */
  /**  记录类型1直播课/2录播课*/
  async myCourseList(pageSize, pageNum, courseType, tenantid) {
    return request({
      url: "/stu/mycourse/getLbClassCourseList",
      method: "GET",
      params: {
        pageSize: pageSize,
        pageNum: pageNum,
        courseType: courseType,
        tenantid: tenantid,
        userid: localStorage.getItem("userId"),
      },
    });
  }
  /* -----------------------消息-------------------------------------- */

  /** 修改当前学生消息状态(转为已读)*/
  async readInforms(ids) {
    return request({
      url: "/stu/Personal/readInforms",
      method: "GET",
      params: {
        ids: ids,
      },
    });
  }
  /** 修改当前学生消息状态(一键已读)*/
  async readAllInforms() {
    return request({
      url: "/stu/Personal/readAllInforms",
      method: "GET",
      params: {
        userid: localStorage.getItem("userId"),
      },
    });
  }
  /** 获取当前学生未读消息条数*/
  async getUnreadInformList(userid, formName) {
    const data = request({
      url: "/stu/Personal/getUnreadInformList",
      method: "GET",
      params: {
        userid: userid,
        formName: formName,
      },
    });
    setReady(data.msg);
    return data;
  }
  /* -----------------------学习记录-------------------------------------- */
  /** 添加学习记录(直播播放)
   *type:1 直播 2录播
   *isLive: 1正在直播  0  回放
   */
  async getCurTracking(
    courseId,
    showId,
    type,
    isLive,
    sClass,
    stuId,
    multiple,
    isExit
  ) {
    return request({
      url: "/kecheng/curTracking/saveCurTracking",
      method: "POST",
      params: {
        minutes: 3,
        courseId: courseId,
        showId: showId,
        type: type,
        isLive: isLive,
        sClass: sClass,
        client: "PC端",
        stuId: stuId,
        multiple: multiple,
        isExit: isExit,
      },
    });
  }
  /** 三分钟一次 */
  async addUpdateTrackingVideoRecord(
    courseId,
    showId,
    type,
    duration,
    classId,
    multiple
  ) {
    return request({
      url: "/kecheng/trackingVideoRecord/addUpdateTrackingVideoRecord",
      method: "POST",
      data: {
        duration: duration,
        courseId: courseId,
        showId: showId,
        minutes: 3,
        type: type,
        classId: classId,
        multiple: multiple,
      },
    });
  }
  /** 查询课程章节是否已观看 */
  async findTrackingVideoRecord(courseId, showId, type, classId) {
    return request({
      url: "/kecheng/trackingVideoRecord/findTrackingVideoRecord",
      method: "POST",
      data: {
        courseId: courseId,
        showId: showId,
        type: type,
        classId: classId,
      },
    });
  }

  /* -----------------------公开课-------------------------------------- */
  /** 分类获取公开课(项目/分类)
   * courseType 0 公开课 1 导学课 2试听课
   */
  async getLiveshowpublicByTypes(pageSize, pageNum, kind, courseType, userid) {
    return request({
      url: "/kecheng/pc/classes/getLiveshowpublicByTypes",
      method: "GET",
      params: {
        pageSize: pageSize,
        pageNum: pageNum,
        kind: kind,
        courseType: courseType,
        userid: userid,
      },
    });
  }
  /* -------------------公开课营销直播------------------ */
  /*
    查询公开课列表
      state: 状态(0直播未开始/1正在直播/2直播结束),
      zyId 专业ID
      pageSize
      pageNum
      kindId 项目Id
      schoolId 院校Id
      name 课程名称
  */
  async getPublicityClassList(params) {
    params.isShow = params.isShow ? 1 : undefined;
    return request({
      url: "/kecheng/publicityClass/getPublicityClassList",
      method: "GET",
      params,
    });
  }
  async getNewPublicClassList(params) {
    params.isShow = params.isShow ? 1 : undefined;
    return request({
      url: "/kecheng/publicClassForStudent/getNewPublicClassList",
      method: "GET",
      params,
    });
  }
  async getNewCollegesPublicClassList(params) {
    params.isShow = params.isShow ? 1 : undefined;
    return request({
      url: "/kecheng/publicClassForStudent/getNewCollegesPublicClassList",
      method: "GET",
      params,
    });
  }
  /* 公开课详情 */
  async getPublicityClassDetail(params) {
    return request({
      url: "/kecheng/publicityClass/getPublicityClassDetail",
      method: "GET",
      params,
    });
  }
  /* 公开课推荐 */
  async getPublicityClassRecommend(params) {
    return request({
      url: "/kecheng/publicityClass/getPublicityClassRecommend",
      method: "GET",
      params,
    });
  }
  /* 添加学生观看公开课记录 */
  async addPublicityClassStudentRecord(params) {
    return request({
      url: "/kecheng/publicityClassStudentRecord/addPublicityClassStudentRecord",
      method: "POST",
      params,
    });
  }
  /* OBS推流上报数据 */
  async stuLiveArriveService(params) {
    return request({
      url: "/kecheng/liveshow/stuLiveArriveService",
      method: "POST",
      params,
    });
  }
  /* 更新公开课阅读量
    id: 主键ID
  */
  async updatePublicityClassClicks(params) {
    return request({
      url: "/kecheng/publicityClass/updatePublicityClassClicks",
      method: "GET",
      params: {
        id: params,
      },
    });
  }
  /**
   * 公开课观看记录添加
   * @param classId 公开课id
   * @param stuId 观看学生用户id
   * @param tenantId  机构id
   * @param type  2观看
   * @param status  1 学生分享  2老师分享 0 主动进去
   * @param userId	 邀请人用户id'
   * @param time  观看时长 秒
   * @param terminal  APP IOS PC M
   * @returns
   */
  async addStudent(data) {
    return request({
      url: "/kecheng/publicityClassStudent/addStudent",
      method: "POST",
      data,
    });
  }
  /**
   * 机构 分享公开课 地址链接 二维码
   * @param classId 公开课id
   * @param satatus '1 学生分享（pc M）   2老师分享（机构后台分享）
   * @returns
   */
  asyncaddQRCodPublicityClass(params) {
    return request({
      url: "kecheng/publicityClass/addQRCodPublicityClass",
      method: "GET",
      params,
    });
  }

  /**
   * 用户课程详情页面数据上传
   * @param {
   *    courseId 课程ID
   *    classId 知识套餐ID
   *    platform  0.PC；1.WAP；2.ANDROID；3.IOS；4.小程序
   * } params
   * @returns
   */
  async coursePublicPvSave(data) {
    return request({
      url: "/kecheng/coursePublic/pv/save",
      method: "POST",
      data,
    });
  }

  /* -----------------------直播课-------------------------------------- */
  /** 分类获取直播课(项目/专业/分类)
   * Types 1.串讲课、2.精讲课、3.冲刺课
   */
  async getLiveshowByTypes(kind, zyId, Types, tenantid) {
    return request({
      url: "/kecheng/pc/classes/getLiveshowByTypes",
      method: "GET",
      params: {
        kind: kind,
        zyId: zyId,
        Types: Types,
        tenantid: tenantid,
      },
    });
  }
  /* -----------------------专业课-------------------------------------- */
  async zhuanyekecheng(kind, zyId, kcName, kcType) {
    const params = this.combin(
      {},
      {
        kind: kind,
        ZId: zyId,
        keName: kcName,
        kcType: kcType,
      }
    );
    const data = await request({
      url: `/kecheng/zhuanyekecheng/getKechengList`,
      method: "GET",
      params: params,
    });
    return data;
  }

  async getKindZidList(kind, zyId) {
    const params = this.combin(
      {},
      {
        kind: kind,
        zyId: zyId,
      }
    );
    const data = await request({
      url: `/kecheng/kecheng/getKindZidList`,
      method: "GET",
      params: params,
    });
    return data;
  }

  // 专业，省份 下的课程列表
  async selectProvinceKechengList(zyId, provinceIds) {
    const params = this.combin(
      {},
      {
        zyId: zyId,
        provinceIds,
      }
    );
    const data = await request({
      url: `/kecheng/provinceCollegesKeCheng/selectProvinceKechengList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /* --------------公开课预约 ------------------------------------------------------ */
  /** 新增公开课预约
   *isPublic      是否预约(0:预约、1：未预约)
   */
  async addUserPublicKe(keId, userName, mobile, userId) {
    const data = await request({
      url: "/kecheng/userPublicKe/addUserPublicKe",
      method: "POST",
      params: {
        keId: keId,
        userName: userName,
        mobile: mobile,
        userId: userId,
      },
    });
    this.functionThen(data);
    return data;
  }
  /** 添加用户邀请记录
   *isPublic      是否预约(0:预约、1：未预约)
   */
  async addUserPublicKeInvite(
    keId,
    inviteUserId,
    beinviteUserId,
    beinviteUserMobile
  ) {
    const data = await request({
      url: "/stu/userPublicKeInvite/addUserPublicKeInvite",
      method: "POST",
      params: {
        keId: keId,
        inviteUserId: inviteUserId,
        beinviteUserId: beinviteUserId,
        beinviteUserMobile: beinviteUserMobile,
      },
    });
    return data;
  }
  /* --------------视频章节随堂资料、讲义（scf） ------------------------------------------------------ */
  // 查列表
  async curCourseDocsList(
    pageNum,
    pageSize,
    sectionId,
    docType,
    courseType,
    courseId,
    groupId
  ) {
    const data = await request({
      url: `/kecheng/curCourseDocs/getList`,
      method: "GET",
      params: {
        pageNum: pageNum,
        pageSize: pageSize,
        sectionId: sectionId,
        docType: docType,
        courseType: courseType,
        courseId: courseId,
        groupId: groupId && groupId != NaN ? groupId : undefined,
      },
    });
    return data;
  }
  // 课程资料标签
  async getDocsPcLabelList(params) {
    const data = await request({
      url: `/knowledge/webPc/getDocsPcLabelList`,
      method: "GET",
      params,
    });
    return data;
  }
  // 课程资料数据
  async getDocsPcPageList(params) {
    const data = await request({
      url: `/knowledge/webPc/getDocsPcPageList`,
      method: "GET",
      params,
    });
    return data;
  }
  // 课程测试标签
  async getContactPcLabelList(params) {
    const data = await request({
      url: `/knowledge/webPc/getContactPcLabelList`,
      method: "GET",
      params,
    });
    return data;
  }
  // 课程测试试卷列表
  async getContactPcPageList(params) {
    const data = await request({
      url: `/knowledge/webPc/getContactPcPageList`,
      method: "GET",
      params,
    });
    return data;
  }
  // 课程资料数据
  async getDocsPcPageList(params) {
    const data = await request({
      url: `/knowledge/webPc/getDocsPcPageList`,
      method: "GET",
      params,
    });
    return data;
  }
  // 课程模考是否显示
  async getCourseContactButton(item) {
    const data = await request({
      url: `/kecheng/curCourseContact/getCourseContactButton`,
      method: "GET",
      params: item,
    });
    return data;
  }
  // 课程资料按钮是否显示
  async getDocButton(item) {
    const data = await request({
      url: `/kecheng/curCourseDocs/getDocButton`,
      method: "GET",
      params: item,
    });
    return data;
  }
  /** 查绑定的试卷
   * status:	1 课后作业 2 随堂测试 3课程模考
   * 1 直播 2录播 3音频 4 图文 5面授
   */
  async getPaperByCourse(cChapterid, cType, cCourseid, cKid, status) {
    const data = await request({
      url: `/kecheng/curCourseContact/getPaperByCourse`,
      method: "GET",
      params: {
        cChapterid: cChapterid,
        cType: cType,
        cCourseid: cCourseid,
        cKid: cKid,
        status: status,
      },
    });
    this.functionNo(data);
    return data;
  }
  /* --------------专属班型下 ------------------------------------------------------ */
  // 知识套餐课程资料年份（优化） 按钮显示
  async classesDocKcTableButton(params) {
    const data = await request({
      url: `/kecheng/courseSys/classesDocKcTableButton`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  // 知识套餐课程资料年份（优化）
  async classesDocKcTableList(params) {
    const data = await request({
      url: `/kecheng/courseSys/classesDocKcTableList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  // 知识套餐课程课程题库年份（优化）按钮显示
  async classesContactTableButton(params) {
    const data = await request({
      url: `/kecheng/courseSys/classesContactTableButton`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  // 知识套餐课程课程题库年份（优化）
  async classesContactTableListCourseSys(params) {
    const data = await request({
      url: `/kecheng/courseSys/classesContactTableList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  // 课程测试 课程资料课程名
  async classesButtonKcDataList(params) {
    const data = await request({
      url: `/kecheng/courseSys/classesButtonKcDataList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  //课程测试试卷列表
  async getContactPcPageList(params) {
    const data = await request({
      url: `/knowledge/webPc/getContactPcPageList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  /* --------------专属课程模块 虚拟考场 ------------------------------------------------------ */
  /** 知识套餐课程年份  */
  async classesTable(classesId, userid, kId) {
    const data = await request({
      url: `/kecheng/basics/classesTable`,
      method: "GET",
      params: {
        classesId: classesId,
        userid: userid,
        kId: kId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 学习中心课程类型下的年份(新)  */
  async getClassesCourseTypeYearList(item) {
    const data = await request({
      url: `/kecheng/courseSys/getClassesCourseTypeYearList`,
      method: "GET",
      params: item,
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐课试卷列表年份   */
  async classesContactTableList(classesId, userid) {
    const data = await request({
      url: `/kecheng/courseSys/classesContactTableList`,
      method: "GET",
      params: {
        classesId: classesId,
        userid: userid,
      },
    });
    this.functionNo(data);
    return data;
  }

  /** 知识套餐课程年份（优化） 精品线  */
  async classesNewTableKsTime(classesId, userid) {
    const data = await request({
      url: `/kecheng/basics/classesNewTableKsTime`,
      method: "GET",
      params: {
        classesId: classesId,
        userid: userid,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 根据课程名+学生ID+班型ID或课程包统计年份  */
  async selectYearByKeIdStudentIdAndClassesId(item) {
    const data = await request({
      url: `/kecheng/studyCenter/selectYearByKeIdStudentIdAndClassesId`,
      method: "GET",
      params: item,
    });
    this.functionNo(data);
    return data;
  }
  /** 根据课程名+学生ID+班型ID或课程包统计年份(面授课)  */
  async selectYearByFaceId(item) {
    const data = await request({
      url: `/kecheng/studyCenter/selectYearByFaceId`,
      method: "GET",
      params: item,
    });
    this.functionNo(data);
    return data;
  }
  /** 专属课程模块 虚拟考场  */
  async selectClassesRoomList(classesId) {
    const data = await request({
      url: `/ques/business/selectClassesRoomList`,
      method: "GET",
      params: {
        classesId: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 课程包选择方向 专属课程模块 虚拟考场
   * @param classesId 班型id
   * @param coursePackageId 课程包id
   * @param startTime
   */
  async selectClassesCourseRoomList(params) {
    const data = await request({
      url: `/ques/business/selectClassesCourseRoomList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }

  /** 专属资料 */
  async getClassesMaterialsList(classesId) {
    const data = await request({
      url: `/kecheng/materials/getClassesMaterialsList`,
      method: "GET",
      params: {
        classesId: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 课程包选择方向查专属资料
   * @param classesId 班型ID
   * @param coursePackageId 课程包id
   */
  async getCoursePackageMaterialsList(params) {
    const data = await request({
      url: `/kecheng/materials/getCoursePackageMaterialsList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }

  /** 专属试卷 */
  async selectClassesPaper(classesId) {
    const data = await request({
      url: `/ques/business/selectClassesPaper`,
      method: "GET",
      params: {
        classesId: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 课程包选择方向专属试卷
   * @param classesId 班型id
   * @param coursePackageId 课程包
   * @param stuId 学生id
   */
  async selectClassesCoursePaper(params) {
    const data = await request({
      url: `/ques/business/selectClassesCoursePaper`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }

  /* 专属考场 */
  async selectVirtualRoomPcList(params) {
    const data = await request({
      url: `/kecheng/courseSys/selectVirtualRoomPcList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  /**
   * 课程包方向 知识套餐专属考场
   * @param classesId
   * @param coursePackageId
   * @param yearName
   * @returns
   */
  async selectVirtualRoomPcPackageList(params) {
    const data = await request({
      url: `/kecheng/courseSys/selectVirtualRoomPcPackageList`,
      method: "GET",
      params,
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐课表 资料 */
  async classesCourseDocList(classesId, yearName, kId) {
    const data = await request({
      url: `/kecheng/courseSys/classesCourseDocList`,
      method: "GET",
      params: {
        yearName: yearName,
        kId: kId,
        classesId: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐课表 模考*/
  async classesContactList(classesId, yearName, kId) {
    const data = await request({
      url: `/kecheng/courseSys/classesContactList`,
      method: "GET",
      params: {
        yearName: yearName,
        kId: kId,
        classesId: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐课表 专属资料（新）*/
  async selectDocList(classesId, yearName, kId) {
    const data = await request({
      url: `/kecheng/courseSys/selectDocList`,
      method: "GET",
      params: {
        yearName: yearName,
        kId: kId,
        classesId: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐课表 buyStatus:1：已购买，0：未购买*/
  async classesCourseList(classesId, yearName, kId, userid, buyStatus) {
    const data = await request({
      url: `/kecheng/courseSys/classesCourseList`,
      method: "GET",
      params: {
        yearName: yearName,
        kId: kId,
        classesId: classesId,
        userid: userid,
        buyStatus: buyStatus,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐其他课程课表 buyStatus:1：已购买，0：未购买*/
  async classesCourseTableList(
    classesId,
    userid,
    yearName,
    buyStatus,
    kId,
    kcType
  ) {
    const data = await request({
      url: `/kecheng/courseSys/classesCourseTableList`,
      method: "GET",
      params: {
        yearName: yearName,
        classesId: classesId,
        userid: userid,
        buyStatus: buyStatus,
        kId: kId,
        kcType: kcType,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐其他课程课表 buyStatus:1：已购买，0：未购买*/
  async classesNewCourseList(
    classesId,
    userid,
    yearName,
    buyStatus,
    kId,
    kcType
  ) {
    const data = await request({
      url: `/kecheng/courseSys/classesNewCourseList`,
      method: "GET",
      params: {
        yearName: yearName,
        classesId: classesId,
        userid: userid,
        buyStatus: buyStatus,
        kId: kId,
        kcType: kcType,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐其他课程课表 buyStatus:1：已购买，0：未购买*/
  async getPastPrivateClassList(classesId, userid, yearName, buyStatus) {
    const data = await request({
      url: `/kecheng/courseSys/getPastPrivateClassList`,
      method: "GET",
      params: {
        yearName: yearName,
        classesId: classesId,
        userid: userid,
        buyStatus: buyStatus,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐其他课程课表 buyStatus:1：已购买，0：未购买*/
  async myClassesNewCourseList(classesId, userid, yearName, buyStatus) {
    const data = await request({
      url: `/kecheng/courseSys/myClassesNewCourseList`,
      method: "GET",
      params: {
        // yearName: yearName,
        // classesId: classesId,
        // userid: userid,
        // buyStatus: buyStatus
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 往期课程*/
  async pastCourseList(classesId, userid, yearName, kid, kcType) {
    const data = await request({
      url: `/kecheng/courseSys/pastCourseList`,
      method: "GET",
      params: {
        classesId: classesId,
        userid: userid,
        yearName: yearName,
        kId: kid,
        kcType: kcType,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 学习中心课程类型*/
  async classesNewCourseKcTypeList(item) {
    const data = await request({
      url: `/kecheng/courseSys/classesNewCourseKcTypeList`,
      method: "GET",
      params: item,
    });
    this.functionNo(data);
    return data;
  }
  /** 小班课课程列表 buyStatus:1：已购买，0：未购买*/
  async getPrivateClassList(classesId, userid, yearName, buyStatus) {
    const data = await request({
      url: `/kecheng/courseSys/getPrivateClassList`,
      method: "GET",
      params: {
        classesId: classesId,
        userid: getInfo() ? getInfo().id : undefined,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 查询小班课单科课程 */
  async getPrivateClassData(classesId, courseId) {
    const data = await request({
      url: `/kecheng/basics/getPrivateClassData`,
      method: "GET",
      params: {
        classesId: classesId,
        courseId: courseId,
        userid: getInfo() ? getInfo().id : undefined,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 知识套餐课表 buyStatus:1：已购买，0：未购买*/
  async getClassesCourseBuyList(classesId, yearName, kId, userid, buyStatus) {
    const data = await request({
      url: `/kecheng/courseSys/getClassesCourseBuyList`,
      method: "GET",
      params: {
        yearName: yearName,
        kId: kId,
        classesId: classesId,
        userid: userid,
        buyStatus: buyStatus,
      },
    });
    this.functionNo(data);
    return data;
  }
  /* --------------查询机构所有直播录播课 ------------------------------------------------------ */
  /** 查询机构所有直播录播课
   */
  async getCourseList(
    pageSize,
    pageNum,
    types,
    schoolId,
    kind,
    zyId,
    kid,
    productLine,
    areaIds
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kind: kind,
        zyId: zyId,
        kid: kid,
        types,
        schoolId: schoolId,
        productLine: productLine,
        areaIds,
      }
    );
    const data = await request({
      url: `/kecheng/courseAll/getCourseList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  async getCourseListnew(
    pageSize,
    pageNum,
    types,
    schoolId,
    kind,
    zyId,
    kid,
    productLine,
    areaIds
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
        pcDataType: 1,
      },
      {
        kind: kind,
        zyId: zyId,
        kid: kid,
        types,
        schoolId: schoolId,
        productLine: productLine,
        areaIds,
      }
    );
    const data = await request({
      url: `/kecheng/dataCache/getCourseList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  async getNewCourseList(
    pageSize,
    pageNum,
    schoolId,
    kind,
    stage,
    zyId,
    kid,
    productLine,
    areaIds,
    types,
    name
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kind: kind,
        zStage: stage,
        zyId: zyId,
        kid: kid,
        schoolId: schoolId,
        productLine: productLine,
        areaIds,
        types,
        name,
      }
    );
    const data = await request({
      url: `/kecheng/courseAll/getNewCourseList`,
      method: "GET",
      params: params,
    });
    return data;
  }

  /** 分类获取直播课(项目/专业/分类)直播课展示直播下所有将要直播与还未直播的章节(直播结束的不展示)
   * Types	  1.串讲课、2.精讲课、3.冲刺课
   */
  async getLiveshowplaybackByTypes(
    pageSize,
    pageNum,
    kind,
    zyId,
    Types,
    tenantid,
    areaIds
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kind: kind,
        zyId: zyId,
        Types: Types,
        tenantid: tenantid,
        areaIds:areaIds
      }
    );
    const data = await request({
      url: `/kecheng/pc/classes/getLiveshowplaybackByTypes`,
      method: "GET",
      params: params,
    });
    return data;
  }
  async getLiveshowplaybackByTypeupdata(item) {
    const data = await request({
      url: `/kecheng/pc/classes/getLiveshowplaybackByTypes`,
      method: "GET",
      params: item,
    });
    return data;
  }
  async getLiveshowplaybackByTypesnew(
    pageSize,
    pageNum,
    kind,
    zyId,
    Types,
    tenantid
  ) {
    const params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
        pcDataType: 1,
      },
      {
        kind: kind,
        zyId: zyId,
        Types: Types,
        tenantid: tenantid,
      }
    );
    const data = await request({
      url: `/kecheng/dataCache/getLiveshowplaybackByTypes`,
      method: "GET",
      params: params,
    });
    return data;
  }
  async getNewLiveshowplaybackByTypesnew(params) {
    const data = await request({
      url: `/kecheng/dataCache/getLiveshowplaybackByTypes`,
      method: "GET",
      params,
    });
    return data;
  }
  async getNewLiveshowplaybackByTypes(params) {
    const data = await request({
      url: `/kecheng/pc/classes/getNewLiveshowplaybackByTypes`,
      method: "GET",
      params,
    });
    return data;
  }
  /** 知识套餐课程年份（优化） 精品线  */
  async classesTableKsTime(classesId, userid) {
    const data = await request({
      url: `/kecheng/basics/classesTableKsTime`,
      method: "GET",
      params: {
        classesId: classesId,
        userid: userid,
      },
    });
    this.functionNo(data);
    return data;
  }
  /* --------------课程方向 ------------------------------------------------------ */

  /** 获取课程方向列表
   * isShow   页面展示使用可以显示的是否显示(0:显示/1:不显示)
   */
  async getCoursePackageList(classesId, pageSize, pageNum) {
    const data = await request({
      url: `/kecheng/coursePackage/getCoursePackageList`,
      method: "GET",
      params: {
        isShow: 0,
        pageSize: pageSize,
        pageNum: pageNum,
        classesId: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /** 获取机构班型下已购买的直播录播(
   * "type": 3,   课程类型2:直播/3:录播
   */
  async getTenClassCourse(id) {
    const data = await request({
      url: `/kecheng/pc/course/getTenClassCourse`,
      method: "GET",
      params: {
        id: id,
      },
    });
    return data;
  }
  /* --------------图文课 ------------------------------------------------------ */
  // 姜涛 2022.10.14 单科详情 购买状态   班型进入单科详情 购买状态  区分出来
  /** 查询pc端图文课详情*/
  // async getImgCourse(id, classId) {
  //   let params = this.combin(
  //     {
  //       userid: localStorage.getItem("userId"),
  //       id: id,
  //     },
  //     {
  //       classId: classId,
  //     }
  //   );
  //   let data = await request({
  //     url: "/kecheng/imageText/getPcImageTextTeachDetail",
  //     method: "GET",
  //     params: params,
  //   });
  //   return data;
  // }
  /** 查询pc端图文课详情*/
  async getImgCourse(id, classId) {
    const params = this.combin(
      {
        userid: localStorage.getItem("userId"),
        id: id,
      },
      {
        classId: classId,
      }
    );
    const data = await request({
      url: "/kecheng/imageText/getPcImageTextTeachDetailNew",
      method: "GET",
      params: params,
    });
    return data;
  }
  /* 图文章节详情 */
  async getImageChat(id) {
    const data = await request({
      url: "/kecheng/imageTeachChapter/getImageTeachChapterDetail",
      method: "GET",
      params: {
        id: id,
      },
    });
    return data;
  }
  /* --------------音频课 ------------------------------------------------------ */
  // 姜涛 2022.10.14 单科详情 购买状态   班型进入单科详情 购买状态  区分出来
  /** 查询pc端图文课详情*/
  // async getPcAudioTeachDetail(id, classId) {
  //   let params = this.combin(
  //     {
  //       userid: localStorage.getItem("userId"),
  //       id: id,
  //     },
  //     {
  //       classId: classId,
  //     }
  //   );
  //   let data = await request({
  //     url: "/kecheng/audio/getPcAudioTeachDetail",
  //     method: "GET",
  //     params: params,
  //   });
  //   return data;
  // }
  async getPcAudioTeachDetail(id, classId) {
    const params = this.combin(
      {
        userid: localStorage.getItem("userId"),
        id: id,
      },
      {
        classId: classId,
      }
    );
    const data = await request({
      url: "/kecheng/audio/getPcAudioTeachDetailNew",
      method: "GET",
      params: params,
    });
    return data;
  }
  /* 查询音频课程节详情 */
  async getAudioLessonDetail(id) {
    const data = await request({
      url: "/kecheng/audioLesson/getAudioLessonDetail",
      method: "GET",
      params: {
        id: id,
      },
    });
    return data;
  }
  /* --------------面授课 ------------------------------------------------------ */
  // 姜涛 2022.10.14 单科详情 购买状态   班型进入单科详情 购买状态  区分出来
  /** 查询pc端图文课详情*/
  // async getPcFaceTeachDetail(id, classId) {
  //   let params = this.combin(
  //     {
  //       userid: localStorage.getItem("userId"),
  //       id: id,
  //     },
  //     {
  //       classId: classId,
  //     }
  //   );
  //   let data = await request({
  //     url: "/kecheng/face/getPcFaceTeachDetail",
  //     method: "GET",
  //     params: params,
  //   });
  //   return data;
  // }
  async getPcFaceTeachDetail(id, classId) {
    const params = this.combin(
      {
        userid: localStorage.getItem("userId"),
        id: id,
      },
      {
        classId: classId,
      }
    );
    const data = await request({
      url: "/kecheng/face/getPcFaceTeachDetailNew",
      method: "GET",
      params: params,
    });
    return data;
  }
  /* 面授课章节详情 */
  async getFaceTeachOutlineDetail(id) {
    const data = await request({
      url: "/kecheng/faceOutline/getFaceTeachOutlineDetail",
      method: "GET",
      params: {
        id: id,
      },
    });
    return data;
  }
  /*  */
  /** 获取课程方向详情*/
  async getCoursePackage(classesId) {
    const data = await request({
      url: `/kecheng/coursePackage/getCoursePackage`,
      method: "GET",
      params: {
        id: classesId,
      },
    });
    this.functionNo(data);
    return data;
  }
  /**  更新课程热度
   *  1班型2直播3录播4音频5图文6面授*/
  async updateCourseAllClicks(type, id) {
    const data = await request({
      url: "/kecheng/courseAll/updateCourseAllClicks",
      method: "GET",
      params: {
        type: type,
        id: id,
      },
    });
    return data;
  }
  /* --------------实用信息模块 ------------------------------------------------------ */
  /**  实用信息模块菜单*/
  async informMenuPcList(pKindId, kind) {
    const data = await request({
      url: "/stu/informMenu/selectPcList",
      method: "GET",
      params: {
        pKindId: pKindId,
        kind: kind,
      },
    });
    return data;
  }
  /**  项目分类基础数据*/
  async selectProjectTypeList() {
    const data = await request({
      url: "/enroll/schoolDataPc/selectProjectTypeList",
      method: "GET",
    });
    return data;
  }
  /* --------------记忆播放 ------------------------------------------------------ */
  /**  学习记录回放记录添加
   * courseType  记录类型1直播课/2录播课   */
  async addStudentLearningPlaybackRecords(
    courseType,
    uid,
    kid,
    chapterId,
    duration
  ) {
    const data = await request({
      url: `/stu/studentLearningPlaybackRecords/addStudentLearningPlaybackRecords`,
      method: "POST",
      params: {
        courseType: courseType,
        uid: uid,
        kid: kid,
        chapterId: chapterId,
        duration: duration,
      },
    });
    this.functionNo(data);
    return data;
  }
  /**  学习记录回放记录详情
   * courseType  记录类型1直播课/2录播课   */
  async getStudentLearningPlaybackRecords(courseType, uid, kid, chapterId) {
    const data = await request({
      url: `/stu/studentLearningPlaybackRecords/getStudentLearningPlaybackRecords`,
      method: "GET",
      params: {
        courseType: courseType,
        uid: uid,
        kid: kid,
        chapterId: chapterId,
      },
    });
    this.functionNo(data);
    return data;
  }
  // 获取收藏状态
  async getCollectProductStatus(params) {
    const data = await request({
      url: `/stu/studentCollectProduct/getCollectProductStatus`,
      method: "GET",
      params: params,
    });
    this.functionNo(data);
    return data;
  }
  // 收藏
  async collectProduct(params) {
    const data = await request({
      url: `/stu/studentCollectProduct/collectProduct`,
      method: "POST",
      data: params,
    });
    this.functionThen(data);
    return data;
  }
  // 获取的收藏班型列表
  async getStuCollectClassesProductList(params) {
    const data = await request({
      url: `/stu/studentCollectProduct/getStuCollectClassesProductList`,
      method: "GET",
      params: params,
    });
    this.functionNo(data);
    return data;
  }
  // 获取的收藏班型列表
  async getStuCollectProductList(params) {
    const data = await request({
      url: `/kecheng/courseSys/getStuCollectProductList`,
      method: "GET",
      params: params,
    });
    this.functionNo(data);
    return data;
  }
  /* --------------资格证 ------------------------------------------------ */

  /**  新闻数据（学校 分类）*/
  async getContentNewPcList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/getContentNewPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  问题数据（学校 分类）*/
  async getSchoolProblemPcList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/getSchoolProblemPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  父级下的数据*/
  async selectProjectTypePullList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectProjectTypePullList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  新闻详情数据*/
  async getContentNewPcData(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/getContentNewPcData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业列表*/
  async getEnrollBriefsTuitionPcList(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/getEnrollBriefsTuitionPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业下的院校*/
  async selectAllSchoolPcList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectAllSchoolPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业问题数据列表（专业 分类）*/
  async selectMajorProblemPcList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectMajorProblemPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业筛选数据*/
  async getTuitionPcFiltrateLists(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/getTuitionPcFiltrateList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业筛选数据*/
  async getSchoolPcPcFiltrateList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getSchoolPcPcFiltrateList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业筛选数据*/
  async getTuitionFiltrateList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getTuitionFiltrateList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业列表*/
  async getTechnicalTuitionPcList(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/getTechnicalTuitionPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业列表*/
  async getGraduateTuitionPcList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getGraduateTuitionPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业详情*/
  async getTuitionMajorData(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/getTuitionMajorData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业详情*/
  async getGraduateMajorData(params) {
    const data = await request({
      url: "/enroll/graduatePc/getGraduateMajorData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业下的院校*/
  async selectMajorSchoolPcList(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/selectMajorSchoolPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专业下的院校*/
  async getMajorSchoolPcList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getMajorSchoolPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校详情*/
  async getSchoolPcData(params) {
    const data = await request({
      url: "/enroll/graduatePc/getSchoolPcData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校详情*/
  async selectSchoolPcData(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectSchoolPcData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校详情*/
  async getStudySchoolData(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getStudySchoolData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校简章列表*/
  async selectSchoolBriefsPcList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectSchoolBriefsPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校简章列表*/
  async selectBriefsPcAbroadPage(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/selectBriefsPcAbroadPage",
      method: "GET",
      params,
    });
    return data;
  }

  /**  院校简章列表*/
  async selectBriefsPcGraduatePage(params) {
    const data = await request({
      url: "/enroll/graduatePc/selectBriefsPcGraduatePage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校师资团队*/
  async selectSchoolTeacherPage(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectSchoolTeacherPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校风采列表*/
  async selectSchoolDemeanourPage(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectSchoolDemeanourPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校简章详情*/
  async selectSchoolBriefsData(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectSchoolBriefsData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校简章详情*/
  async selectBriefsPcAbroadData(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/selectBriefsPcAbroadData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校简章详情*/
  async selectBriefsData(params) {
    const data = await request({
      url: "/enroll/graduatePc/selectBriefsData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校简章学费分页列表*/
  async selectTuitionFlatfeePage(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectTuitionFlatfeePage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校筛选条件*/
  async getTuitionPcFiltrateList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/getTuitionPcFiltrateList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  院校列表*/
  async selectSchoolPcList(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/selectSchoolPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  主考院校*/
  async selectProjectSchoolPcList(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/selectProjectSchoolPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  主考院校*/
  async selectGraduateSchoolPage(params) {
    const data = await request({
      url: "/enroll/graduatePc/selectGraduateSchoolPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  主考院校广告图*/
  async getAdvertConfiguration(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/getAdvertConfiguration",
      method: "GET",
      params,
    });
    return data;
  }
  /**  专本科报名咨询*/
  async addSchoolUserConsultation(params) {
    const data = await request({
      url: "/enroll/schoolDataPc/addSchoolUserConsultation",
      method: "POST",
      data: params,
    });
    return data;
  }
  /**  专本科项目下的专业 招生层次*/
  async getEnrollTuitionPcData(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/getEnrollTuitionPcData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  项目简章列表*/
  async selectProjectBriefsPcList(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/selectProjectBriefsPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  简章列表筛选条件*/
  async selectSchoolBriefsFiltrateData(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/selectSchoolBriefsFiltrateData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  简章所有列表*/
  async selectSchoolBriefsPcAllPage(params) {
    const data = await request({
      url: "/enroll/tuitionDataPc/selectSchoolBriefsPcAllPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  热门留学国家*/
  async getSchoolCountryList(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getSchoolCountryList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  热门留学国家*/
  async getStudyCountryPage(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getStudyCountryPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  留学国家院校列表*/
  async getStudyCountrySchoolPage(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getStudyCountrySchoolPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  在职研究生报名咨询专业列表*/
  async getSchoolList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getSchoolList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  出国留学报名咨询*/
  async addStudyAbroadUserConsultation(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/addStudyAbroadUserConsultation",
      method: "POST",
      data: params,
    });
    return data;
  }
  /**  在职研究生留学报名咨询*/
  async addGraduateUserConsultation(params) {
    const data = await request({
      url: "/enroll/graduatePc/addGraduateUserConsultation",
      method: "POST",
      data: params,
    });
    return data;
  }
  /**  留学学校推荐*/
  async getStudyAbroadSchoolPage(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getStudyAbroadSchoolPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  热门留学国家详情*/
  async getEnrollStudyCountry(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getEnrollStudyCountry",
      method: "GET",
      params,
    });
    return data;
  }
  /**  留学国家常见问题*/
  async getEnrollAgentProblemPage(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getEnrollAgentProblemPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  出国留学院校筛选条件*/
  async selectBriefsPcAbroadFiltrate(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/selectBriefsPcAbroadFiltrate",
      method: "GET",
      params,
    });
    return data;
  }
  /**  出国留学院校筛选条件*/
  async getStudyAbroadSchoolFiltrateData(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getStudyAbroadSchoolFiltrateData",
      method: "GET",
      params,
    });
    return data;
  }
  /**  出国留学院校全部列表*/
  async getStudySchoolAllPage(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/getStudySchoolAllPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  出国留学院校全部列表*/
  async getSchoolPcList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getSchoolPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  出国留学简章所有列表*/
  async selectBriefsPcAbroadAllPage(params) {
    const data = await request({
      url: "/enroll/studyAbroadPc/selectBriefsPcAbroadAllPage",
      method: "GET",
      params,
    });
    return data;
  }
  /**  热门专业列表*/
  async getBriefsTuitionPcList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getBriefsTuitionPcList",
      method: "GET",
      params,
    });
    return data;
  }
  /**  热门专业列表*/
  async getSchoolPcDataMajorList(params) {
    const data = await request({
      url: "/enroll/graduatePc/getSchoolPcDataMajorList",
      method: "GET",
      params,
    });
    return data;
  }
}

/**
 * 向后端发送视频观看记录数据
 * @param data 观看记录数据
 * @returns {*} request
 */
export function sendVideoHistoryDataBatch(data) {
  return request({
    url: "/kecheng/kafka/courseDatasendBatch",
    method: "POST",
    data,
    params: {
      domain: location.host,
    },
  });
}
/**
 * 向后端发送直播回放观看记录数据
 * @param data 观看记录数据
 * @returns {*} request
 */
export function sendLiveVideoHistoryBatch(data) {
  return request({
    url: "/kecheng/kafka/liveCourseDataSendBatch",
    method: "POST",
    data,
    params: {
      domain: location.host,
    },
  });
}
/**
 * 向后端发送直播观看记录数据
 * @param data 观看记录数据
 * @returns {*} request
 */
export function onLineLiveCourseDataSendBatch(data) {
  return request({
    url: "/kecheng/kafka/onLineLiveCourseDataSendBatch",
    method: "POST",
    data,
    params: {
      domain: location.host,
    },
  });
}
/**
 * pc APP 观看记录时长 修改数据
 * @param data 观看记录数据
 * @returns {*} request
 */
export function updateLearningDuration(data) {
  return request({
    url: "/kecheng/privateClassStudent/updateLearningDuration",
    method: "PUT",
    data,
  });
}
/**
 * 获取视频播放凭证
 */
export function playAuth(vid) {
  return request({
    url: `/ucenter/api/playAuth/${vid}`,
    method: "GET",
  });
}
/**
 * 精品课程分类
 */
export function getCourseFenLei() {
  return request({
    url: `/kecheng/courseAll/getCourseFenLei`,
    method: "GET",
  });
}
/**
 * 班型分类
 */
export function getClassesFenlei() {
  return request({
    url: `/kecheng//tenant/classes/getClassesFenlei`,
    method: "GET",
  });
}
// 学习中心---学习记录------------------------------------------
// 获取全部班型
export function selectStuClassesListByStudentId(params) {
  return request({
    url: `/kecheng/studyRecord/selectStuClassesListByStudentId`,
    method: "GET",
    params,
  });
}
// 根据班型获取对应课程
export function selectCourseNameByClassesId(params) {
  return request({
    url: `/kecheng/studentStudy/selectCourseNameByClassesId`,
    method: "GET",
    params,
  });
}
// PC-班型-直播-学习记录查询（学生+班型+课程名+时间+分页）
export function selectPcStuLiveRecordByClassIdKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuLiveRecordByClassIdKeId`,
    method: "GET",
    params,
  });
}
// PC-班型-录播-学习记录查询（学生+班型+课程名+时间+分页）
export function selectPcStuVideoRecordByClassIdKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuVideoRecordByClassIdKeId`,
    method: "GET",
    params,
  });
}
// PC-班型-考场-学习记录查询（学生+班型+课程名+时间+分页）
export function selectPcStuExamRecordByClassIdKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuExamRecordByClassIdKeId`,
    method: "GET",
    params,
  });
}
// PC-班型-试题-学习记录查询（学生+班型+课程名+时间+分页）
export function selectPcStuPaperRecordByClassIdKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuPaperRecordByClassIdKeId`,
    method: "GET",
    params,
  });
}
// PC-班型-资料-学习记录查询（学生+班型+课程名+时间+分页）
export function selectPcStuMaterialRecordByClassIdKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuMaterialRecordByClassIdKeId`,
    method: "GET",
    params,
  });
}
// PC-单科-直播-学习记录查询（学生+课名称+时间+分页）
export function selectPcStuLiveRecordBySingleKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuLiveRecordBySingleKeId`,
    method: "GET",
    params,
  });
}
// PC-单科-录播-学习记录查询（学生+课名称+时间+分页）
export function selectPcStuVideoRecordBySingleIdKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuVideoRecordBySingleIdKeId`,
    method: "GET",
    params,
  });
}
// PC-单科-资料-学习记录查询（学生+课名称+时间+分页）
export function selectPcStuMaterialRecordBySingleIdKeId(params) {
  return request({
    url: `/kecheng/studyRecord/selectPcStuMaterialRecordBySingleIdKeId`,
    method: "GET",
    params,
  });
}
// 课程下的全部课程
export function selectStuCourseListByStudentId(params) {
  return request({
    url: `/kecheng/studyRecord/selectStuCourseListByStudentId`,
    method: "GET",
    params,
  });
}
// 检查课程是否可以观看
export function courseCheckPlay(params) {
  return request({
    url: `/kecheng/kafka/courseCheckPlay`,
    method: "POST",
    params,
  });
}
// 检查课程是否已评论
export function judgeIsComment(params) {
  return request({
    url: `/kecheng/stuTeacherScore/judgeIsComment`,
    method: "GET",
    params,
  });
}

/* --------------1v1直播间 ------------------------------------------------ */
// 1v1 添加到课记录
export function saveArriveRecord(data) {
  return request({
    url: "/kecheng/coachingArriveRecord/saveArriveRecord",
    method: "POST",
    data,
  });
}
