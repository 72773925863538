// User
const tokenKey = 'token'
const UserInfoKey = 'userInfo'
/* ,{Domain: '.xlcwx.com',path: '/'} */
/* 用户信息 */
export const getInfo = () =>
  localStorage.getItem(UserInfoKey)
    ? JSON.parse(localStorage.getItem(UserInfoKey))
    : null
export const setInfo = (UserInfo) =>
  localStorage.setItem(UserInfoKey, JSON.stringify(UserInfo))
export const getToken = () => localStorage.getItem(tokenKey)
export const setToken = (token) => localStorage.setItem(tokenKey, token)
/* 是否有未读消息 */
const unReadyKey = 'noRead'
export const getReady = () =>
  localStorage.getItem(unReadyKey) ? localStorage.getItem(unReadyKey) : null
export const setReady = (unReadyVal) =>
  localStorage.setItem(unReadyKey, unReadyVal)
/* 入驻商Token */
const domainTokenKey = 'domainjwt'
export const getDomainToken = () =>
  localStorage.getItem(domainTokenKey)
    ? localStorage.getItem(domainTokenKey)
    : null
export const setDomainToken = (unReadyVal) =>
  localStorage.setItem(domainTokenKey, unReadyVal)
/* 入驻商*/
const domainKey = 'domain'
export const getDomain = () =>
  localStorage.getItem(domainKey)
    ? JSON.parse(localStorage.getItem(domainKey))
    : null
export const setDomain = (logo) =>
  localStorage.setItem(domainKey, JSON.stringify(
    logo))
/* 入驻商Logo */
const domainLogoKey = 'domainLogo'
export const getDomainLogo = () =>
  localStorage.getItem(domainLogoKey)
    ? localStorage.getItem(domainLogoKey)
    : null
export const setDomainLogo = (logo) =>
  localStorage.setItem(domainLogoKey, logo)
/* 入驻商版权信息 */
const introductionKey = 'copyright'
export const getDomainInfo = () =>
  localStorage.getItem(introductionKey)
    ? localStorage.getItem(introductionKey)
    : null
export const setDomainInfo = (logo) =>
  localStorage.setItem(introductionKey, logo)
/* 类型缓存 */
const classSelectEnumKey = 'classSelectEnum'
export const getClassSelectEnum = (name) =>
  sessionStorage.getItem(classSelectEnumKey + '-' + name)
    ? JSON.parse(sessionStorage.getItem(classSelectEnumKey + '-' + name))
    : null
export const setClassSelectEnum = (name, val) =>
  sessionStorage.setItem(classSelectEnumKey + '-' + name, JSON.stringify(val))
export const removeClassSelectEnum = (name) =>
  sessionStorage.removeItem(classSelectEnumKey + '-' + name)
