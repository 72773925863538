/*
 * @Author: 南桥几许
 * @Date: 2021-06-30 10:53:19
 * @LastEditTime: 2023-10-21 15:08:20
 * @LastEditors: 隐世浮华
 * @Description:
 * @FilePath: \edub_tob_pc\src\common\utils\request.js
 *
 */
import router from '@/router'
import axios from 'axios'
import {
  loginOut
} from '@/api/user'
import {
  getDomainToken,
  getToken,
  getDomain
} from '@/api/cookies'

import {
  selectEntrantsSeoPcList
} from '@/api/home'
// import { Notification, MessageBox, Message } from 'element-ui'
// import store from "../../store"
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API // url = base url + request url
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    var domainjwt =
      //   'entrants:83771108f021664bd3972fc5913ba23f'
      process.env.VUE_APP_BASE_IfAl == 'true'
        ? getDomainToken()
        : 'entrants:1b22c90fdd7a4b4494dc0dbbaa1ce1f3'
    var token = getToken()
    if (domainjwt) {
      config.headers["domainjwt"] = domainjwt;
      config.headers['terminal'] = 'pc'
    } else {
      if (config.url != '/ucenter/business/getDnsOneApi') {
        // 页面强制刷新
        window.location.reload()
      }
      // 页面强制刷新
      // window.location.reload();
    }
    config.headers['type'] = '1' // 用于区分token的key值PC：1 M:2
    if (token) {
      // config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['token'] = token
    }
    if (config.url == '/ucenter/business/getDnsOneApi') {
      selectEntrantsSeoPcList().then((res) => {
        if (res.data) {
          sessionStorage.setItem('seo', JSON.stringify(res.data))
        }
        let a = sessionStorage.getItem('seo')
        if (a) {
          a = JSON.parse(a)
        }
        if (a && a.length > 0) {
          for (const e of a) {
            if (e.typeName == to.name) {
              document.title = e.seoTitle
              document
                .querySelector('meta[name="keywords"]')
                .setAttribute('content', e.seoKey)
              document
                .querySelector('meta[name="description"]')
                .setAttribute('content', e.seoDescribe)
              break
            } else if (to.name == '项目频道页' && e.kindId == to.query.id) {
              document.title = e.seoTitle
              document
                .querySelector('meta[name="keywords"]')
                .setAttribute('content', e.seoKey)
              document
                .querySelector('meta[name="description"]')
                .setAttribute('content', e.seoDescribe)
              break
            } else {
              document.title = getDomain() ? getDomain().name : ''
              document
                .querySelector('meta[name="keywords"]')
                .setAttribute('content', '')
              document
                .querySelector('meta[name="description"]')
                .setAttribute('content', '')
            }
          }
        } else {
          document.title = getDomain() ? getDomain().name : ''
          document.querySelector('meta[name="keywords"]').setAttribute('content', '')
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', '')
        }
      })
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code == 500) {
      /* ||res.msg == '请求没有token.请先登陆获取token' */
      if (
        res.msg == 'token 已经过期无效' ||
        res.msg == 'token 解析失败,已经过期无效'
      ) {
        localStorage.clear()
        if (response.request.responseURL.indexOf('/stu/front/logOut') == -1) {
          loginOut()
        }
        // setTimeout(() => {
        window.location.reload()
        router.replace(`/`)
        // }, 2000);
      } else {
        return response.data
      }
    } else {
      return response.data
    }
  },
  (error) => {
    /* Message({
      message: '服务器调用错误',
      type: 'error',
      duration: 5 * 1000
    }) */
    console.log('服务器调用错误')

    return Promise.reject(error)
  }
)
export default service
