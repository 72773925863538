import request from "@/common/utils/request.js";
import Vue from "vue";

export class Zhpassword {
  // 找回密码第一步点击确定按钮 验证手机号 图形码
  retrievePwStart(as, bs) {
    return request({
      url: "/stu/front/retrievePwStart",
      method: "POST",
      params: {
        mobile: as,
        code: bs,
      },
    });
  }
  // 找回密码第二步 点击按钮发送验证码

  // 找回密码第二步点击确定按钮 验证手机号 图形码 验证码/*  */
  retrievePcSmspcWeb(as, bs) {
    return request({
      url: "/stu/front/retrievePcSms",
      method: "POST",
      params: {
        mobile: as,
        token: bs,
        // code :cs
      },
    });
  }
  // 找回密码第三步 修改密码
  updatePwPassword(as, bs) {
    return request({
      url: "/stu/front/updatePwPassword",
      method: "POST",
      params: {
        mobile: as,
        password: Vue.prototype.encrypt(bs),
      },
    });
  }
}
