/* 授课计划 */
export class TimeFunction {
  /*
   得到本周、上周、下周的起始、结束日期
      getMonday(type,dates)  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表本周，-1代表上周，1代表下周
   getMonday("s",1)  //得到下周一的yyyy-mm-dd格式日期
   getMonday("e",1)  //得到下周日的yyyy-mm-dd格式日期 */
  getMonday(data, type, dates) {
    var now = data ? new Date(data) : new Date()
    var nowTime = now.getTime()
    var day = now.getDay()
    var longTime = 24 * 60 * 60 * 1000
    var n = longTime * 7 * (dates || 0)
    var dd
    if (type == 's') {
      dd = nowTime - (day - 1) * longTime + n
    }
    if (type == 'e') {
      dd = nowTime + (7 - day) * longTime + n
    }
    dd = new Date(dd)
    var y = dd.getFullYear()
    var m = dd.getMonth() + 1
    var d = dd.getDate()
    m = m < 10 ? '0' + m : m
    d = d < 10 ? '0' + d : d
    var dayVal = y + '-' + m + '-' + d
    return dayVal
  }
  /* 得到本月、上月、下月的起始、结束日期 */
  async getMonth(data, type, months) {
    var d = data ? new Date(data) : new Date()
    var year = d.getFullYear()
    var month = d.getMonth() + 1
    if (months && Math.abs(months) > 12) {
      months = months % 12
    }
    if (months && months != 0) {
      if (month + months > 12) {
        year++
        month = (month + months) % 12
      } else if (month + months < 1) {
        year--
        month = 12 + month + months
      } else {
        month = month + months
      }
    }
    month = (await month) < 10 ? '0' + month : month
    var date = d.getDate()
    var firstday = year + '-' + month + '-' + '01'
    var lastday = ''
    if (
      month == '01' ||
      month == '03' ||
      month == '05' ||
      month == '07' ||
      month == '08' ||
      month == '10' ||
      month == '12'
    ) {
      lastday = year + '-' + month + '-' + 31
    } else if (month == '02') {
      if (
        (year % 4 == 0 && year % 100 != 0) ||
        (year % 100 == 0 && year % 400 == 0)
      ) {
        lastday = year + '-' + month + '-' + 29
      } else {
        lastday = year + '-' + month + '-' + 28
      }
    } else {
      lastday = year + '-' + month + '-' + 30
    }
    var day = ''
    if (type == 's') {
      day = firstday
    } else {
      day = lastday
    }
    return day
  }
  /*   前后多少天日期
   *fun_date(7);//7天后的日期
   fun_date(-7);//7天前的日期   */
  async fun_date(aa, date) {
    var date1 = date ? new Date(date) : new Date()
    var time1 =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1) +
        '-' +
        date1.getDate() // time1表示当前时间
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + aa)
    const month =
      date2.getMonth() + 1 < 10
        ? '0' + (date2.getMonth() + 1)
        : date2.getMonth() + 1
    const day = date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate()
    var time2 = date2.getFullYear() + '-' + month + '-' + day
    return time2
  }
  /*   传入时间戳返回yyyy-mm-dd hh:mm:ss   */
  getDate(timestamp) {
    const date = new Date(timestamp) // 将时间戳转换为毫秒并创建Date对象
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)
    const seconds = ('0' + date.getSeconds()).slice(-2)
    const dateFormate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    return dateFormate
  }
  /* 计算两个日期相差的天数
   * sDate1和sDate2是2006-12-18格式
   */
  async DateDiff(sDate1, sDate2) {
    sDate1 = await new Date(sDate1).toLocaleDateString()
    sDate2 = await new Date(sDate2).toLocaleDateString()

    var aDate, oDate1, oDate2, iDays
    aDate = sDate1.split('/')
    oDate1 = new Date(aDate[0], aDate[1], aDate[2]) // 转换为12-18-2006格式
    aDate = sDate2.split('/')
    oDate2 = new Date(aDate[0], aDate[1], aDate[2])
    iDays = parseInt(String(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24)) // 把相差的毫秒数转换为天数
    return iDays
  }
  /* 计算两个日期相差的天数/时分秒
   * sDate1和sDate2是2006-12-18格式
   */

  async DateDiffTime(begintime, endtime) {
    var date1 = begintime ? new Date(begintime) : new Date() // 开始时间
    var date2 = endtime ? new Date(endtime) : new Date() // 结束时间
    var date3 = date2.getTime() - date1.getTime() // 时间差的毫秒数
    // 计算出相差天数
    var days = Math.floor(date3 / (24 * 3600 * 1000))
    // 计算出小时数
    var leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000))
    // 计算相差分钟数
    var leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000))
    // 计算相差秒数
    var leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
    var seconds = Math.round(leave3 / 1000)
    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    }
  }
}
