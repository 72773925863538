<template>
  <div class="mainbox">
    <div class="news_box">
      <div class="news_til">
        <div class="news_til_left">
          <div class="news_til_left_logo">
            <img src="~@/assets/img/TechnicalCollege/30.png" alt="">
          </div>
          <div class="news_til_left_til">资讯新闻</div>
        </div>
        <div @click="goNext(`/gxGather/components/news`)" class="news_til_right">查看更多 <img
            src="~@/assets/img/TechnicalCollege/48.png" alt=""></div>
      </div>
      <div class="news_concent">
        <div class="news_concents">
          <div @click="goNext(`/gxGather/components/newsDetail?id=${item.id}`)" v-for="(item, index) in newsList"
            :key="index">
            <div></div>
            <div class="elp">{{ item.newTitle }}</div>
          </div>

        </div>
      </div>
    </div>
    <div class="news_box">
      <div class="news_til">
        <div class="news_til_left">
          <div class="news_til_left_logo">
            <img src="~@/assets/img/TechnicalCollege/45.png" alt="">
          </div>
          <div class="news_til_left_til">常见问题</div>
        </div>
        <div @click="goNext(`/gxGather/components/question`)" class="news_til_right">查看更多 <img
            src="~@/assets/img/TechnicalCollege/48.png" alt=""></div>
      </div>
      <div class="news_concent">
        <div class="news_concents">
          <div v-for="(item, index) in qusetionList" :key="index">
            <div v-show="false"></div>
            <div class="elp">{{ item.questionName }}</div>
          </div>

        </div>
      </div>
    </div>
    <div class="apply_box">
      <div class="apply_box_til">报名咨询</div>
      <div class="form">
        <el-form class="centent_form" :rules="rules" ref="form" :model="form" label-position="left">
          <el-form-item label="" prop="name">
            <el-input v-model="form.name" placeholder="您的称呼"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <el-input v-model="form.phone" placeholder="手机号码"></el-input>
          </el-form-item>
          <el-form-item label="" prop="code">
            <el-input v-model="form.code" placeholder="验证码">
              <span class="yCode" slot="suffix" v-if="!downTime" @click="codeClick">获取验证码</span>
              <el-statistic v-else slot="suffix" :value="countDown" time-indices format="ss" @finish="timeFinish">
                <template slot="suffix">s</template>
              </el-statistic>
            </el-input>
          </el-form-item>

          <el-form-item prop="examStage" label="">
            <el-select v-model="form.examStage" placeholder="报考层次">
              <el-option label="高起专" value="高起专"> </el-option>
              <el-option label="高起本" value="高起本"> </el-option>
              <el-option label="专升本" value="专升本">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div @click="submitClick" class="apply_box_btn">立即咨询</div>
        <div class="apply_box_des">提交咨询后，我们会24小时内电话联系您</div>

      </div>

    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import { loginSmsCode } from "@/api/login";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
  components: {},
  props: {
    id: '',
  },
  data() {
    return {
      countDown: Date.now() + 1000 * 60,
      downTime: false,

      search: {
        pageSize: 8,
        pageNum: 1,
        projectTypeId: this.id
      },
      form: {},
      rules: {
        name: [{ required: true, message: " ", trigger: "blur" }],
        examStage: [{ required: true, message: " ", trigger: "blur" }],
        phone: [
          { required: true, message: " ", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: " ",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: " ", trigger: "blur" }],
      },
      newsList: [],
      qusetionList: [],
    };
  },
  created() {
    console.log(this.search, 'search');
    know.getContentNewPcList({ ...this.search }).then((res) => {
      this.newsList = res.rows
    });
    know.getSchoolProblemPcList({ ...this.search }).then((res) => {
      this.qusetionList = res.rows
    });
  },
  mounted() {

  },
  methods: {
    // 获取验证码
    async codeClick() {

      this.countDown = Date.now() + 1000 * 60;
      if (!this.form.phone) {
        this.$message.error("请先输入手机号");
        return;
      } else {
        const res = await loginSmsCode(this.form.phone, "welcomePotential");
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.downTime = true;
        } else if (res.code === 500) {
          this.$message.error("请勿频繁获取！");
        }
      }
    },
    // 倒计时结束
    timeFinish(val) {
      if (val) return (this.downTime = false);
    },
    // 立即提交
    submitClick() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await know.addSchoolUserConsultation({
            studentName: this.form.name,
            mobile: this.form.phone,
            mobileToken: this.form.code,
            examStage: this.form.examStage,
            consultationClassify: localStorage.getItem("consultationClassify"),
            projectType: "0",
          });
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.form = {}
            this.downTime = false
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请将信息填写完整 ！");
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.mainbox {
  width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  background: #fff;

  .news_box {
    width: 380px;

    .news_til {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .news_til_left {
        display: flex;
        align-items: center;

        .news_til_left_logo {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #E9F4FF;
        }

        .news_til_left_til {
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
          font-weight: bold;
          font-size: 18px;
          color: #333333;
          margin-left: 5px;
        }
      }

      .news_til_right {

        font-family: PingFangSC-Regular, PingFangSC-Regular;
        font-weight: normal;
        font-size: 14px;
        color: #777777;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 10px;
          height: 10px;
          margin-left: 4px;
        }
      }
    }

    .news_concent {
      width: 380px;
      height: 250px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #DDDDDD;
      margin-top: 13px;

      .news_title {
        display: flex;

        >div {
          width: 50%;
          text-align: center;
          border-bottom: 1px solid #F2F2F2;
          padding: 6px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
          font-weight: normal;
          font-size: 14px;
          color: #555555;
          cursor: pointer;
        }

        .news_choose {
          background: #3D6CC8;
          color: #FFFFFF;
        }


      }

      .news_concents {
        padding: 15px;

        >div {
          display: flex;
          align-items: center;

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 14px;
          color: #444444;
          margin-bottom: 10px;
          cursor: pointer;

          >div:nth-child(1) {
            width: 3px;
            height: 3px;
            background: #E1E1E1;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
          }

          >div:nth-child(2) {
            width: 300px;
          }
        }

        >div:hover {
          color: #BD1212;
        }
      }
    }
  }



  .apply_box {
    width: 358px;
    height: 295px;
    background: #F1F8FA;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #4298B5;

    .apply_box_til {
      width: 100%;
      height: 40px;
      background: url('~@/assets/img/TechnicalCollege/2.png');
      background-size: 100% 100%;
      text-align: center;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 40px;
      margin-top: -1px;

    }

    .form {
      padding: 0 20px;
      margin: 25px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      input {
        width: 150px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DDDDDD;
        padding: 0 10px;
      }

      input:nth-child(3),
      input:nth-child(4) {
        margin-top: 20px;
      }

      select {
        width: 150px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DDDDDD;
        padding: 0 10px;
        margin-top: 20px;

      }


    }

    .apply_box_btn {
      width: 230px;
      height: 40px;
      background: #BC3535;
      border-radius: 21px 21px 21px 21px;
      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      margin-top: 30px;
    }

    .apply_box_des {

      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      font-size: 12px;
      color: #999999;
      text-align: center;
      margin: 0 auto;
      margin-top: 15px;
    }
  }
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

.centent_form {
  // margin-top: 57px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  /deep/ .el-form-item:nth-last-child(1) {
    margin-right: 0;
  }

  /deep/ .el-input__suffix {
    right: 16px !important;
  }

  /deep/ .el-form-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    // margin-right: 10px;

    .el-form-item__label {
      font-size: 18px;
      font-family: PingFangSC-Medium-, PingFangSC-Medium;
      font-weight: normal;
      color: #444444;
      letter-spacing: 1px;
      padding: 0;
    }

    .el-form-item__label::before {
      content: "";
      margin-right: 0;
    }

    .el-form-item__content {
      width: 150px;
      color: #848484 !important;

      // margin-left: 0px !important;
      .el-input {
        width: 100%;
        color: #848484 !important;

        .el-input__inner {
          width: 140px;
          height: 34px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #DDDDDD;
          padding: 0 10px;
        }

        .yCode {

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 12px;
          color: #1061FF;
          cursor: pointer;
        }

        .el-statistic {
          margin: 13px 5px 0 0;
        }

        .number {
          font-size: 12px;
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-weight: normal;
          color: #333333;
          letter-spacing: 1px;
        }
      }

      ::placeholder {
        color: #848484 !important;
      }

      // height: 30px;
      // border-radius: 30px;
    }
  }
}
</style>