import { render, staticRenderFns } from "./submitschool.vue?vue&type=template&id=8b1318c4&scoped=true&"
import script from "./submitschool.vue?vue&type=script&lang=js&"
export * from "./submitschool.vue?vue&type=script&lang=js&"
import style0 from "./submitschool.vue?vue&type=style&index=0&id=8b1318c4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b1318c4",
  null
  
)

export default component.exports