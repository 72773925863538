import Vue from 'vue'
import Bus from '@/api/bus'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import format from 'date-fns/format'
import './plugins/axios'
import axios from 'axios'
import DateRangePicker from 'vue-mj-daterangepicker'
import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

Vue.prototype.$http = axios
Vue.config.productionTip = false
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.use(Bus)
import preventClick from '@/common/utils/preventClick'
Vue.use(preventClick)
Vue.use(DateRangePicker)
import VueAreaLinkage from 'vue-area-linkage'
import 'vue-area-linkage/dist/index.css'
import '@/assets/css/all.less'
import '@/assets/css/homethree.less'
import '@/style/css/main.less'
/* 公共方法引入 */
import comFun from '@/api/comFun'
import { selectEntrantsSeoPcList } from '@/api/home'
import { getDomain } from '@/api/cookies'
Vue.use(comFun)
// 生成二维码
import VueQriously from 'vue-qriously'
Vue.use(VueQriously)
Vue.use(VueAreaLinkage)
// 自定义过滤器
Vue.filter('format', function (value, formatStr) {
 
  let time = new Date(value ? value.replace(/-/g, '/') : '')
  return format(time == 'Invalid Date' ? new Date(value) : time, formatStr)
})
// 字符串截选
Vue.filter('ellipsis', function (value, number) {
  if (!value) return ''
  if (value.length > number) {
    return value.slice(0, number) + '...'
  }
  return value
})
router.beforeEach(async (to, from, next) => {
  let seoval = sessionStorage.getItem('seo')
  if (
    (!seoval && seoval != 'null' && seoval != 'undefind') ||
    (seoval && seoval.length == 0) ||
    to.path == '/'
  ) {
    if (localStorage.getItem('domainjwt')) {
      await selectEntrantsSeoPcList().then((res) => {
        if (res.data) {
          sessionStorage.setItem('seo', JSON.stringify(res.data))
        }
      })
    }
  }
  // if (sessionStorage.getItem('seo') == null) {
  //   await selectEntrantsSeoPcList().then((res) => {
  //     if (res.data) {
  //       sessionStorage.setItem("seo", JSON.stringify(res.data));
  //     }
  //   });
  // }
  let a = sessionStorage.getItem('seo')
  if (a) {
    a = JSON.parse(a)
  }
  if (a && a.length > 0) {
    for (let e of a) {
      if (e.typeName == to.name) {
        document.title = e.seoTitle
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute('content', e.seoKey)
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', e.seoDescribe)
        break
      } else if (to.name == '项目频道页' && e.kindId == to.query.id) {
        document.title = e.seoTitle
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute('content', e.seoKey)
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', e.seoDescribe)
        break
      } else {
        document.title = getDomain() ? getDomain().name : ''
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute('content', '')
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', '')
      }
    }
  } else {
    document.title = getDomain() ? getDomain().name : ''
    document.querySelector('meta[name="keywords"]').setAttribute('content', '')
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', '')
  }
  next()
  // if(to.path!='/' && !token){
 
  //       next({path: '/'})
  //     } else {
 
  //         next()
  //       }
})
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
