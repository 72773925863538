import request from '@/common/utils/request.js'
import Vue from 'vue'

// 图形验证码
function imagecode() {
  return request({
    url: '/stu/image/code',
    method: 'GET',
    responseType: 'arraybuffer',
  })
}

// 图形验证码
function codeObj() {
  return request({
    url: '/stu/image/codeObj',
    method: 'GET',
  })
}
// 注册验证图形验证码
function selectCode(as) {
  return request({
    url: '/stu/front/selectCode',
    method: 'GET',
    params: {
      code: as,
    },
  })
}
// 注册
// function addPcUser(as, bs, cs, ds, account) {
function addPcUser(as, cs, ds, account) {
  return request({
    url: '/stu/front/addPcUser',
    method: 'POST',
    // headers:{
    //     'Content-Type':'application/x-www-form-urlencoded'
    // },
    params: {
      nickName: as,
      // password: Vue.prototype.encrypt(bs),
      mobile: cs,
      sjToken: ds,
      account: account,
      userSource: 'PC端',
    },
  })
}
// 验证手机号唯一性
function selectMobile(mobile, account) {
  return request({
    url: '/stu/front/selectMobile',
    method: 'GET',
    params: {
      mobile: mobile,
      account: account,
    },
  })
}
// 验证手机号唯一性
function selectRegisterMobile(mobile, account) {
  return request({
    url: '/stu/front/selectRegisterMobile',
    method: 'GET',
    params: {
      mobile: mobile,
      account: account,
    },
  })
}

export {
  imagecode,
  addPcUser,
  codeObj,
  selectMobile,
  selectCode
}