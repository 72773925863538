<template>
  <div class="warp">
    <!-- 大盒子背景图 hzc == 1 登录-->
    <div v-if="hzc == 1" class="box">
      <!-- 小盒子定位 b==1-->
      <div v-if="b == 1" class="boxs">
        <!-- 微信登录 -->
        <!-- <div class="weiXin"> -->
        <!-- <div class="weiXinLeft" @click="weixin">微信登录</div>
          <div class="weiXinRight" @click="weixin">
            <img src="@/assets/img/Login/erweima.png" alt="" />
          </div> -->
        <!-- </div> -->
        <!-- 密码、短信登录选择 -->
        <div class="choice">
          <div class="choiceLeft" style="margin-right: 30px">
            <div class="choiceLeftText" :class="[a == 2 ? 'class1' : '']" @click="message">
              快捷登录
            </div>
            <div v-show="a == 2" class="choiceLefts" />
          </div>
          <div class="choiceLeft">
            <div class="choiceLeftText" :class="[a == 1 ? 'class1' : '']" @click="pword">
              密码登录
            </div>
            <div v-show="a == 1" class="choiceLefts" />
          </div>
          <!-- <div class="choices"></div> -->
        </div>
        <!-- 密码、短信登录选择内容 -->
        <div v-show="a == 1" class="content">
          <div class="contentTop">
            <el-form ref="passwordLogin" :model="ruleForma" :rules="rules">
              <el-form-item prop="username">
                <el-input v-model.trim="ruleForma.username" placeholder="请输入账号" @input="accountVerify" />
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model.trim="ruleForma.password" placeholder="请输入密码" show-password
                  @keyup.enter.native="submit" @input="passwordVerify" />
              </el-form-item>
            </el-form>
            <!-- 选择框自动登录忘记密码 -->
            <div class="contentTops">
              <div class="contentTopsLeft">
                <input type="checkbox">
                <p>自动登录</p>
              </div>
              <span @click="zhaoPassword">忘记密码？</span>
            </div>
            <!-- 登录按钮 -->
            <el-button :style="loginBgColor ? 'background: #CCCCCC' : 'background: #FF5E51'
              " class="contentTopBtn" :loading="loading" :disabled="loginBgColor" style="margin-top: 50px"
              @click="submitAntiShaking" @keyup.enter.native="submitAntiShaking">
              登录
            </el-button>
          </div>
          <!-- 底部 没有账号？点我注册-->
          <div class="contentBtm">
            没有账号？&nbsp;<span @click="goNext()">点我注册</span>
          </div>
        </div>
        <div v-show="a == 2" class="content">
          <div class="contentTop">
            <el-form ref="duanxinFrom" :model="ruleFormb" :rules="rules">
              <el-form-item prop="phones" style="background: #f2f2f2">
                <span class="add86 shrink0">+86</span>
                <el-input v-model.trim="ruleFormb.phones" placeholder="请输入手机号" maxlength="11" @input="phoneVerify" />
              </el-form-item>
              <!-- <el-form-item prop="imgCode">
                <div class="dis_flex_between">
                  <el-input
                    placeholder="验证码"
                    prefix-icon="el-icon-c-scale-to-original"
                    v-model.trim="ruleFormb.imgCode"
                    style="width: 60%"
                  />
                  <div class="diva">
                    <img :src="captcha" alt="验证码" @click="refresh" />
                  </div>
                </div>
              </el-form-item> -->
              <el-form-item prop="shortCode">
                <div class="dis_flex_between" style="width: 100%">
                  <el-input v-model.trim="ruleFormb.shortCode" placeholder="请输入验证码" style="width: 60%"
                    @input="codeVerify" />
                  <p type="button" :class="time > 0 ? 'nodivb notClick' : 'divb'" :disabled="disable"
                    @click="dongTaiMa">
                    {{ title }}
                  </p>
                </div>
              </el-form-item>
            </el-form>
            <!-- 选择框自动登录忘记密码 -->
            <div class="contentTops">
              <div class="contentTopsLeft">
                <input type="checkbox">
                <p>自动登录</p>
              </div>
              <!-- <span @click="zhaoPassword">忘记密码？</span> -->
            </div>
            <!-- 登录按钮 -->
            <el-button ref="loginSubmit" v-preventClick class="contentTopBtn" style="margin-top: 50px" :style="loginBgColorPhone
              ? 'background: #CCCCCC'
              : 'background: #FF5E51'
              " :disabled="loginBgColorPhone" @keyup.enter.native="submits" @click="submits">登录</el-button>
            <!-- 底部 没有账号？点我注册-->
          </div>
          <div class="contentBtm">
            没有账号？&nbsp;<span @click="goNext()">点我注册</span>
          </div>
        </div>
      </div>
      <!-- 小盒子定位 b==2-->
      <div v-if="b == 2" class="boxs">
        <!-- 密码登录 -->
        <div class="weiXin">
          <div class="weiXinLeft" @click="weixins">密码登录</div>
          <div class="weiXinRight" @click="weixins">
            <img src="@/assets/img/Login/mima.png" alt="">
          </div>
        </div>
        <div class="weiXinName">微信登录</div>
        <div class="weiXinImg">
          <img src="@/assets/img/Login/weixin.png" alt="">
        </div>
        <div class="weiXinNames">
          请使用微信扫描二维码登录 “{{ domainInfo.name }}网校”
        </div>
        <div class="weiXinFoot">
          没有账号？&nbsp;<span @click="goNext()">点我注册</span>
        </div>
      </div>
    </div>
    <el-dialog width="600px" :visible.sync="imgCodeDio">
      <imgCode @codeBack="codeBack" v-if="imgCodeDio"></imgCode>
    </el-dialog>

  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { imagecode, addPcUser, selectMobile, selectCode } from '@/api/register'
import { loginPcUser, SmsMobileCode, loginPcUserSms } from '@/api/login'
import { getDomainInfo, getDomain } from '@/api/cookies'
import { setInfo, setToken } from '@/api/cookies'
import imgCode from '@/views/login/imgCode.vue'

export default {
  components: { imgCode },
  data() {
    const codeProp = (rule, value, callback) => {
      selectCode(value).then((res) => {
        if (res.code == 0) {
          callback()
        } else {
          callback('请输入正确的验证码')
        }
      })
    }

    return {
      domainInfo: {}, // 机构信息
      loginBgColorPhone: true,
      loginBgColor: true,
      imgCodeDio: false,
      userSource: 'PC端',
      w: 1,
      hzc: 1,
      a: 2,
      b: 1,
      timer: null,
      disable: false,
      title: '获取验证码',
      time: 0,
      input: '',
      loading: false,
      welcomePotential: 'confirmToken',
      ruleForma: {},
      ruleFormb: {
        phones: '',
        // imgCode: "",
        shortCode: '',
        welcomePotential: 'welcomePotential'
      },
      captcha: '',
      rules: {
        username: [
          { required: true, message: '账号不能为空', trigger: 'blur' }
        ],
        phones: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码不能长度小于6个字符', trigger: 'blur' }
        ],
        // imgCode: [
        //   { required: true, message: "图形验证码不能为空", trigger: "blur" },
        //   {
        //     min: 4,
        //     max: 4,
        //     message: "请输入正确的图形验证码",
        //     trigger: "blur",
        //   },
        //   {
        //     validator: codeProp,
        //     trigger: "blur",
        //   },
        // ],
        shortCode: [
          { required: true, message: '手机验证码不能为空', trigger: 'blur' }
        ]
      },
      href: ''
    }
  },
  mounted() {
    setTimeout(() => {
      if (!!this.ruleForma.username && !!this.ruleForma.password) {
        this.loginBgColor = false
      }
      if (!!this.ruleFormb.phones && !!this.ruleFormb.shortCode) {
        this.loginBgColorPhone = false
      }
    }, 1000)
  },
  created() {
    this.href = this.$route.query.href
    console.log(this.href, 'href')
    this.domainInfo = getDomain()
    if (this.$route.query.hzc) {
      this.hzc = this.$route.query.hzc
    } else {
      this.hzc = 1
    }
    this.loadCaptcha()
  },
  methods: {
    async codeBack(val, vals) {
      this.imgCodeDio = false
      if (val && vals) {
        if (this.time > 0) {
          return
        }
        if (!/^[1]([3-9])[0-9]{9}$/.test(this.ruleFormb.phones)) {
          this.$message.error('手机号格式不正确')
          return
        }
        if (this.ruleFormb.phones) {
          let code
          await selectMobile(this.ruleFormb.phones).then((res) => {
            code = res.code
          })
          if (code == 0) {
            this.$message.error('当前手机号不存在，请先去注册账号')
            return
          }
          await SmsMobileCode({
            mobile: this.ruleFormb.phones,
            tenantid: this.domainInfo.eId,
            source: 'Pc端',
            imgKey: vals,
            imgCode: btoa(val + '4205140546'),
          }
          ).then((res) => {
            if (res.code != 0) {
              this.$message.error(res.msg)
            } else {
              this.time = 60
              this.$message.success(res.msg)
              var www = setInterval(() => {
                if (this.time < 1) {
                  this.title = '获取验证码'
                  this.disable = false
                  clearInterval(www)
                } else {
                  this.title = this.time-- + '秒后重发'
                  this.disable = true
                }
              }, 1000)
            }
          })
        } else {
          this.$message.error('请输入手机号')
        }
      }
    },
    accountVerify() {
      if (!!this.ruleForma.username && !!this.ruleForma.password) {
        this.loginBgColor = false
      } else {
        this.loginBgColor = true
      }
    },
    passwordVerify() {
      if (!!this.ruleForma.username && !!this.ruleForma.password) {
        this.loginBgColor = false
      } else {
        this.loginBgColor = true
      }
    },
    phoneVerify() {
      if (!!this.ruleFormb.phones && !!this.ruleFormb.shortCode) {
        this.loginBgColorPhone = false
      } else {
        this.loginBgColorPhone = true
      }
    },
    codeVerify() {
      if (!!this.ruleFormb.phones && !!this.ruleFormb.shortCode) {
        this.loginBgColorPhone = false
      } else {
        this.loginBgColorPhone = true
      }
    },
    goNext() {
      this.$emit('toRegister')
    },
    // ...mapActions(['loginPcUser']),
    // 刷新图形验证码验证码
    refresh() {
      this.loadCaptcha()
      // this.ruleFormb.imgCode = "";
    },
    // 获取图形验证码
    loadCaptcha() {
      imagecode().then((res) => {
        this.captcha = `data: image/jpeg;base64,${btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )}`
      })
    },
    // 显示登陆
    headDengLu() {
      this.hzc = 1
    },
    // 找回密码
    zhaoPassword() {
      this.$emit('showForgot')
      // this.hzc = 3;
      // this.$router.push("/forget");
    },
    // 微信登录
    weixin() {
      this.b = 2
    },
    // 密码登录
    weixins() {
      this.b = 1
    },
    // 密码登录选择
    pword() {
      this.a = 1
      this.$refs.passwordLogin.clearValidate()
    },
    // 短信登录选择
    message() {
      this.a = 2
      this.$refs.duanxinFrom.clearValidate()
    },
    submitAntiShaking() {
      this.timer = setTimeout(() => {
        // if (!this.timer) {
        // }
        if (!this.timer) {
          clearTimeout(this.timer)
        }
        this.loading = true
        console.log('防抖中')
        this.submit()
        clearTimeout(this.timer)
      }, 0)
    },
    // 用户名密码登录
    submit() {
      this.$refs.passwordLogin.validate(async (valid) => {
        if (valid) {
          loginPcUser(
            this.ruleForma.username,
            this.ruleForma.password,
            this.userSource
          ).then((res) => {
            if (res.code == 0) {
              sessionStorage.setItem('loginInfo', '1')
              console.log(this.href, 'this.href')
              this.$emit('toHome')
              // 存储token
              if (this.href) {
                this.$router.push(this.href)
              } else {
                window.location.reload()
              }
              // this.$router.push("/");
              Bus.$emit('toLoginPage', false)
              this.loading = false
            } else {
              this.$message.error(res.msg)
              this.loading = false
            }
          })
        }
      })
      this.$refs.passwordLogin.disable = true
    },
    // 短信登录
    submits() {
      this.$refs.duanxinFrom.validate(async (valid) => {
        if (valid) {
          loginPcUserSms(
            this.ruleFormb.phones,
            this.ruleFormb.shortCode,
            // this.ruleFormb.imgCode,
            this.userSource,
            this.tenantId
          ).then((res) => {
            if (res.code == 0) {
              /* sessionStorage.setItem("loginInfo", "1");
              localStorage.setItem("userId", res.data.user.id);
              setInfo(res.data.user);
              setToken(res.data.token); */
              if (this.href) {
                this.$router.push(this.href)
              } else {
                window.location.reload()
              }
              Bus.$emit('toLoginPage', false)
              // this.$router.push("/");
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 获取动态码
    async dongTaiMa() {
      this.imgCodeDio = true
      return
    }
  }
}
</script>
<style lang="less" scoped>
.warp {
  width: 100%;

  // height: 1080px;
  // 导航栏顶部灰条
  .header {
    width: 100%;
    height: 36px;
    background: #f5f7fa;

    // 宽度缩放60%
    .headers {
      width: 65%;
      height: 100%;
      margin: auto;
      // border: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // 左侧
      ul {
        width: auto;
        height: 100%;

        li {
          float: left;
          line-height: 36px;
          margin-right: 30px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
      }

      // 右侧
      .headersRight {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }
    }
  }

  // 大盒子背景图 登录 hzc=1
  .box {

    // width: 100%;
    // height: 670px;
    // background-image: url("../../assets/img/Login/loginIcon68.png");
    // background-size: 100% 100%;
    // position: relative;
    // margin-bottom: 40px;
    // 小盒子定位
    .boxs {
      // position: absolute;
      // top: 70px;
      // right: 360px;
      // width: 380px;
      // height: 482px;
      background: #ffffff;
      // box-shadow: 0px 0px 16px 0px rgba(222, 224, 228, 0.9);
      border-radius: 10px;

      // 微信登录
      .weiXin {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        // 微信登录左
        .weiXinLeft {
          width: 67px;
          height: 22px;
          background-image: url("../../assets/img/Login/yuanjiao.png");
          background-size: 100% 100%;
          margin: 0px 3px 4px 0px;
          text-align: center;
          line-height: 22px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f15817;
          cursor: pointer;
        }

        // 微信登录右验证码
        .weiXinRight {
          width: 34px;
          height: 34px;
          margin-right: 20px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      // 密码、短信登录选择
      .choice {
        // width: 85%;
        height: auto;
        margin: auto;
        margin-top: 10px;
        display: flex;
        // justify-content: space-between;
        align-items: center;

        // 密码登录
        .choiceLeft {
          width: auto;
          height: 35px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .choiceLeftText {
            width: auto;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666;
            cursor: pointer;
          }

          .class1 {
            color: #ff4027;
            cursor: pointer;
          }

          .choiceLefts {
            margin: auto;
            margin-top: 8px;
            width: 30px;
            height: 3px;
            background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
            border-radius: 2px;
          }
        }

        // 分割线
        .choices {
          width: 1px;
          height: 16px;
          background: #dddddd;
          margin-bottom: 7px;
        }
      }

      // 密码、短信登录选择内容
      .content {
        width: 100%;
        // height: 330px;
        margin-top: 30px;

        .el-form-item {
          margin-bottom: 24px;
        }

        .contentTop {
          // width: 90%;
          // height: 263px;
          margin: auto;

          // 输入框
          .el-input {
            width: 100%;
            height: 46px;
            // margin-bottom: 10px;
          }

          // 单选框
          .contentTops {
            height: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            //   左盒子
            .contentTopsLeft {
              width: 80px;
              height: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              // 勾选自动登录输入框
              input {
                width: 12px;
                height: 100%;
                margin-right: 3px;
              }

              // 自动登录
              p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #a8abbe;
                cursor: pointer;
              }
            }

            //   忘记密码
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a8abbe;
              cursor: pointer;
            }
          }

          // 登录按钮
          .contentTopBtn {
            width: 100%;
            height: 46px;
            margin-top: 16px;
            background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
            // box-shadow: 0px 2px 8px 0px rgba(203, 25, 0, 0.28);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }
        }

        //  底部 没有账号？点我注册
        .contentBtm {
          margin-top: 20px;
          padding-bottom: 40px;
          width: 100%;
          // height: 70px;
          // margin-top: 10px;
          // background: #f8f8f8;
          border-radius: 0px 0px 10px 10px;
          text-align: center;
          // line-height: 70px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7b8196;
          cursor: pointer;

          span {
            color: #ff4027;
          }
        }
      }
    }
  }

  // 大盒子背景图 注册 hzc=2
  .boxb {
    width: 100%;
    height: 670px;
    background-image: url("../../assets/img/Login/loginIcon682.png");
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 40px;

    // 小盒子定位
    .boxbs {
      position: absolute;
      top: 70px;
      right: 360px;
      width: 380px;
      height: 510px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(222, 224, 228, 0.9);
      border-radius: 10px;

      // 缩宽
      .boxbss {
        width: 80%;
        height: 100%;
        margin: auto;

        // 标题
        .boxbsName {
          width: 163px;
          height: 17px;
          margin: 30px auto;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
        }

        // 标题下图片
        .boxbssHd {
          width: 222px;
          height: 35px;
          margin: 0px auto;
          margin-top: 54px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .imga {
            width: 24px;
            height: 35px;
          }

          .imgb {
            width: 124px;
            height: 4px;
          }

          .imgc {
            width: 24px;
            height: 18px;
          }
        }

        .boxbssHdp {
          width: 100%;
          height: 12px;
          margin-top: 40px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a8abbe;

          span {
            color: #333333;
          }
        }

        .el-form {
          .boxbssHdBtn {
            width: 87px;
            height: 40px;
            outline: none;
            background: #eeeeee;
            border: none;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff4027;
            cursor: pointer;
          }
        }

        // 注册按钮
        .boxbsBtn {
          width: 320px;
          height: 46px;
          margin: 22px auto;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
          box-shadow: 0px 2px 8px 0px rgba(203, 25, 0, 0.28);
          border-radius: 23px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }

        // 底部必选已同意
        .boxbsBtm {
          width: 100%;
          height: 13px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          input {
            margin: 0px 6px 0px 21px;
          }

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #7b8196;

            span {
              color: #ff4027;
            }
          }
        }
      }
    }
  }

  // 大盒子背景图 忘记密码 hzc=3
  .boxc {
    width: 100%;
    margin-bottom: 40px;
    height: 740px;
    background: #f3f4f7;
    overflow: hidden;

    // 内容框
    .boxcs {
      width: 1200px;
      height: 630px;
      margin: auto;
      margin-top: 40px;
      background: #ffffff;
      overflow: hidden;
      position: relative;

      // 标题
      .boxcsHead {
        width: 80px;
        height: 18px;
        margin: 30px 0px 0px 30px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;
      }

      // 虚线
      .line {
        width: 960px;
        height: 1px;
        margin: auto;
        margin-top: 84px;
        background: #e3e3e3;
      }

      // 内容
      .boxcsCent {
        width: 430px;
        height: 200px;
        margin: auto;
        margin-top: 122px;

        // 输入框盒子
        .boxcsCents {
          width: 100%;
          height: 46px;
          margin-bottom: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            width: auto;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
          }

          div {
            width: 96px;
            height: 40px;
            display: flex;
            margin-left: 14px;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff7f48;
            cursor: pointer;
          }

          button {
            outline: none;
            border: none;
            width: 96px;
            height: 40px;
            display: flex;
            margin-left: 14px;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff7f48;
            cursor: pointer;
          }
        }

        // 找回密码第四步确认图片盒子
        .zhQueren {
          width: 180px;
          height: 122px;
          margin: auto;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          p {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
          }
        }

        // 按钮
        .boxcsCentss {
          width: 320px;
          height: 46px;
          // background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
          border-radius: 2px;
          margin-left: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }

      // 底部
      .boxcsFoot {
        width: 1140px;
        height: 96px;
        margin: auto;
        margin-top: 61px;
        background: #fff4ef;
        border-radius: 4px;
        overflow: hidden;

        .boxcsFoota {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27323f;
          margin: 20px 0px 10px 20px;
        }

        .boxcsFootb {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7b8196;
          margin: 0px 0px 3px 20px;
        }
      }

      // 定位手机号
      .phone {
        position: absolute;
        top: 213px;
        left: 397px;
        z-index: 999999;
        width: 180px;
        height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;

        p {
          width: auto;
          height: auto;
        }

        span {
          width: auto;
          height: auto;
        }
      }

      // 定位图片1
      .boxcsImga {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        left: 164px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片2
      .boxcsImgb {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        left: 432px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片3
      .boxcsImgc {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        right: 432px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片4
      .boxcsImgd {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        right: 164px;
        z-index: 999999;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .diva {
    width: 96px;
    height: 40px;
    border: 1px solid #dddddd;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 获取动态码
  .divb {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999;
    // background: #ffffff;
    // border: 1px solid #dddddd;
    border-radius: 2px;
    cursor: pointer;
  }

  .divb:hover {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    // background: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    // border: 1px solid #ff4027;
    color: #ff4027;
  }

  // 微信登录
  .weiXinName {
    width: 100%;
    height: 55px;
    text-align: center;
    line-height: 55px;
    font-size: 18px;
    margin-bottom: 17px;
  }

  // 扫描二维码
  .weiXinImg {
    width: 200px;
    height: 200px;
    border-radius: 2px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 二维码下方字体
  .weiXinNames {
    margin: auto;
    margin-top: 20px;
    text-align: center;
    width: 169px;
    height: 32px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b8196;
    line-height: 20px;
  }

  // 底部
  .weiXinFoot {
    margin: auto;
    margin-top: 33px;
    width: 380px;
    height: 70px;
    background: #f8f8f8;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    line-height: 70px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b8196;
    cursor: pointer;

    span {
      color: #ff4027;
    }
  }
}

/deep/.el-input__inner {
  border: none !important;
  background: #f2f2f2 !important;
}

/deep/.el-form-item__content {
  display: flex;
  height: 40px;
}

/deep/.el-form-item__error {
  // margin-left: 5%;
}

/deep/.el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f2f2f2 inset !important;
  background-color: #f2f2f2 !important;
}

.dis_flex_between {
  background: #f2f2f2;
}
</style>
