<template>
  <div>
    <el-dialog :visible.sync="couponTipsVisible" width="500px" :before-close="couponTipsClose">

      <div class="bigBox">
        <div>恭喜您注册成功,送给您新人专属优惠券,快去使用吧！</div>
        <div v-for="item in ouponList" :key="item.id" class="couponItem">
          <div class="leftBox">
            <div class="priceBox">
              <span class="symbol">￥</span>
              <span class="num">
                {{ item.couponValue }}
              </span>
            </div>
          </div>
          <div class="rightBox">
            <div class="itemTitle">{{ item.couponName }}</div>
            <div class="validityPeriod">{{ '有效期至' + item.validEndTime }}</div>
            <div class="forPeople">
              {{ item.forPeople == 3 || item.forPeople == 4 ? forPeople[item.forPeople] + '专享' : '' }}</div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="couponTipsClose">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: ['couponTipsVisible', 'ouponList'],
  props: ['couponTipsVisible'],
  data () {
    return {
      forPeople: [
        '',
        '指定用户',
        '全部用户',
        '新用户',
        '老用户',
        '首单优惠',
        '累计消费优惠券'
      ],
      ouponList: [
        {
          'id': 83,
          'couponName': '新用户优惠券',
          'couponType': 1,
          'fullReductionAmount': null,
          'couponValue': 100.00,
          'totalStock': 11,
          'validStartTime': '2022-01-11 00:00:00',
          'validEndTime': '2022-01-14 00:00:00',
          'applicableGoods': 1,
          'applicableGoodsSet': null,
          'applicableGoodsName': null,
          'applicableGoodsTypeSet': '0',
          'receiverLimit': 1,
          'receiverLimitSet': null,
          'personLimitNumber': 1,
          'shareStatus': 1,
          'hideStatus': 2,
          'tenantId': 62,
          'isDelete': 0,
          'createTime': '2022-01-12 21:47:43',
          'searchTenant': null,
          'couponStatus': 2,
          'newAdd': null,
          'entrantsType': null,
          'useNum': null,
          'receiveNum': null,
          'entrantsName': null,
          'status': null,
          'expireTime': null,
          'couponCodeId': null,
          'forPeople': 3,
          'limitDaysForOlder': 0,
          'fullDiscountRate': 10.00,
          'limitLowAmount': null,
          'receiverLimitName': null
        }
      ]
    }
  },
  methods: {
    couponTipsClose () {
      this.$emit('couponTipsClose')
      sessionStorage.removeItem('loginInfo')
    }
  }
}
</script>

<style lang="less" scoped>
.bigBox {
  height: 500px;
  overflow: auto;
  .couponItem {
    margin-top: 20px;
    margin-bottom: 20px;
    background-image: url("../assets/img/user/Group 396.png");
    width: 340px;
    height: 106px;
    display: flex;
    .leftBox {
      width: 96px;
      height: 106px;
      color: #fff4bb;
      display: flex;
      justify-content: center;
      align-items: center;
      .priceBox {
        .symbol {
          font-size: 14px;
        }
        .num {
          font-size: 32px;
        }
      }
    }
    .rightBox {
      width: 244px;
      height: 106px;
      padding-left: 17px;
      padding-top: 17px;
      .itemTitle {
        color: #ff5e51;
        margin-bottom: 10px;
      }
      .validityPeriod {
        color: #ff5e51;
      }
      .forPeople {
      }
    }
  }
}
</style>
