import request from "@/common/utils/request.js";
import { WebClient } from "@/api/webClient";
let webClient = new WebClient();
export class DataClient extends WebClient {
  /**查询资料包列表 */
  async getPackageList(
    pageSize,
    pageNum,
    kind,
    zyId,
    classifyId,
    userid,
    courseId
  ) {
    let params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kind: kind,
        zyId: zyId,
        classifyId: classifyId,
        userid: userid,
        courseId,
      }
    );
    let data = await request({
      url: `/kecheng/pc/classes/getPackageList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /**查询资料包列表 */
  async getNewPackageList(
    pageSize,
    pageNum,
    kind,
    stage,
    zyId,
    classifyId,
    userid,
    kId
  ) {
    let params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        kind: kind,
        zyId: zyId,
        zStage: stage,
        classifyId: classifyId,
        userid: userid,
        kId,
      }
    );
    let data = await request({
      url: `/kecheng/pc/classes/getNewPackageList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /**查询资料包资料列表 */
  async getPackageDetails(pageSize, pageNum, packageId, userid) {
    let params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
        packageId: packageId,
      },
      {
        userid: userid,
      }
    );
    let data = await request({
      url: `/kecheng/pc/classes/getPackageDetails`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /**查询资料包详情
   *isPurchase 当前用户是否购买资料包0已购买/1未购买
   */
  async getDocPackageDetails(packageId, userid) {
    let data = await request({
      url: `/kecheng/pc/classes/getDocPackageDetails`,
      method: "GET",
      params: {
        id: packageId,
        userid: userid,
      },
    });
    return data;
  }
  /**获取当前学生所有资料包 */
  async getMyDocPackageList(pageSize, pageNum, classifyId, userid, IsPurchase) {
    let params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        userid: userid,
        classifyId: classifyId,
        IsPurchase: IsPurchase,
      }
    );
    let data = await request({
      url: `/kecheng/pc/classes/getMyDocPackageList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /**获取当前学生套餐赠送的资料/资料包 */
  async getMyClassDocPackageList(
    pageSize,
    pageNum,
    classifyId,
    userid,
    isSingle
  ) {
    let params = this.combin(
      {
        pageSize: pageSize,
        pageNum: pageNum,
      },
      {
        userid: userid,
        classifyId: classifyId,
        isSingle: isSingle,
      }
    );
    let data = await request({
      url: `/kecheng/pc/classes/getMyClassDocPackageList`,
      method: "GET",
      params: params,
    });
    return data;
  }
  /**
   * 下载资料时记录
   * @param zlId  资料id
   * @param xmId  项目id
   * @param dlId  大类id
   * @param linkLocation  链接
   * @param name  资料名称
   */
  async downloadRecord(data) {
    let res = await request({
      url: `/kecheng/pc/classes/downloadRecord`,
      method: "POST",
      data,
    });
    return res;
  }
}
