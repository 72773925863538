/*
 * @Description:
 * @Version: 2.0
 * @Author: 姜涛
 * @Date: 2021-11-30 16:31:33
 * @LastEditors: 隐世浮华
 * @LastEditTime: 2023-09-20 10:45:26
 * @FilePath: \edub_tob_pc\src\api\classifi.js
 */
import request from '@/common/utils/request.js'
import { WebClient } from '@/api/webClient'
const webClient = new WebClient()
export class Classifi extends WebClient {
  /** 查询字段扩展详情 */
  async selectFieldExtendDetail(id) {
    const res = await request({
      url: `/kecheng/fieldExtend/selectFieldExtendDetail`,
      method: 'GET',
      params: {
        kindId: id
      }
    })
    return res
  }
  /** 查询字段扩展详情=课程 */
  async selectPKcExtendDetail(id) {
    const res = await request({
      url: `/kecheng/KcExtend/selectPKcExtendDetail`,
      method: 'GET',
      params: {
        kindId: id
      }
    })
    return res
  }
  /** 获取分类列表加载下拉 */
  async getClassSelectSelect(type) {
    const params = this.combin(
      {},
      {
        fType: type
      }
    )
    const res = await request({
      url: `/kecheng/fClass/selectSelect`,
      method: 'GET',
      params: params
    })
    return res
  }
  /** 试卷包专业列表 */
  async getPackageMajor(kind, zStage) {
    const data = await request({
      url: `/ques/questionPackage/getPackageMajor`,
      method: 'GET',
      params: {
        zKind: kind,
        zStage: zStage
      }
    })
    return data
  }
  /** 试卷专业列表 */
  async getPaperMajor(kind, zStage) {
    const data = await request({
      url: `/ques/questionPaper/getPaperMajor`,
      method: 'GET',
      params: {
        zKind: kind,
        zStage: zStage
      }
    })
    return data
  }
  /** 资料包专业列表 */
  async getDocPackageMajor(kind, zStage) {
    const data = await request({
      url: `/kecheng/docpackage/getDocPackageMajor`,
      method: 'GET',
      params: {
        zKind: kind,
        zStage: zStage
      }
    })
    return data
  }
  /* 试卷包课程列表 */
  async getPackageCourse(kind, zId) {
    const params = this.combin({}, { kindId: kind, zId })
    const data = await request({
      url: `/ques/questionPackage/getPackageCourse`,
      method: 'GET',
      params: params
    })
    webClient.functionNo(data)
    return data
  }
  /* 试卷课程列表 */
  async getPaperCourse(kind, zId) {
    const params = this.combin({}, { kindId: kind, ZId: zId })
    const data = await request({
      url: `/ques/questionPaper/getPaperCourse`,
      method: 'GET',
      params: params
    })
    webClient.functionNo(data)
    return data
  }
  /* 资料包课程列表 */
  async getDocPackageCourse(kind, zId) {
    const params = this.combin({}, { kindId: kind, zId: zId })
    const data = await request({
      url: `/kecheng/docpackage/getDocPackageCourse`,
      method: 'GET',
      params: params
    })
    webClient.functionNo(data)
    return data
  }
  /** 试卷包分类 */
  async getPackageFenlei() {
    const res = await request({
      url: `/ques/questionPackage/getPackageFenlei`,
      method: 'GET'
    })
    return res
  }
  /** 试卷分类 */
  async getPaperFenlei() {
    const res = await request({
      url: `/ques/questionPaper/getPaperFenlei`,
      method: 'GET'
    })
    return res
  }
  /** 资料包分类 */
  async getDocPackageFenlei() {
    const res = await request({
      url: `/kecheng/docpackage/getDocPackageFenlei`,
      method: 'GET'
    })
    return res
  }
  /** 查询机构下的精品课程的课程类型 */
  async getCourseListTypes(item) {
    const res = await request({
      url: `/kecheng/courseAll/getCourseListTypes`,
      method: 'GET',
      params:item
    })
    return res
  }
  async selectZhuanyeByKind  (kindId,kindStage) {
    let res = await request({
      url: `/kecheng/zhuanye/selectZhuanyeByKind?kindId=${kindId}&kindStage=${kindStage} `,
      method: "GET",
    });
    return res;
  }
}
