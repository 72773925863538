import request from "@/common/utils/request.js";
import { getInfo, getDomain } from "@/api/cookies";

import { WebClient } from "@/api/webClient";
const webClient = new WebClient();
/** 课程推荐
 *  1知识套餐课程  2直播课程  3录播课程 4公开课程 5资料包' */
function selectCoursePages(type, pageSize, pageNum) {
  return request({
    url: "/ucenter/api/home/selectCoursePages",
    method: "GET",
    params: {
      // 产品类型
      type: type,
      // 一页几条
      pageSize: pageSize,
      // 第几页
      pageNum: pageNum,
    },
  });
}
/** 知识套餐课程 知识套餐列表
 * classType: 4 精品班 1 协议班 2 普通班  5 特价班
 * productLine: 1 普通线 2 精品线  3 专属线 4 普通线加精品线
 */
function selectPageList(
  pageSize,
  pageNum,
  schoolId,
  kind,
  zyId,
  classType,
  productLine
) {
  return request({
    url: "/kecheng/pc/classes/selectPageList",
    method: "GET",
    params: {
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      zyId: zyId,
      classType: classType,
      schoolId: schoolId,
      productLine: productLine || 4,
    },
  });
}
function selectNewPageListToIndex(
  pageSize,
  pageNum,
  schoolId,
  kind,
  zyId,
  classType,
  productLine,
  stage,
  areaId,
  name
) {
  return request({
    url: "/kecheng/pc/classes/selectNewPageListToIndex",
    method: "GET",
    params: {
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      zStage: stage,
      zyId: zyId,
      classType: classType,
      schoolId: schoolId,
      productLine: productLine || 4,
      areaId,
      tiitle: name
    }
  })
}
function selectNewPageListToIndexs(
  pageSize,
  pageNum,
  schoolId,
  kind,
  zyId,
  classType,
  productLine,
  stage,
  areaId,
  name
) {
  return request({
    url: '/kecheng/pc/classes/selectNewPageListToIndex',
    method: 'GET',
    params: {
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      zStage: stage,
      zyId: zyId,
      classType: classType,
      schoolId: schoolId,
      productLine: productLine,
      areaId,
      tiitle: name
    }
  })
}
function selectNewPageListToIndexnew(
  pageSize,
  pageNum,
  schoolId,
  kind,
  zyId,
  classType,
  productLine,
  stage,
  areaId,
  name
) {
  return request({
    url: '/kecheng/pc/classes/selectNewPageListToIndex',
    method: 'GET',
    params: {
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      zStage: stage,
      zyId: zyId,
      classType: classType,
      schoolId: schoolId,
      productLine: productLine,
      areaId,
      tiitle: name
    }
  })
}
async function getImagePage() {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_juxue,
    url: `/kecheng/image-jxu/getImagePage`,
    method: "GET",
  });
  return data;
}
// 资格证列表
async function getOrgCertificateList(params) {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: `/enroll/org/getOrgCertificateList`,
    method: "GET",
    params,
  });
  return data;
}
// 资格证列表
async function getOrgCertificateByName(params) {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: `/enroll/org/getOrgCertificateByName`,
    method: "GET",
    params,
  });
  return data;
}
// 资格证列表菜单
async function getProjectClassLis(params) {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: `/enroll/school/getPcProjectClassList`,
    method: "GET",
    params,
  });
  return data;
}

// 资格证详情
async function getDetails(id) {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: `/enroll/certificate/getDetail?id=${id}`,
    method: "GET",
  });
  return data;
}
// 资格证书大类
async function getProjectClassList(params) {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: `/enroll/school/getProjectClassList`,
    method: "GET",
    params,
  });
  return data;
}
// 资格证书大类
async function getPcList(params) {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: `/enroll/certificate/getPcList`,
    method: "GET",
    params,
  });
  return data;
}
// 广告营销
// async function selectPcConfiguration(params) {
//   const data = await request({
//     baseURL: process.env.VUE_APP_BASE_API,
//     url: `/ucenter/bridalPackage/selectPcConfiguration`,
//     method: "GET",
//     params,
//   });
//   return data;
// }

async function getAccessKey() {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_juxue,
    url: `/neteasy/tspResManage/getAccessKey`,
    method: "POST",
  });
  return data;
}

async function getPcCityEntrantsList(areaProvince, areaCity, areaName) {
  const params = { areaProvince, areaCity, areaName }
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_juxue,
    url: `/ucenter/api/getPcCityEntrantsList`,
    method: 'GET',
    params: params
  })
  return data
}

function selectPageListToIndex(
  pageSize,
  pageNum,
  schoolId,
  kind,
  zyId,
  classType,
  productLine,
  areaId,
  tiitle
) {
  return request({
    url: "/kecheng/pc/classes/selectPageListToIndex",
    method: "GET",
    params: {
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      zyId: zyId,
      classType: classType,
      schoolId: schoolId,
      productLine: productLine || 4,
      areaId,
      tiitle
    }
  })
}
function selectPageListToIndexnew(
  pageSize,
  pageNum,
  schoolId,
  kind,
  zyId,
  classType,
  productLine,
  areaId,
  tiitle
) {
  return request({
    url: "/kecheng/dataCache/selectPageListToIndex",
    method: "GET",
    params: {
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      zyId: zyId,
      classType: classType,
      schoolId: schoolId,
      productLine: productLine || 4,
      areaId,
      tiitle,
      pcDataType:1
    }
  })
}
function selectPageListToIndexnews(
  pageSize,
  pageNum,
  schoolId,
  kind,
  zyId,
  classType,
  productLine,
  areaId,
  tiitle
) {
  return request({
    url: "/kecheng/dataCache/selectPageListToIndex",
    method: "GET",
    params: {
      pageSize: pageSize,
      pageNum: pageNum,
      kind: kind,
      zyId: zyId,
      classType: classType,
      schoolId: schoolId,
      productLine: productLine,
      areaId,
      tiitle,
      pcDataType:1
    }
  })
}
// 广告营销
async function selectPcConfiguration(params) {
  const data = await request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: `/ucenter/bridalPackage/selectPcConfiguration`,
    method: 'GET',
    params
  })
  return data
}
/** 获取知识套餐下推荐知识套餐
 *  1知识套餐课程  2直播课程  3录播课程 4公开课程 5资料包' */
function getClassRelevant(type, id, tenantid) {
  return request({
    url: "/kecheng/pc/classes/getClassRelevant",
    method: "GET",
    params: {
      type: type,
      id: id,
      tenantid: tenantid,
    },
  });
}

// 项目列表
function getTenantidNavigation(collageId) {
  return request({
    /*   url: "/ucenter/api/home/getTenantidNavigation", */
    url: "/kecheng/basics/getKindToKecheng",
    method: "GET",
    params: {
      collageId: collageId,
    },
  });
}
// 课程分类 VIP专属 项目列表
// function getKindToKecheng(collageId) {
//   return request({
//     /*   url: "/ucenter/api/home/getTenantidNavigation", */
//     url: "/kecheng/basics/getKindToKecheng ",
//     method: "GET",
//     params: {
//       collageId: collageId,
//     },
//   });
// }
// questionPackage / getQuestionPackageList;
// function getTenantidNavigation(pageSize,pageNum,kindId) {
//   return request({
//     /*   url: "/ucenter/api/home/getTenantidNavigation", */
//     url: "questionPackage/getQuestionPackageList",
//     method: "GET",
//     params: {
//       pageSize: 9,
//       pageNum: 1,
//       kindId: 3,
//     },
//   });
// }
// 课程分类 VIP专属 项目列表
function getKindToKecheng(collageId) {
  return request({
    /*   url: "/ucenter/api/home/getTenantidNavigation", */
    url: "/kecheng/basics/getKindToKecheng ",
    method: "GET",
    params: {
      collageId: collageId,
    },
  });
}
// 学习资料项目列表
function getKindToZiliao(collageId) {
  return request({
    /*   url: "/ucenter/api/home/getTenantidNavigation", */
    url: "/kecheng/basics/getKindToZiliao ",
    method: "GET",
    params: {
      collageId: collageId,
    },
  });
}
// 题库项目列表
function getKindToQuestion(collageId) {
  return request({
    /*   url: "/ucenter/api/home/getTenantidNavigation", */
    url: "/kecheng/basics/getKindToQuestion ",
    method: "GET",
    params: {
      collageId: collageId,
    },
  });
}
// 项目列表
function getKindToPublic(collageId) {
  return request({
    /*   url: "/ucenter/api/home/getTenantidNavigation", */
    url: "/kecheng/basics/getKindToPublic",
    method: "GET",
    params: {
      collageId: collageId,
    },
  });
}
// 项目列表
function selectZyList(collageId, pkind) {
  return request({
    /*   url: "/ucenter/api/home/getTenantidNavigation", */
    url: "/kecheng/basics/getZhuanye",
    method: "GET",
    params: {
      parentid: collageId,
      pkind: pkind,
    },
  });
}
// 获取学院列表信息
function getMYTenantKindZyList(productLine) {
  return request({
    url: `/kecheng/basics/getMYTenantKindZyList`,
    method: "GET",
    params: {
      productLine: productLine,
    },
  });
}
// 获取学院列表信息
function getMYTenantKindZyListnew(productLine) {
  return request({
    url: `/kecheng/dataCache/getMYTenantKindZyList`,
    method: "GET",
    params: {
      productLine: productLine,
      pcDataType:1
    },
  });
}

// 项目数据 （知识套餐 首页）
function selectKindTenantIdProducts() {
  return request({
    url: `/kecheng/KindData/selectKindTenantIdProducts`,
    method: 'GET',
   
  })
}
// 项目数据 （直播课
function getLiveshowKindList() {
  return request({
    url: `/kecheng/KindData/getLiveshowKindList`,
    method: 'GET',
   
  })
}
// 项目数据 （资料
function getTenantDocPackageZyList() {
  return request({
    url: `/kecheng/KindData/getTenantDocPackageZyList`,
    method: 'GET',
   
  })
}
// 项目数据 （公开课
function getNewPublicKindList() {
  return request({
    url: `/kecheng/KindData/getNewPublicKindList`,
    method: 'GET',
   
  })
}
// 项目数据 （教辅商城
function getSelfTextbookKindList() {
  return request({
    url: `/kecheng/KindData/getSelfTextbookKindList`,
    method: 'GET',
   
  })
}
// 项目数据 （开启的项目数据
function selectKindTenantId() {
  return request({
    url: `/kecheng/KindData/selectKindTenantId`,
    method: 'GET',
   
  })
}

function selectKindAllCollage(productLine) {
 
  return request({
    url: `/kecheng/basics/getTenantKindZyList`,
    method: "GET",
    params: {
      productLine: productLine,
    },
  });
}
// 
function selectKindIdList() {
  return request({
    url: `/kecheng/researchTextbook/selectKindIdList`,
    method: 'GET',
  })
}

function selectZyIdList(params) {
  return request({
    url: `/kecheng/researchTextbook/selectZyIdList`,
    method: 'GET',
    params
  })
}

// 获取干货资料轮播图数据
function getSelectPcConfiguration() {
  return request({
    url: `/ucenter/bridalPackage/selectPcConfiguration`,
    method: 'GET'
  })
}

// 获取学院列表信息(新 2022.10.13)
function getTenantKindNoZyList(productLine) {
  return request({
    url: `/kecheng/basics/getTenantKindNoZyList`,
    method: "GET",
    params: {
      productLine: productLine,
    },
  });
}
// 首页机构下的班型类型列表
function selectPageListToIndexType(item) {
  return request({
    url: `/kecheng/pc/classes/selectPageListToIndexType`,
    method: "GET",
    params: item
  });
}
// 查询机构下的精品课程的课程类型
function getCourseListTypes(item) {
  return request({
    url: `/kecheng/courseAll/getCourseListTypes`,
    method: "GET",
    params: item
  });
}
/**
 * 套餐查询省份和专业
 * @param productLine 产品线（必须）（1 普通线 2是精品线 3是专属线 4是普通线加精品线）
 * @param schoolId 第一级大类（必须）
 * @param kind 项目id（必须）
 * @param stage 阶段（非必须）
 * @returns
 */
function getTenantKcProvinceList(params) {
  return request({
    url: `/kecheng/basics/getTenantKcProvinceList`,
    method: "GET",
    params,
  });
}
/**
 * 班型对比查询省份
 * @param kindId 项目id（必须）
 * @returns
 */
function selectOptionList(params) {
  return request({
    url: `/kecheng/classContrast/selectOptionList`,
    method: "GET",
    params,
  });
}
/**
 * 班型对比查询省份下专业
 * @param kindId 项目id（必须）
 * @param areaId 省份id（必须）
 * @returns
 */
function selectOptionZyList(params) {
  return request({
    url: `/kecheng/classContrast/selectOptionZyList`,
    method: "GET",
    params,
  });
}
/**
 * 查询直播课省份专业显示
 * @param schoolId 第一级大类（必须）
 * @param kind 项目id（必须）
 * @param stage 阶段（非必须）
 * @returns
 */
function getTenantLiveShowProvinceList(params) {
  return request({
    url: `/kecheng/basics/getTenantLiveShowProvinceList`,
    method: "GET",
    params,
  });
}
/**
 * 套餐查询省份和专业
 * @param schoolId 第一级大类（必须）
 * @param kind 项目id（必须）
 * @param stage 阶段（非必须）
 * @returns
 */
function getTenantBoutiqueProvinceList(params) {
  return request({
    url: `/kecheng/basics/getTenantBoutiqueProvinceList`,
    method: "GET",
    params,
  });
}
// 获取学院列表信息精品课程页面
function getTenantLearnMaterialsZyList() {
  return request({
    url: `/kecheng/basics/getTenantLearnMaterialsZyList`,
    method: "GET",
  });
}
// 获取学院列表信息学习资料页面
function getTenantBoutiqueZyList() {
  return request({
    url: `/kecheng/basics/getTenantBoutiqueZyList`,
    method: "GET",
  });
}
// 获取学院列表信息直播课页面
function getTenantZBZyList() {
  return request({
    url: `/kecheng/basics/getTenantZBZyList`,
    method: "GET",
  });
}
// 获取学院列表信息题库试卷包页面
function getTenantQuestionPackageZyList() {
  return request({
    url: `/kecheng/basics/getTenantQuestionPackageZyList`,
    method: "GET",
  });
}
// 获取学院列表信息题库试卷页面
function getTenantQuestionPaperZyList() {
  return request({
    url: `/kecheng/basics/getTenantQuestionPaperZyList`,
    method: "GET",
  });
}
// 首页轮播左侧专业详情
function getZyIdByCurriculumList(as) {
  return request({
    url: "/ucenter/api/home/getZyIdByCurriculumList",
    method: "GET",
    params: {
      // 专业id
      zyId: as,
    },
  });
}

// 公开课详情
function getDetail(as) {
  return request({
    url: "/ucenter/api/home/getDetail",
    method: "GET",
    params: {
      // 公开课Id
      id: as,
      userid: localStorage.getItem("userId"),
    },
  });
}

// 知识套餐详情
async function getClass(as) {
  return request({
    url: "/ucenter/api/home/getClass",
    method: "GET",
    params: {
      // 知识套餐Id
      id: as,
      userid: localStorage.getItem("userId"),
    },
  });
}
// 知识套餐详情
async function getClassss(as) {
  return request({
    url: "/kecheng/classes/getClass",
    method: "GET",
    params: {
      // 知识套餐Id
      id: as,
      userid: localStorage.getItem("userId"),
    },
  });
}
// 小班课随堂资料
async function selectClassCourseMaterialList(item) {
  return request({
    url: '/kecheng/privateClassCourseMaterial/selectClassCourseMaterialList',
    method: 'GET',
    params: { userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined, ...item }

  })
}
// 小班课随堂资料
async function selectPrivateClassSectionList(item) {
  return request({
    url: '/kecheng/privateClassCourseMaterial/selectPrivateClassSectionList',
    method: 'GET',
    params: { userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined, ...item }

  })
}
// 1v1班级详情
async function getStuPcDetails(as) {
  return request({
    url: "/kecheng/coachingProcess/getStuPcDetails",
    method: "GET",
    params: {
      id: as,
      userId: localStorage.getItem("userId"),
    },
  });
}
// 学友课表章节详情
async function getPcPlaybackId(as) {
  return request({
    url: "/kecheng/coachingProcess/getPcPlaybackId",
    method: "GET",
    params: {
      playbackId: as,
      userId: localStorage.getItem("userId"),
    },
  });
}
// 老师列表
async function selectPcTerList(as) {
  return request({
    url: "/kecheng/coachingProcess/selectPcTerList",
    method: "GET",
    params: {
      classesId: as,
      userId: localStorage.getItem("userId"),
    },
  });
}
// 辅导流程
async function selectProcessPcList() {
  return request({
    url: "/kecheng/coachingProcess/selectProcessPcList",
    method: "GET",
    params: {
      status: "1",
    },
  });
}
// 学友课表
async function selectPlaybackPcList(data) {
  return request({
    url: "/kecheng/coachingProcess/selectPlaybackPcList",
    method: "GET",
    params: data,
  });
}
// 学友课表
/* VIP专属班详情 */
async function getClassDetailsVip(as) {
  return request({
    url: "/kecheng/classes/getClassDetailsVip",
    method: "GET",
    params: {
      id: as,
      userid: localStorage.getItem("userId"),
    },
  });
}
/* VIP专属教材 */
async function getClassTeaching(as) {
  return request({
    url: "/kecheng/classes/getClassTeaching",
    method: "GET",
    params: {
      id: as,
    },
  });
}
/**
 * 选择方向 VIP专属教材
 * @param id 班型id
 * @param coursePackageId 课程包id
 * @returns
 */
async function getClassCourseTeaching(params) {
  return request({
    url: "/kecheng/classes/getClassCourseTeaching",
    method: "GET",
    params,
  });
}

/* VIP专属教材详情 */
async function getCurSelfResearchTextbook(as) {
  return request({
    url: "/kecheng/classes/getCurSelfResearchTextbook",
    method: "GET",
    params: {
      id: as,
    },
  });
}

// 课程推荐详情 直播课/录播课
function getClassCourse(bs, cs) {
  return request({
    url: "/ucenter/api/home/getClassCourse",
    method: "GET",
    params: {
      // 知识套餐Id
      id: bs,
      // 1直播/2录播
      type: cs,
    },
  });
}

/**
 * VIP班型详情 学生已购买的课程包id
 * @param id  班型id
 * @param userid  用户Id
 * @returns
 */
function getClassCoursePackageVip(params) {
  return request({
    url: "/kecheng/classes/getClassCoursePackageVip",
    method: "GET",
    params,
  });
}

// 姜涛 2022.10.14 单科详情 购买状态   班型进入单科详情 购买状态  区分出来
// 直播课程详情信息
function getLbClassCourseById(liveid, classId, coursePackageId) {
  return request({
    url: "/ucenter/api/home/getLbClassCourseByIdS",
    method: "GET",
    params: {
      // 直播课id
      liveid: liveid,
      classId: classId || null,
      coursePackageId: coursePackageId || null,
      userid: localStorage.getItem("userId"),
    },
  });
}
/** 直播课节信息 */
function getLbClassCourseListById(Id) {
  return request({
    url: "/ucenter/api/home/getLbClassCourseListById",
    method: "GET",
    params: {
      // 录播Id
      playid: Id,
    },
  });
}
/** 直播课节信息 */
function selectSupplyToStudentStudyRate(item) {
  return request({
    url: "/kecheng/studentStudy/selectSupplyToStudentStudyRate",
    method: "GET",
    params: item,
  });
}
// 录播课程详情信息
function getClassCourseById(videoid, classId, coursePackageId) {
  const params = webClient.combin(
    {
      videoid: videoid,
    },
    {
      classId: classId,
      coursePackageId: coursePackageId,
      userid: localStorage.getItem("userId"),
    }
  );
  return request({
    url: "/ucenter/api/home/getClassCourseByIdS",
    method: "GET",
    params: params,
  });
}
/** 录播课下节信息
 * type:1直播/2录播*/
function getClassCourseByVideoId(Id) {
  return request({
    url: "/ucenter/api/home/getClassCourseByVideoId",
    method: "GET",
    params: {
      // 录播Id
      videoid: Id,
    },
  });
}
/* 视频打点
  parentId 章节ID
  videoType   liveCourses 直播 videoCourses 录播 点播 audio 音频
*/
function getvideoSegmentList(params) {
  return request({
    url: "/kecheng/curVideoSegment/getvideoSegmentList",
    method: "GET",
    params,
  });
}

/**
 * 知识点点列表
 * @param type  1直播视频i打点  2录播视频i打点  3试题   4资料
 * @param typeId  打点视频id   试题id  资料id
 * @returns
 */
function selectPcKnowledgeDataList(params) {
  return request({
    url: "/kecheng/knowledgeProduct/selectPcKnowledgeDataList",
    method: "GET",
    params,
  });
}
/**
 * 查询学生是否购买
 * @param courseId  课程 id  courseId
 * @param type  1直播 2录播
 * @returns
 */
function selectDataIsBuyList(params) {
  return request({
    url: "/kecheng/knowledgeProduct/selectDataIsBuyList",
    method: "POST",
    params,
  });
}
/**
 * 查询学生是否购买资料
 * @param docId 资料id   mid
 * @returns
 */
function selectDocIsBuyList(params) {
  return request({
    url: "/kecheng/knowledgeProduct/selectDocIsBuyList",
    method: "POST",
    params,
  });
}

// 支付宝支付接口
function testPay(outTradeNo, subject, body, isInvoice, couponId) {
  return request({
    url: "/stu/zfbPay/PcZfbPay",
    method: "POST",
    dataType: "json",
    params: {
      outTradeNo: outTradeNo,
      subject: body,
      body: body,
      isInvoice: isInvoice,
      couponId: couponId,
    },
  });
}
// 微信支付接口
function pcwxtestPay(outTradeNo, body, isInvoice, couponId) {
  return request({
    url: "/stu/wxPay/pcwxtestPay",
    method: "POST",
    dataType: "json",
    params: {
      outTradeNo: outTradeNo,
      body: body,
      isInvoice: isInvoice,
      couponId: couponId,
    },
  });
}

// 新闻资讯 获取页面行业咨询
function queryAllArticle(kind, pageSize, pageNum, classifyId) {
  const copyright = getDomain();
  return request({
    url: "/stu/front/api/home/selectInformationList",
    method: "GET",
    params: {
      // 类型Id
      kind: kind,
      // 一页几条
      pageSize: pageSize,
      // 第几页
      pageNum: pageNum,
      entrantsType: copyright.entrantsType,
      classifyId,
    },
  });
}

// 在线报名提交
function addEnrollUserConsultation(data) {
  return request({
    url: "/enroll/userConsultation/addEnrollUserConsultation",
    method: "POST",
    data,
  });
}

/**
 * pc M 后台资讯分类列表
 * @param {*} isPass 1 显示
 * @returns
 */
function selectClassifyList(isPass) {
  return request({
    url: "/stu/informationClassify/selectClassifyList",
    method: "GET",
    params: {
      isPass,
    },
  });
}

// 新闻资讯详情
function queryAllArticleid(as) {
  return request({
    url: "/stu/front/api/home/selectInformationById",
    method: "GET",
    params: {
      // 传入id
      id: as,
    },
  });
}

// 根据域名获取机构id
function getDnsOneApi() {
  return request({
    url: "/ucenter/business/getDnsOneApi",
    method: "GET",
    params: {
      domain: location.host,
    },
  });
}

function getStore(entrants_type) {
  return request({
    url: "/ucenter/business/getStore",
    method: "GET",
    params: {
      status: 0,
      entrantsType: entrants_type,
    },
  });
}

// 查询所有学院信息分类
function selectAcademyKindzyList() {
  return request({
    url: "kecheng/basics/getTenantKindZyList",
    method: "GET",
    params: {},
  });
}
// 查询所有学院信息分类
function selectAcademyKindzyListnew() {
  return request({
    url: "/kecheng/dataCache/getTenantKindZyList",
    method: "GET",
    params: {
      pcDataType:1
    },
  });
}
// 搜索
function getCurriculumsList(pageSize, pageNum, tenantid, title) {
  return request({
    url: "/ucenter/api/home/getCurriculumsList",
    method: "POST",
    data: {
      // 传入id
      pageSize: pageSize,
      pageNum: pageNum,
      tenantid: tenantid,
      title: title,
    },
  });
}
/** 轮播图
 * type  1 PC端 / 2 M端 / 3 M课程图片 / 4 PC知识套餐图片/5讲师/6 PC资讯图片
 * entrantsType  入驻商类型  1 同行转型、2 招商加盟 3 高校合伙人
 */
function getSurfaceList(type, entrantsId, entrantsType) {
  const data = request({
    url: `/ucenter/surface/getSurfaceList`,
    method: "GET",
    params: {
      type: type,
      entrantsId: entrantsId || entrantsId === 0 ? entrantsId : null,
      showStatus: 1,
      entrantsType,
    },
  });
  return data;
}
/** 营销图片pc端 */
function getMarketingImgJxuIndex(item) {
  const data = request({
    url: `/kecheng/marketingImg/getMarketingImgJxuIndex`,
    method: 'GET',
    params: item
  })
  return data
}
/* 注册网易云信 */
function neteasyRegist(params) {
  const data = request({
    url: `/neteasy/student/regist`,
    method: "POST",
    params,
  });
  return data;
}
/* 群组拉人入群 */
function userJoinTeam(params) {
  const data = request({
    url: `/neteasy/group/userJoinTeam`,
    method: "POST",
    params,
  });
  return data;
}
/* -----------------------------------------------------------推广图文页----------------- */
/* 查询图文页详情 */
function getMultyDetailById(params) {
  const data = request({
    url: `/market/marketingPrompMulty/getMultyDetailForpage`,
    method: "POST",
    params,
  });
  return data;
}
/* 推广图文页表单详情 */
function getFormById(params) {
  const data = request({
    url: `/market/marketPromptForm/getFormDetailForPage`,
    method: "POST",
    params,
  });
  return data;
}
/* 保存填写的表单记录信息 */
function saveInputFormVal(data) {
  const res = request({
    url: `/market/marketPromptForm/saveInputFormVal`,
    method: "POST",
    data,
  });
  return res;
}
/* 分享查看的时候，获取信息 */
function getMultyDetailForpage(params) {
  const data = request({
    url: `/market/marketingPrompMulty/getMultyDetailForpage`,
    method: "POST",
    params,
  });
  return data;
}
/* 分享查看的时候，获取信息 */
function timesClosePage(id) {
  const data = request({
    url: `/market/requestData/timesClosePage`,
    method: "POST",
    params: {
      accessId: id,
    },
  });
  return data;
}
/**
 * 机构ID查M端域名
 * @param tenantid 机构id
 */
async function getDnsOneByTenantId(tenantid) {
  const res = await request({
    url: `/ucenter/entrantsdns/getDnsOneByTenantId?tenantid=${tenantid}`,
    method: "GET",
  });
  return res.data;
}
/* -------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------学习卡----------------- */
/* 学习卡对应的知识套餐列表 */
function getActStudyProductList(params) {
  const data = request({
    url: `/market/studycard/getActStudyProductList`,
    method: "POST",
    params,
  });
  return data;
}
/* 学习卡激活 */
function activeStudyCardt(params) {
  const data = request({
    url: `/market/studycard/activeStudyCard`,
    method: "POST",
    params,
  });
  return data;
}
/* 验证当前用户是否有当前学习卡添加的课程 */
function checkStudyCode(params) {
  const data = request({
    url: `/market/studycard/checkStudyCode`,
    method: "POST",
    params,
  });
  return data;
}
/* ----------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------优惠券------------------------- */
/* 新用户注册查询赠送的优惠券 */
function getNewUserSendComponList(params) {
  const data = request({
    url: `/market/discountCoupon/getNewUserSendComponList`,
    method: "POST",
    params,
  });
  return data;
}
/* ---------------------------------------------------------------------------------------------我的提问------------------------- */
/*
  我的提问列表
  tenantid
  userid
  mobile
  nickname
  pageNum
  pageSize
*/
function selectStuPcQuestionsList(params) {
  const data = request({
    url: `/kecheng/questions/selectStuPcQuestionsList`,
    method: "GET",
    params,
  });
  return data;
}

/**
 * 获取最新安卓包
 * @param appType 聚学U 聚学堂
 */
function getAndroidDownloadPackage(params) {
  const data = request({
    url: `ucenter/index/getAndroidDownloadPackage`,
    method: "GET",
    params,
  });
  return data;
}
/* ===============================项目频道页=========================== */
/**
 * 项目详情
 * @param pKind
 * @returns
 */
function selectChannel(params) {
  const data = request({
    url: `/stu/channe/selectChannel`,
    method: "GET",
    params,
  });
  return data;
}
/**
 * 项目报考信息列表
 * @param kind  项目id
 * @returns
 */
function selectPcList(params) {
  const data = request({
    url: `/stu/channeMenu/selectPcList`,
    method: "GET",
    params,
  });
  return data;
}
/**
 * 项目轮播图
 * @param kindId  项目id
 * @returns
 */
function selectImgList(params) {
  const data = request({
    url: `/stu/channelImg/selectImgList`,
    method: "GET",
    params,
  });
  return data;
}
/**
 * pc端项目频道页班型对比
 * @param pageSize  项目id
 * @param pageNum  项目id
 * @param kindId  项目id
 * @param areaId  项目id
 * @param zyId  项目id
 * @returns
 */
function selectClassContrastVoPc(params) {
  const data = request({
    url: `/kecheng/classContrast/selectClassContrastVoPc`,
    method: "GET",
    params,
  });
  return data;
}
/** 用户聊天注册*/
function register(params) {
  const data = request({
    baseURL: process.env.VUE_APP_IM_API,
    url: `/im-user/user/register`,
    method: "POST",
    data: params,
  });
  return data;
}
/** 加入群聊*/
function joinGroup(params, toke) {
  const data = request({
    baseURL: process.env.VUE_APP_IM_API,
    url: `/im-user/user/joinGroup`,
    method: "POST",
    params: {
      groupId: params.groupId,
    },
    data: params.list,
    headers: {
      accessToken: toke,
    },
  });
  return data;
}
/** 查询群聊消息*/
function queryGroupMsg(params, toke) {
  const data = request({
    baseURL: process.env.VUE_APP_IM_API,
    url: `/im-chat/chat/queryGroupMsg`,
    method: "POST",
    params: {
      groupId: params.groupId,
      pageNum: params.pageNum,
      pageSize: params.pageSize,
    },

    headers: {
      accessToken: toke,
    },
  });
  return data;
}
/** 发送群聊消息*/
function sendGroupMsg(params, toke) {
  const data = request({
    baseURL: process.env.VUE_APP_IM_API,
    url: `/im-chat/chat/sendGroupMsg`,
    method: "POST",
    data: {
      groupId: params.groupId,
      msg: params.msg,
    },
    headers: {
      accessToken: toke,
    },
  });
  return data;
}
/** 专业， 下的省份列表
 * @param zyId // 专业ID
 */
function selectProvinceVoList(params) {
  const res = request({
    url: `/kecheng/provinceCollegesKeCheng/selectProvinceVoList`,
    method: "GET",
    params,
  });
  return res;
}
/** seo权重关键词

 */
function selectEntrantsSeoPcList() {
  const res = request({
    url: `/kecheng/seo/selectEntrantsSeoPcList`,
    method: "GET",
  });
  return res;
}
/** 加入群聊*/
function joinGroups(params) {
  const res = request({
    url: `/kecheng/liveShowGroup/joinGroup`,
    method: 'POST',
    data: params
  })
  return res
}
/** 查询群聊消息*/
function getGroupChatLog(params) {
  const res = request({
    url: `/kecheng/liveShowGroup/getGroupChatLog`,
    method: 'POST',
    params: params
  })
  return res
}
/** 发送群聊消息*/
function sendMessage(params) {
  const res = request({
    url: `/kecheng/liveShowGroup/sendMessage`,
    method: 'POST',
    data: params
  })
  return res
}
/** 资讯新闻详情*/
function getInformation(params) {
  const res = request({
    url: `/enroll/enrollInform/getInformation`,
    method: 'GET',
    params: params
  })
  return res
}
/** 资讯新闻详情PC端列表*/
function selectInformationPcList(params) {
  const res = request({
    url: `/enroll/enrollInform/selectInformationPcList`,
    method: 'GET',
    params: params
  })
  return res
}
export {
  joinGroups,
  getGroupChatLog,
  selectInformationPcList,
  getInformation,
  sendMessage,
  selectChannel,
  selectPcList,
  selectImgList,
  getAndroidDownloadPackage,
  selectStuPcQuestionsList,
  getvideoSegmentList,
  timesClosePage,
  getNewUserSendComponList,
  getMultyDetailForpage,
  checkStudyCode,
  activeStudyCardt,
  getActStudyProductList,
  saveInputFormVal,
  getMultyDetailById,
  getFormById,
  getSurfaceList,
  getTenantidNavigation,
  getZyIdByCurriculumList,
  getDnsOneApi,
  getStore,
  selectCoursePages,
  getDetails,
  queryAllArticle,
  queryAllArticleid,
  getClass,
  getStuPcDetails,
  getPcPlaybackId,
  selectPcTerList,
  selectPcConfiguration,
  getClassCourse,
  testPay,
  getClassCourseById,
  getClassCourseByVideoId,
  getLbClassCourseById,
  getCurriculumsList,
  getImagePage,
  getClassRelevant,
  selectPageList,
  pcwxtestPay,
  getLbClassCourseListById,
  selectKindAllCollage,
  selectKindIdList,
  selectZyIdList,
  getMYTenantKindZyList,
  selectAcademyKindzyList,
  selectAcademyKindzyListnew,
  selectZyList,
  neteasyRegist,
  userJoinTeam,
  getClassDetailsVip,
  selectProcessPcList,
  selectPlaybackPcList,
  getClassTeaching,
  getCurSelfResearchTextbook,
  register,
  joinGroup,
  queryGroupMsg,
  sendGroupMsg,
  getKindToPublic,
  getProjectClassList,
  // selectPcConfiguration,
  selectPageListToIndex,
  selectPageListToIndexnew,
  selectPageListToIndexnews,
  selectNewPageListToIndex,
  getKindToKecheng,
  getKindToZiliao,
  getKindToQuestion,
  selectProvinceVoList,
  getTenantBoutiqueZyList,
  getTenantLearnMaterialsZyList,
  getTenantZBZyList,
  getAccessKey,
  getPcCityEntrantsList,
  getTenantQuestionPackageZyList,
  getTenantQuestionPaperZyList,
  selectPcKnowledgeDataList,
  selectDataIsBuyList,
  selectDocIsBuyList,
  selectEntrantsSeoPcList,
  getTenantKindNoZyList,
  selectPageListToIndexType,
  getTenantKcProvinceList,
  selectOptionList,
  getDetail,
  selectOptionZyList,
  getOrgCertificateByName,
  getClassCourseTeaching,
  selectClassifyList,
  getOrgCertificateList,
  getTenantLiveShowProvinceList,
  getTenantBoutiqueProvinceList,
  selectClassContrastVoPc,
  getClassCoursePackageVip,
  selectSupplyToStudentStudyRate,
  getSelectPcConfiguration,
  selectKindTenantIdProducts,
  getLiveshowKindList,
  selectClassCourseMaterialList,
  selectPrivateClassSectionList,
  getMarketingImgJxuIndex,
  getProjectClassLis,
  addEnrollUserConsultation,
  selectKindTenantId,
  getSelfTextbookKindList,
  getNewPublicKindList,
  getTenantDocPackageZyList,
  selectNewPageListToIndexs,
  getCourseListTypes,
  getPcList,
  getMYTenantKindZyListnew,
  getClassss
}
