<!--
 * @Author: 南桥几许
 * @Date: 2021-06-30 10:53:19
 * @LastEditTime: 2023-09-20 09:11:19
 * @LastEditors: 隐世浮华
 * @Description:
 * @FilePath: \edub_tob_pc\src\layout\layout.vue
 *
-->
<template>
  <div class="app" :class="`app-box${copyright.entrantsType}`">
    <navigationnew v-if="copyright.entrantsType == 1"></navigationnew>
    <navigation-seventh v-else-if="copyright.entrantsType == 3" />
    <navigationPartnerold v-else-if="copyright.entrantsType == 5 && htmlVal[0].id == 5002" />
    <navigationPartner v-else-if="copyright.entrantsType == 5 && htmlVal[0].id == 5001" />
    <navigation v-else></navigation>
    <router-view v-if="ifNew" class="router-box" :key="$route.fullPath" />
    <footer class="footer">
      <footersixth v-if="copyright.entrantsType == 1"></footersixth>
      <footers-seventh v-else-if="copyright.entrantsType == 3" />
      <FootersPartner v-else-if="copyright.entrantsType == 5" />
      <footers v-else></footers>
    </footer>
    <div v-if="guanggao.length > 0 && $route.name === '首页'" class="mask"
      :class="{ clicked4: isClicked3 || !guangcenter.bol }">
      <!-- <div v-if="guangtop.bol" class="img-box img-box1" :class="{ clicked: isClicked }">
            <img :src="guangtop.img" alt="">
            <img src="../assets/img/icon_quxiao.png" alt="" class="cancle-icon" :class="{ clicked: isClicked }"
              @click="addClickedClass">
          </div> -->
      <a :href="guangbottom.link">
        <div v-if="guangbottom.bol" class="img-box img-box2" :class="{ clicked2: isClicked2 }">
          <img :src="guangbottom.img" alt="">
          <img src="../assets/img/icon_quxiao.png" alt="" class="cancle-icon" :class="{ clicked2: isClicked2 }"
            @click="addClickedClass2">
        </div>
      </a>
      <a :href="guangcenter.link">
        <div v-if="guangcenter.bol" class="img-box img-box3" :class="{ clicked3: isClicked3 }">
          <img :src="guangcenter.img" alt="">
          <img src="../assets/img/icon_quxiao.png" alt="" class="cancle-icon" :class="{ clicked3: isClicked3 }"
            @click="addClickedClass3">
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { getDnsOneApi, selectPcConfiguration, getStore } from "@/api/home";
import { setDomain, setDomainToken, setDomainLogo, setDomainInfo } from "@/api/cookies";

import "@/assets/css/common.css";
import navigation from "@/components/navigation";
import navigationnew from "@/components/navigationnew";
import footers from "@/components/footers";
import footersixth from "@/components/fooeresixth";
import { getDomain } from "@/api/cookies";
import NavigationSeventh from "../components/navigationSeventh.vue";
import navigationPartnerold from "../components/navigationPartnerold.vue";
import navigationPartner from "../components/navigationPartner.vue";
import FootersPartner from "../components/footersPartner.vue";
import FootersSeventh from "../components/footersSeventhNew.vue";
import isThisQuarter from "date-fns/isThisQuarter/index.js";
export default {
  components: {
    navigation,
    footers,
    navigationnew,
    footersixth,
    NavigationSeventh,
    navigationPartner,
    FootersPartner,
    FootersSeventh,
    navigationPartnerold
  },
  data() {
    return {
      university: '',
      getStore: '',
      htmlVal: [],
      copyright:
        {} /* { name: "同行转型", key: 1 },    { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */,
      ifNew: false,
      show: true,
      shows: true,
      // isClicked: false,
      isClicked2: false,
      isClicked3: false,
      guanggao: [],
      guangtop: {},
      guangcenter: {},
      guangbottom: {}
    }
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理

    $route: function (newVal, oldVal) {

      if (newVal.path.includes('agent') && !newVal.path.includes('consulting')) {
        this.show = false
        this.shows = false
      } else if (newVal.path.includes('consulting')) {
        this.show = false
        this.shows = true
      } else {
        this.shows = true
        this.show = true
      }
    }
  },
  async created() {
    this.copyright = getDomain()
    if (this.copyright.eId == process.env.VUE_APP_university) {
      this.university = 1
    }
    if (this.$route.path.includes('agent') && !this.$route.path.includes('consulting')) {
      this.show = false
      this.shows = false
    } else if (this.$route.path.includes('consulting')) {
      this.show = false
      this.shows = true
    } else {
      this.shows = true
      this.show = true
    }
    if (!this.copyright) {
      await getDnsOneApi().then((res) => {
        if (res.code == 200) {
          setDomainToken(res.msg.domainToken)
          setDomainLogo(res.msg.entrants.logo)
          setDomain(res.msg.entrants)
          localStorage.setItem('isXiaoQingWang', res.msg.xiaoQingId)
          setDomainInfo(res.msg.entrants.copyright)
        }
      })
      this.copyright = getDomain()
      if (this.copyright.entrantsType == 5) {
        getStore(this.copyright.entrantsType).then((res) => {
          if (res.code == 0) {
            this.htmlVal = JSON.parse(res.data.json)
            console.log(this.htmlVal, 'this.htmlVal');
          }
        })
      }
    } else {
      if (this.copyright.entrantsType == 5) {
        getStore(this.copyright.entrantsType).then((res) => {
          if (res.code == 0) {
            this.htmlVal = JSON.parse(res.data.json)
            console.log(this.htmlVal, 'this.htmlVal');
          }
        })
      }
    }


    this.selectPcConfiguration()
    this.$nextTick(() => {
      this.ifNew = true
    })
  },

  methods: {
    // 获取营销广告图片
    async selectPcConfiguration() {

      if (this.copyright.entrantsType == 3) {
        selectPcConfiguration().then((res) => {

          res.popupAdshow.forEach((e) => {
            if (e.type == 0 && e.status == 1) {
              this.guangcenter.bol = true
              this.guangcenter.img = e.img
              this.guangcenter.link = e.link
            }
            // if (e.type == 1 && e.status == 1) {
            //   this.guangtop.bol = true
            //   this.guangtop.img = e.img
            //   this.guangtop.link = e.link
            // }
            if (e.type == 2 && e.status == 1) {
              this.guangbottom.bol = true
              this.guangbottom.img = e.img
              this.guangbottom.link = e.link
            }
          })
          this.guanggao = res.popupAdshow

        })
      }
    },
    // addClickedClass () {
    //   this.isClicked = true
    // },
    addClickedClass2(event) {
      this.isClicked2 = true
      event.stopPropagation() // 阻止事件冒泡到父元素
      event.preventDefault() // 阻止默认的跳转行为
    },
    addClickedClass3(event) {
      this.isClicked3 = true
      event.stopPropagation() // 阻止事件冒泡到父元素
      event.preventDefault() // 阻止默认的跳转行为
    }
  }
}
</script>
<style lang="less" scoped>
// .clicked {
//   /* 添加类名时的样式 */
//   display: none;
// }
.clicked2 {
  /* 添加类名时的样式 */
  display: none;
}

.clicked3 {
  /* 添加类名时的样式 */
  display: none;
}

.app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

/* .router-box {
  position: relative;
} */
.app-box1 {
  padding-bottom: 440px !important;
}

// .app-box2 {
//   padding-bottom: 240px !important;
// }

.app-box3 {
  background-color: #f5f5f5;
  padding-bottom: 170px !important;
}

.app-box5 {
  background-color: #f5f5f5;
  padding-bottom: 0px !important;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;

  // .img-box1 {
  //   position: fixed;
  //   top: 0;
  //   left: 50%;
  //   margin-left: -960px;
  //   width: 1920px;
  //   height: 70px;
  //   // background-color: #3177ff;
  //   z-index: 99999;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     display: block;
  //   }
  //   .cancle-icon {
  //     width: 30px;
  //     height: 30px;
  //     position: absolute;
  //     top: 50%;
  //     margin-top: -15px;
  //     right: 0;
  //     margin-right: 172px;
  //   }
  // }
  .img-box2 {
    position: fixed;
    bottom: 0;
    left: 50%;
    margin-left: -960px;
    width: 1920px;
    height: 70px;
    // background-color: #3177ff;
    z-index: 99999;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .cancle-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      margin-top: -15px;
      right: 0;
      margin-right: 172px;
    }
  }

  .img-box3 {
    width: 400px;
    height: 466px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -233px;
    margin-left: -200px;
    // background-color: #797a9f;
    z-index: 9999999999;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 20px 20px;
    }

    .cancle-icon {
      width: 34px;
      height: 34px;
      opacity: 1;
      position: absolute;
      top: -30px;
      right: -30px;
    }
  }
}

.clicked4 {
  background: transparent;
  height: 0px;
  width: 0px;
}
</style>
