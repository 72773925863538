<template>
  <div class="box">
    <div class="boxk"></div>
    <div :class="srcs == 2 ? 'box boxs' : 'box boxs'">
      <!-- 导航栏 -->
      <div class="nav_main">
        <div class="nav_til">
          <div class="welcome">hi~欢迎回来！</div>
          <div class="useropration">
            <div v-if="userInfo" @click="gopage(`/mysixth/mineNews`)" class="tilmessage">
              <img src="@/assets/img/homesixth/Group 3854.png" /> 消息
              <div class="xx" v-if="noRead > 0">({{ noRead }})</div>
            </div>
            <div v-if="!tok" class="shu"></div>
            <div v-if="!tok" @click="mss(2)">免费注册</div>
            <div class="shu"></div>
            <div class="login_nav" v-if="!tok" @click="mss(1)">
              您好，请登录
            </div>
            <div class="headersRight" v-if="tok">
              <div class="headersRights">
                <img @click="goNext(`/mysixth/myInfo`)" :src="ruleForm.img"
                  alt="" /><!-- <span>{{ ruleForm.nickName }}</span> -->
                <span class="loginOut" @click="clogin">退出</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hrcss"></div>
      <div class="nav_bot">
        <div class="nav_bot_msg">
          <div class="logo">
            <img v-if="logo" :src="logo" @click="shouy" fit="contain" />
          </div>
          <div class="nava">
            <el-menu menu-trigger="click" :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
              @select="handleSelect">
              <el-menu-item :index='`/gxGather/abroad/indexs`'>
                <div @click="chooseMeau()" class="menu-box">出国留学首页</div>
              </el-menu-item>
              <el-menu-item @click="chooseMeau(item)" v-for="(item, index) in mList" :key="index" :index="item.path">
                <div class="menu-box">{{ item.typeName }}</div>
              </el-menu-item>
              <!-- <el-menu-item index="/allcurriculum/typeclasssixth">
                <div class="menu-box">成人高考</div>
              </el-menu-item>
              <el-menu-item index="/VIP/VIPClasssixth">
                <div class="menu-box">开放教育</div>
              </el-menu-item> -->
            </el-menu>
          </div>
          <div class="phone">
            <div class="phoneimg">
              <div></div>
              <div>24小时</div>
            </div>
            <div class="phonenum">
              <div>客服热线：</div>
              <div>{{ userInfos.mobile }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 登录弹框 -->
      <el-dialog class="loginDialog" :close-on-click-modal="false" title="" :visible.sync="showLogin"
        :before-close="handleClose" append-to-body>
        <new-login v-if="showLogin" :key="newloginKey" @toHome="toHome" @toRegister="toRegister"
          @showForgot="showForgot"></new-login>
      </el-dialog>
      <!-- 注册弹框 -->
      <el-dialog :close-on-click-modal="false" class="loginDialog" title="" :visible.sync="showRegister" append-to-body
        :before-close="handleCloseRegister">
        <new-register v-if="showRegister" :key="registerKey" @handleShowLogin="handleShowLogin"></new-register>
      </el-dialog>
      <!-- 忘记密码弹框 -->
      <el-dialog class="loginDialog" title="" :close-on-click-modal="false" width="22%" :visible.sync="showForgotDialog"
        :before-close="handleCloseForgot">
        <for-got @dealForgot="dealForgot" :key="forgotKey"></for-got>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { loginOut } from "@/api/user";
import { Know } from "@/api/know";

let know = new Know();
import Bus from "@/api/bus";
import Vue from "vue";
import { getNewUserSendComponList } from "@/api/home";
import { selectTextbookCount } from "@/api/mykecheng";
import {
  setReady,
  getToken,
  getInfo,
  getDomainLogo,
  getDomain,
} from "@/api/cookies";
import newLogin from "../../login/newLogin.vue";
import newRegister from "../../login/newRegister.vue";
import forGot from "../../login/forget.vue";
export default {
  components: { newLogin, newRegister, forGot },

  data() {
    return {
      id: '',
      userInfo: null,
      couponTipsVisible: false,
      forgotKey: 0,
      newloginKey: 0,
      registerKey: 0,
      showForgotDialog: false,
      mustBack: null,
      showRegister: false,
      showLogin: false,
      activeIndex: "/gxGather/abroad/indexs",
      // 导航栏选中颜色样式
      // 输入框
      input: "",
      // 基本资料
      ruleForm: {},
      userInfos: {},
      // 是否有token
      tok: false,
      token: "",
      noRead: 0,
      userId: null,
      logo: "",

      loginInfo: null,
      ouponList: [],
      srcs: 2,
      mList: []
    };
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      this.activeIndex = newVal.path;
      this.userId = localStorage.getItem("userId");
      if (oldVal.path == "/login" || newVal.path == "/login") {
        this.token = getToken();
      }
      this.ruleForm = getInfo();
      know.getUnreadInformList(this.userId, undefined).then((res) => {
        this.noRead = res.msg;
      });
    },
  },
  async created() {
    this.id = localStorage.getItem('projectTypeId');
    this.activeIndex = this.$route.path;

    this.userInfo = getInfo();
    this.userInfos = getDomain();
    this.logo = getDomainLogo();
    this.userId = localStorage.getItem("userId");
    this.token = getToken();
    await this.selectProjectTypePullList()

    Bus.$on("readChane", () => {
      know.getUnreadInformList(this.userId, undefined).then((res) => {
        setReady(res.msg);
        this.noRead = res.msg;
      });
    });
    Bus.$on("handleLogin", (item) => {
      if (item) {
        this.cacheVal = item;
      }
      this.showLogin = true;
    });
    Bus.$on("toLoginPage", (bol) => {
      this.showLogin = bol;
    });
    Bus.$on("handleRegister", (bol) => {
      this.showRegister = bol;
    });
    this.loginInfo = sessionStorage.getItem("loginInfo");
    this.$forceUpdate();
  },
  methods: {
    selectProjectTypePullList() {
      know.selectProjectTypePullList({ parentId: this.id }).then((res) => {
        res.data.forEach(e => {
          if (e.id == 31) {
            e.path = '/gxGather/abroad/index'
          } else if (e.id == 32) {
            e.path = '/gxGather/abroad/TwoT'
          } else if (e.id == 33) {
            e.path = '/gxGather/abroad/preparatory'
          }
        });
        this.mList = res.data;
        this.$forceUpdate()
      });
    },
    handleCloseForgot() {
      this.forgotKey += 1;
      this.showForgotDialog = false;
    },
    handleCloseRegister() {
      this.registerKey += 1;
      this.showRegister = false;
    },
    mss(index) {
      if (index == 1) {
        this.cacheVal = "";
        this.showLogin = true;
      }
      if (index == 2) {
        this.showRegister = true;
      }
    },
    dealForgot() {
      this.showForgotDialog = false;
      this.showLogin = true;
    },
    showForgot() {
      this.showForgotDialog = true;
      this.showLogin = false;
    },
    handleClose() {
      this.newloginKey += 1;
      if (this.cacheVal == "mustBack") {
        this.$router.go(-1);
      }
      this.showLogin = false;
    },
    toRegister() {
      this.cacheVal = "";
      this.showLogin = false;
      this.showRegister = true;
    },
    toHome() {
      this.cacheVal = "";
      this.showLogin = false;
    },
    handleShowLogin(bol) {
      this.showLogin = bol;
      this.showRegister = false;
    },
    gopage(path) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      this.$router.push(path);
    },
    /* 菜单切换 */
    handleSelect(key, keyPath) {
      let userInfo = getInfo();
      // for (let item of this.data.chil) {
      //   if (key == item.path) {
      //     this.data.name = item.name;
      //     this.data.path = item.path;
      //     break;
      //   }
      // }
      let routeUrl = this.$router.resolve({
        path: key,
      });
      // window.open(key, "_blank");
      // console.log(key, 'key');
      window.location.href = key;
      return false;
      if (key) {
        window.history.go(0);
      }
    },
    chooseMeau(item) {
      localStorage.removeItem('projectCategoryId');

      if (item) {
        localStorage.setItem('projectCategoryId', item.id);
      } else {
        localStorage.removeItem('projectCategoryId');
      }
    },
    // 点击logo图片跳转首页
    shouy() {
      this.$router.push({ path: "/" });
    },
    // 点击退出登录
    async clogin() {
      loginOut();
      localStorage.clear();

      this.$router.replace(`/`);
      window.location.reload();
    },
    searchClick() {
      if (location.pathname == "/search") {
        Bus.$emit("search", this.input);
      } else {
        this.$router.replace({ name: "搜索", params: { search: this.input } });
      }
    },
    handleScroll() {
      var yheight1 = window.pageYOffset;
      if (yheight1 > 0) {
        this.srcs = 1;
      } else {
        this.srcs = 2;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less">
.el-menu--popup {
  .el-menu-item {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171920;
    // line-height: 90px;
    border: none !important;
    cursor: pointer;
  }

  .is-active {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff4027 !important;
    border: none !important;
  }
}
</style>
<style lang="less" scoped>
.box {
  width: 100%;
  z-index: 9999;
  background: #fff;
  position: relative;

  // 导航栏
  .nav_main {
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    .nav_til {
      width: 1220px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;

      .welcome {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
      }

      .useropration {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        display: flex;
        justify-content: flex-start;

        >div {
          cursor: pointer;
        }

        .shop {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 12px;
          display: flex;
          align-items: center;
          margin-right: 20px;
          cursor: pointer;

          .iconfont {
            font-size: 12px;
            margin-right: 4px;
          }

          .count {
            color: #ff5206;
          }
        }

        .tilmessage {
          position: relative;
          display: flex;
          justify-content: flex-start;

          .noRead {
            height: 11px;
            background: #ff5206;
            border-radius: 2px;
            padding: 0px 2px;
            font-size: 9px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 11px;
            color: #ffffff;
            position: absolute;
            top: 3px;
            right: 23px;
            width: 18px;
          }

          img {
            width: 12px;
            height: 12px;
            margin-top: 14px;
            margin-right: 4px;
          }
        }

        .login_nav {
          color: #1061ff;
        }
      }

      .shu {
        width: 1px;
        height: 8px;
        background: #cccccc;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-top: 16px;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }

  .hrcss {
    width: 100%;
    height: 1px;
    background: #dddddd;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .nav_bot {
    width: 100%;
    height: 78px;
    // background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    .nav_bot_msg {
      width: 1220px;
      margin: 0 auto;
      display: flex;
      position: relative;

      .logo {
        // width: 102px;
        height: 40px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-top: 19px;

        img {
          height: 40px;
          margin-right: 80px;
          cursor: pointer;
          flex-shrink: 0;
        }
      }

      .sear {
        position: relative;
        margin-top: 21px;
        margin-left: 80px;

        /deep/ .el-input {
          position: relative;

          input {
            width: 160px;
            height: 36px;
            background: #ffffff;
            border-radius: 22px 22px 22px 22px;
            opacity: 1;
            border: 1px solid #1061ff;
          }

          .el-input-group__append {
            position: absolute;
            top: 0;
            right: 0;
            width: 36px;
            height: 36px;
            background: #1061ff;
            border-radius: 0px 22px 22px 0px;
            opacity: 1;
            color: #ffffff;
            border: 1px solid #1061ff;

            .el-icon-search {
              position: absolute;
              top: 10px;
              right: 11px;
              border: 1px solid #1061ff;
            }
          }

          // color: #cccccc;
        }

        // 修改输入框预输入文字
        input::-webkit-input-placeholder {
          color: #ccc;
        }
      }

      .phone {
        min-width: 166px;
        height: 46px;
        display: flex;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        margin-top: 16px;

        .phoneimg {
          width: 46px;
          height: 46px;
          background: #ffecdb;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;

          >div:nth-child(1) {
            width: 15px;
            height: 15px;
            background-image: url("~@/assets/img/homesixth/Frame.png");
            opacity: 1;
            margin: 0 auto;
            margin-top: 7px;
          }

          >div:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ff7600;
            text-align: center;
            margin-top: 4px;
          }
        }

        .phonenum {
          margin-left: 12px;

          >div:nth-child(1) {
            margin-top: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }

          >div:nth-child(2) {
            margin-top: 4px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }

  .nav {
    // width: 1920px;
    height: 44px;
    background: #2868e5;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    /deep/ .el-menu-demo {
      width: 1220px;
      margin: 0 auto;
      background: #2868e5;
    }

    /deep/.el-menu--horizontal>.el-menu-item {
      height: 44px;
      line-height: 44px;
      // width: 88px;
      height: 44px;
      background: #2868e5;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      padding: 0 28px;
    }

    /deep/.el-menu--horizontal {
      border-bottom: 0;
    }

    /deep/.menu-box {
      z-index: 999;
    }

    /deep/.is-active {
      background: rgba(0, 0, 0, 0.2) !important;
      color: #ffffff !important;
    }
  }
}

/deep/.el-dialog {
  width: 480px;
  border-radius: 8px 8px 8px 8px;
}

/deep/.el-dialog__headerbtn {
  // margin-top: 20px;
}

/deep/.el-dialog__body {
  padding: 0 40px !important;
}

/* 订单消息 */
.msg-box {
  display: flex;
  margin: 0 16px;
  cursor: pointer;
  align-items: center;

  .iconfont {
    font-size: 12px !important;
    color: #a8abbe;
    margin-right: 4px;
  }

  .msg-item {
    margin-right: 25px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }

  // 右侧左盒子
  .headersRightLeft {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;

    .text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .noRead {
      height: 11px;
      background: #ff5206;
      border-radius: 2px;
      padding: 0px 2px;
      font-size: 9px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 11px;
      color: #ffffff;
      position: absolute;
      top: -9px;
      right: -10px;
      width: 18px;
    }
  }
}

// 右侧 有token时
.headersRight {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .loginOut {
    margin-left: 8px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    cursor: pointer;
  }

  // 右侧右盒子
  .headersRights {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 34px;
      height: 34px;
      background: #0b5fa8;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

// 右侧 无token时
.toke {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    opacity: 1;
    margin-right: 12px;
    border-radius: 50%;
  }

  // 登录
  .spanL {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    cursor: pointer;
  }

  // 竖线
  div {
    width: 1px;
    height: 11px;
    background: #999999;
    margin: 0px 16px;
  }

  // 注册
  .spanR {
    width: auto;
    height: auto;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff4224;
    cursor: pointer;
  }
}

// 退出登录
.tuichu {
  position: absolute;
  width: 100px;
  height: 30px;
  top: 36px;
  right: 305px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
}

.xx {
  color: #ff5206;
}

.nava {
  height: 28px;
  line-height: 28px;
  margin-top: 24px;
  margin-left: 24px;

  /deep/ .el-menu-demo {
    // width: 1220px;
    margin: 0 auto;
    // background: #2868e5;
  }

  /deep/.el-menu--horizontal>.el-menu-item {
    height: 36px;
    line-height: 28px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 0 16px;
    padding: 0;
  }

  /deep/.el-menu--horizontal {
    border-bottom: 0;
  }

  /deep/.menu-box {
    z-index: 999;
  }

  /deep/.is-active {
    border-bottom: 2px solid #1061ff !important;
    color: #1061ff !important;
  }
}

.boxs {
  background: rgba(255, 255, 255, 0.96);
  position: fixed;
  top: 0;
  z-index: 9999;

  .nav_main {
    background: rgba(247, 247, 247, 0.96);
  }
}

.boxk {
  height: 119px;
}
</style>
