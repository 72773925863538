<template>
  <!-- 底栏 -->
  <div>
    <div class="brace" />
    <div class="foot">
      <div class="content">
        <div class="left_box">
          <div class="text_box">
            <img class="left-logo" :src="copyright.videoLogo" fit="contain">

            <div class="left_tag">大学生学习规划服务平台</div>
            <div class="green_line" />
            <div class="copyright-text" v-html="copyright.copyright"></div>
            <!-- <div class="tag m-b-17">北京聚学优科技有限公司</div> -->


            <!-- <div class="tag" @click="saveImg('https://beian.miit.gov.cn/')">
              鲁ICP备09085022号-24
            </div>
            <div class="tag" @click="saveImg('https://beian.mps.gov.cn/#/')">
              鲁公网安备37010102001592号
            </div> -->
            <!-- <a
              href="https://xyt.xcc.cn/getpcInfo?sn=1500773343215656960&language=CN&certType=8&url=*.juxueu.com"
              target="_blank"
              style="position: relative"
            >
              <div style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"></div>
              <embed
                src="https://program.xinchacha.com/web/1500773343215656960=*.juxueu.com.svg"
                width="103"
                height="38"
                type="image/svg+xml"
                pluginspage="//www.adobe.com/svg/viewer/install/"
            /></a> -->
          </div>
          <div class="line_box">
            <div class="vertical_line" />
          </div>
        </div>

        <div class="middle_box">
          <div class="item_wrap m_r_91">
            <div class="title">关于名跃教育</div>
            <div v-for="(item, index) in pathList" :key="index" class="item_name"
              :class="{ m_b_16: index != 3, on: path == item.url }" @click="open(item.url)">
              {{ item.text }}
            </div>
          </div>
          <div class="item_wrap m_r_98">
            <div class="title">其他</div>
            <div v-for="(item, index) in linkList" :key="index" class="item_name" :class="{ m_b_16: index != 3 }"
              @click="open(item.url)">
              {{ item.text }}
            </div>
          </div>
          <div class="item_wrap" style="width:128px">
            <div class="title">联系我们</div>
            <div class="item_name m_b_12">
              <i class="iconfont icon-telephone" />
              <span>{{ copyright.mobile }}</span>
            </div>
            <div class="item_name">
              <i class="iconfont el-icon-time" />
              <span>9:00-18:00</span>
            </div>
          </div>
        </div>

        <div class="right_box">
          <div class="title">官方链接</div>
          <div class="qr_code_box">
            <div class="wx_box">
              <div class="border_box">
                <img class="qr_code" :src="wxImg" alt="">
              </div>
              <div class="text">名跃教育官方公众号</div>
            </div>
            <div class="wx_box">
              <div class="border_box">
                <img class="qr_code" :src="appImg" alt="">
              </div>
              <div class="text">名跃教育官方APP</div>
            </div>
          </div>
        </div>

        <!-- <div class="content-left">
          <div class="flex-c" v-for="(item, index) in pathList" :key="index">
            <el-divider direction="vertical" v-if="index != 0"></el-divider>
            <div
              class="text"
              :class="path == item.url ? 'on ' : ''"
              @click="gopage(item.url, item.ifneed)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="box-title">400-6632-666</div>
          <div class="text">工作时间：9:00-18:00</div>
        </div> -->
      </div>

      <!--  <el-divider class="line m-t-36-i"></el-divider>
    <div class="link-box">
      <div class="link-text on">友情链接</div>
      <div class="link-text pointer" @click="open(item.url)" v-for="(item, index) in linkList" :key="index">
        {{ item.text }}
      </div>
    </div> -->

      <!-- <el-divider class="line"></el-divider>
      <div class="copyright-text">津ICP备2020009836号</div>
      <div class="copyright-text m-t-12">聚学云集团科技有限公司</div> -->
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getImagePage } from '@/api/home'
import { getDomain, getDomainLogo } from '@/api/cookies'
import { getInfo } from '@/api/cookies'
// import { JuxueU } from '@/api/JuxueU'
// const juxueU = new JuxueU()
export default {
  data() {
    return {
      path: '/',
      linkList: [
        // { text: '新里程网校', url: 'https://www.xlcwx.com/' },
        // { text: '聚学云官网', url: 'http://www.juxueyun.com/' },
        // { text: '聚学云网校', url: 'http://juxue.cc/' },
        // { text: '山东省教育招生考试院', url: 'http://www.sdzk.cn/' },
        { text: '意见反馈', url: '/seventhPage/my/feedback', ifneed: true },
        {
          text: '关于我们',
          url: '/seventhPage/my/accountSetting?type=4',
          ifneed: true
        }
      ],
      pathList: [
        { text: '网校首页', url: '/', ifneed: false },
        { text: 'VIP专属班', url: '/seventhPage/VIPClass', ifneed: true },
        // { text: '知识套餐', url: '/seventhPage/typeclass', ifneed: true },
        { text: '精品课程', url: '/seventhPage/courer', ifneed: true }
        // { text: '意见反馈', url: '/seventhPage/my/feedback', ifneed: true },
        // {
        //   text: '关于我们',
        //   url: '/seventhPage/my/accountSetting?type=4',
        //   ifneed: true
        // }
      ],
      list: [],
      logo: '',
      qyLogo: '',
      wxImg: '',
      appImg: '',
      timer: null,
      copyright: {}
    }
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      this.userInfo = getInfo()
      this.copyright = getDomain()
      this.logo = getDomainLogo()
      this.path = newVal.path
    }
  },
  async created() {
    this.path = window.location.pathname
    this.userInfo = getInfo()
    this.logo = getDomainLogo()
    this.copyright = getDomain()

    // this.getList()
  },
  mounted() {
    this.timer = setInterval(() => {
      getImagePage().then((res) => {

        for (const item of res.rows) {
          if (item.name == '聚学U客户端二维码') {
            this.appImg = item.url
          }
          if (item.name == '聚学U网校微信公众号二维码') {
            this.wxImg = item.url
          }
        }
      })
      if (this.wxImg && this.appImg) {
        clearInterval(this.timer)
        this.timer = null
      }
    }, 3000)
  },
  methods: {
    gopage(path, type) {
      if (this.path == path) {
        return
      }
      if (type) {
        // window.open(path, "_blank");
        this.$router.push(path)
      } else {
        /* this.$router.push(path); */
        // window.open(path, "_blank");
        this.$router.push(path)
      }
    },
    open(url) {
      window.open(url, '_blank')
    }

    /* 获取学院 */
    // getList() {
    //   juxueU
    //     .getPcCityEntrantsList
    //     /* 15, */
    //     ()
    //     .then((res) => {
    //       if (res.data) {
    //         this.list = res.data.slice(0, 4);
    //       }
    //     });
    // },
  }
}
</script>
<style lang="less" scoped>
// 底栏
.brace {
  width: 100%;
  height: 82px;
}

.foot {
  width: 100%;
  height: 252px;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  // margin-top: 20px;
  position: absolute;
  bottom: 0px;
  padding-top: 38px;

  display: flex;
  justify-content: center;

  .content {
    // width: 100%;
    display: flex;

    // margin-left: 150px;
    .left_box {
      flex-grow: 3;
      display: flex;
      align-items: flex-start;

      .text_box {
        max-width: 300px;

        .left-logo {
          // width: 161px;
          // transform: translateX(-16px);
          height: 61px;
        }

        .left_tag {
          color: #333;
          font-size: 18px;
          margin-top: 5px;
          margin-bottom: 10px;
        }

        .green_line {
          width: 29px;
          height: 2px;
          background-color: #00ab8c;
          margin-bottom: 18px;
        }

        .tag {
          color: rgba(0, 0, 0, 0.61);
          font-size: 12px;
        }

        .m-b-17 {
          margin-bottom: 14px;
        }
      }

      .line_box {
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: 20px;

        .vertical_line {
          height: 100%;
          width: 1px;
          background-color: rgba(0, 0, 0, 0.23);
          margin: 0 87px 0 83px;
        }
      }
    }

    .middle_box {
      display: flex;

      .item_wrap {
        color: #333;

        .title {
          font-size: 18px;
          margin-bottom: 27px;
          white-space: nowrap;
        }

        .item_name {
          font-size: 14px;
          color: #999999;
          cursor: pointer;
          white-space: nowrap;
        }

        .m_b_16 {
          margin-bottom: 16px;
        }

        .m_b_12 {
          margin-bottom: 11px;
        }

        .on {
          color: #999999;
        }

        .iconfont {
          font-size: 9px;
          color: #999;
          margin-right: 3px;
        }
      }

      .m_r_91 {
        margin-right: 109px;
      }

      .m_r_98 {
        margin-right: 98px;
      }
    }

    .right_box {
      margin-left: 80px;

      .title {
        color: #333;
        margin-left: 9px;
        font-size: 18px;
        margin-bottom: 27px;
      }

      .qr_code_box {
        display: flex;

        .wx_box {
          margin-right: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &:last-child {
            margin-right: 0px;
          }

          .border_box {
            // width: 100px;
            // height: 100px;
            // border-radius: 6px;
            // border: 1px solid #333;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            margin-bottom: 19px;

            .qr_code {
              width: 100px;
              height: 100px;
            }
          }

          .text {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }

    .content-left {
      display: flex;
      align-items: center;

      .on {
        color: #999999 !important;
      }

      .text {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333;
        line-height: 14px;
      }

      .el-divider {
        margin: auto 16px;
        width: 1px;
        height: 10px;
        background: #333;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }

    .content-right {
      .box-title {
        font-size: 28px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333;
        line-height: 28px;
      }

      .text {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333;
        line-height: 14px;
        margin-top: 12px;
      }
    }
  }

  .line {
    background: #474d59;
    margin: 36px 0px 12px;
  }

  /* 友情链接 */
  .link-box {
    display: flex;
    align-items: center;
    justify-content: center;

    .link-text {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333;
      line-height: 12px;
      padding: 0px 12px;
    }

    .on {
      color: #999999 !important;
    }
  }

  /* 版权 */
  .copyright-text {
    margin-top: 0px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333;
    line-height: 24px;
    text-align: left;
  }
}
</style>
