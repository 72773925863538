import Vue from "vue";
import Vuex from "vuex";
import { loginPcUser } from "@/api/login";
import { addToken, getToken, removeToken } from "../common/utils/token";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: getToken(),
    IsPurchase: false, // 学习卡下直播课是否可以观看
    collageList: null, // 单个学院时的学院数据
    count: undefined, // 购物车中的数量
  },
  getters: {
    token: (state) => state.token,
  },
  mutations: {
    addToken(state, token) {
      state.token = token;
      addToken(token);
    },
    IS_PURCHASE_CHANGE(state, IsPurchase) {
      state.IsPurchase = IsPurchase;
    },
    SET_COLLAGELIST(state, value) {
      state.collageList = value;
    },
    getNum(state, num) {
      state.count = num;
    },
  },
  actions: {
    loginPcUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        loginPcUser(data).then((res) => {
          if (res.code === 0) {
            commit("addToken", res);
            resolve();
          } else {
            reject(res);
          }
        });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        logout().then(() => {
          commit("addToken", "");
          removeToken();
          resolve();
        });
      });
    },
  },
  modules: {},
});
