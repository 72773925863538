<template>
  <div class="warp">
    <img class="loginback" src="@/assets/img/partner/zz1.png" alt="" />

    <div class="imgBox">

      <!-- <div class="logo">
        <img style="width: 100%" :src="domainInfo.videoLogo" alt="" />
      </div> -->
      <img class="loginbac" src="@/assets/img/partner/loginbac.png" alt="" />
    </div>
    <img class="off" style="width: 20px" src="@/assets/img/partner/off.png" alt="" @click="off" />

    <!--   -->
    <div class="boxc">
      <!--找回密码第一步  mima == 1-->
      <div class="boxcs" v-if="mima == 1">
        <!-- 标题 -->
        <!-- <div class="boxcsHead">找回密码</div> -->
        <div class="choice">
          <div class="choiceLeft" style="margin-right: 30px">
            <div class="choiceLeftText">重置密码</div>
            <div class="choiceLefts" />
          </div>

        </div>
        <!-- 虚线 -->
        <!-- <div class="line"></div> -->
        <!-- 内容 -->
        <div class="boxcsCent">
          <div class="boxcsCents">
            <span class="add86 colf2 shrink0">+86</span>
            <el-input v-model.trim="zhphone" placeholder="请输入手机号" style="width: 320px" @input="zhphoneVerify" />
          </div>
          <div class="boxcsCents">
            <!-- <span>验证码：</span> -->
            <el-input v-model.trim="zhimgCode" placeholder="请输入验证码" style="width: 210px" @input="zhimgCodeVerify" />
            <div style="border: 1px solid #000000;margin-right: 10px;">
              <img :src="captcha" alt="验证码" style="width: 100%; height: 100%" @click="refresh" />
            </div>
          </div>
          <div class="confirmBtn">
            <el-button :style="forgotBtnStatus ? 'background: #CCCCCC' : ''
              " :disabled="forgotBtnStatus" class="boxcsCentss" @click="imgb">确定</el-button>
          </div>
          <!-- 返回登录 -->
          <span @click="toLogin" class="toLogin">返回登录</span>
        </div>
      </div>
      <!--找回密码第二步  mima == 2-->
      <div class="boxcs" v-if="mima == 2">
        <!-- 标题 -->
        <!-- <div class="boxcsHead">找回密码</div> -->
        <!-- <div class="boxcsHead">找回密码</div> -->
        <div class="choice">
          <div class="choiceLeft" style="margin-right: 30px">
            <div class="choiceLeftText">重置密码</div>
            <div class="choiceLefts" />
          </div>
        </div>
        <!-- 虚线 -->
        <!-- <div class="line"></div> -->
        <!-- 内容 -->
        <div class="boxcsCent">
          <!-- <div class="boxcsCents"></div> -->
          <!-- 定位手机号 -->
          <div class="confirmPhone">
            <el-input :placeholder='`已验证手机：${zhphone}`' @input="zhphoneVerify" />

            <!-- <p></p>
            <span>{{ zhphone }}</span> -->
          </div>
          <div class="boxcsCents">
            <!-- <span>验证码：</span> -->
            <el-input v-model.trim="zhshortCode" placeholder="请输入验证码" style="width: 210px" />
            <button type="button" :class="time > 0 ? 'notClick' : ''" @click="hqYzm" :disable="disable"
              style="background: transparent;color:#0270e8;">
              {{ title }}
            </button>
          </div>
          <div class="confirmBtnSecond">
            <div :style="false ? 'background: #CCCCCC' : ''" class="boxcsCentss" @click="imgc">
              确定
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <!-- <div class="boxcsFoot">
          <div class="boxcsFoota">没有收到验证码</div>
          <div class="boxcsFootb">
            1.网络通信异常可能导致电话/短信丢失或延迟，请耐心等待或重新获取。
          </div>
          <div class="boxcsFootb">
            2.请核实是否手机欠费，或第三方软件屏蔽了系统短信。
          </div>
        </div> -->

        <!-- 定位图片 -->
        <!-- <div class="boxcsImga">
          <img src="@/assets/img/Login/loginIcon624.png" alt="" />
        </div>
        <div class="boxcsImgb">
          <img src="@/assets/img/Login/loginIcon715.png"  />
        </div>
        <div class="boxcsImgc">
          <img src="@/assets/img/Login/loginIcon626.png"  />
        </div>
        <div class="boxcsImgd">
          <img src="@/assets/img/Login/loginIcon627.png" alt="" />
        </div> -->
      </div>
      <!--找回密码第三步  mima == 3-->
      <div class="boxcs" v-if="mima == 3">
        <!-- 标题 -->
        <!-- <div class="boxcsHead">找回密码</div> -->
        <!-- <div class="boxcsHead">找回密码</div> -->
        <div class="choice">
          <div class="choiceLeft" style="margin-right: 30px">
            <div class="choiceLeftText">重置密码</div>
            <div class="choiceLefts" />
          </div>
        </div>
        <!-- 虚线 -->
        <!-- <div class="line"></div> -->
        <!-- 内容 -->
        <div class="boxcsCent">
          <div class="boxcsCents">
            <!-- <span>设置新密码：</span> -->
            <el-input v-model.trim="zhpassword" placeholder="请输入新密码" show-password style="width: 100%" />
          </div>
          <div class="boxcsCents">
            <!-- <span>确认密码：</span> -->
            <el-input v-model.trim="zhpasswords" placeholder="请再次确认新密码" show-password style="width: 100%" />
          </div>
          <div class="confirmBtnSecond">
            <div :style="false ? 'background: #CCCCCC' : ''" class="boxcsCentss" @click="imgd">
              确定
            </div>
          </div>
        </div>
      </div>
      <!--找回密码第四步  mima == 4-->
      <div class="boxcs" v-if="mima == 4">
        <!-- 标题 -->
        <!-- <div class="boxcsHead">找回密码</div> -->
        <div class="choice">
          <div class="choiceLeft" style="margin-right: 30px">
            <div class="choiceLeftText">重置密码</div>
            <div class="choiceLefts" />
          </div>
        </div>
        <!-- 虚线 -->
        <!-- <div class="line"></div> -->
        <!-- 内容 -->
        <div class="boxcsCent">
          <div class="zhQueren">
            <img src="@/assets/img/Login/loginIcon863.png" />
            <p>找回密码成功</p>
          </div>
          <div class="confirmBtnSecond">
            <div :style="false ? 'background: #CCCCCC' : ''" class="boxcsCentss" @click="goNext()">
              去登录
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { imagecode } from "@/api/register";
import { loginSmsCode } from "@/api/login";
import { Zhpassword } from "@/api/zhpassword";
import { getDomainInfo, getDomain } from '@/api/cookies'

let zhpassword = new Zhpassword();
// import{ mapActions } from 'vuex';
export default {
  components: {},
  data() {
    return {
      domainInfo: {}, // 机构信息

      forgotBtnStatus: true,
      mima: 1,
      disable: false,
      title: "获取验证码",
      time: 0,
      welcomePotential: "confirmToken",
      // 找回密码第一步 登录名
      zhphone: "",
      // 找回密码第一步 图形码
      zhimgCode: "",
      // 找回密码第二步 图形码
      zhimgCodes: "",
      // 找回密码第二步 验证码
      zhshortCode: "",
      // 找回密码第三步 新密码
      zhpassword: "",
      // 找回密码第三步 确认新密码
      zhpasswords: "",

      captcha: "",
    };
  },
  created() {
    this.domainInfo = getDomain()

    this.loadCaptcha();
  },
  mounted() {
    setTimeout(() => {
      if (!!this.zhphone && !!this.zhimgCode) {
        this.forgotBtnStatus = false;
      }
    }, 1000);
  },
  methods: {
    zhimgCodeVerify() {
      if (!!this.zhphone && !!this.zhimgCode) {
        this.forgotBtnStatus = false;
      } else {
        this.forgotBtnStatus = true;
      }
    },
    zhphoneVerify() {
      if (!!this.zhphone && !!this.zhimgCode) {
        this.forgotBtnStatus = false;
      } else {
        this.forgotBtnStatus = true;
      }
    },
    toLogin() {
      this.$emit("dealForgot");
    },
    // ...mapActions(['loginPcUser']),
    // 刷新验证码
    refresh() {
      this.loadCaptcha();
    },
    goNext() {
      this.$emit("dealForgot");
    },
    // 调用验证码接口方法
    loadCaptcha() {
      imagecode().then((res) => {
        this.captcha = `data: image/jpeg;base64,${btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;

        // this.captcha = res
      });
    },

    // 找回密码1 点击跳转2
    imgb() {
      if (this.zhphone == "") {
        this.$message.error("请输入手机号");
      } else if (this.zhimgCode == "") {
        this.$message.error("请输入验证码");
      } else {
        zhpassword.retrievePwStart(this.zhphone, this.zhimgCode).then((res) => {
          if (res.code == 0) {
            this.mima = 2;
          } else {
            this.zhimgCode = "";
            this.$message.error(res.msg);
          }
        });
        this.loadCaptcha();
      }
    },
    // 找回密码2 点击发送验证码
    hqYzm() {
      if (this.time > 0) {
        return;
      }
      loginSmsCode(this.zhphone, this.welcomePotential).then((res) => {
        if (res.code == 0) {
          this.time = 60;
          this.$message.success(res.msg);
          var www = setInterval(() => {
            if (this.time < 1) {
              this.title = "获取验证码";
              this.disable = false;
              clearInterval(www);
            } else {
              this.title = this.time-- + "秒后重发";
              this.disable = true;
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 找回密码2 点击跳转3
    imgc() {
      zhpassword
        .retrievePcSmspcWeb(this.zhphone, this.zhshortCode)
        .then((res) => {
          if (res.code == 0) {
            this.mima = 3;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 找回密码3 点击跳转4
    imgd() {
      if (this.zhpassword.length < 6) {
        this.$message.error("密码长度不能少于6个字符");
        return;
      }
      if (this.zhpassword == this.zhpasswords) {
        zhpassword
          .updatePwPassword(this.zhphone, this.zhpassword)
          .then((res) => {
            if (res.code == 0) {
              this.mima = 4;
              // localStorage.clear();
            }
          });
      } else {
        this.$message.error("请输入相同的密码");
      }
    },
    off() {

      this.$emit('closes')
    },
  },
};
</script>
<style lang="less" scoped>
.warp {
  position: relative;
  width: 443px;
  height: 450px;
  background: linear-gradient(180deg, #cce4ff 0%, #ffffff 100%);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  padding: 30px 30px 0;

  // height: 1080px;
  // 导航栏顶部灰条
  .header {
    width: 100%;
    height: 36px;
    // background: #f5f7fa;

    // 宽度缩放60%
    .headers {
      width: 65%;
      height: 100%;
      margin: auto;
      // border: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // 左侧
      ul {
        width: auto;
        height: 100%;

        li {
          float: left;
          line-height: 36px;
          margin-right: 30px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
      }

      // 右侧
      .headersRight {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }
    }
  }

  // 大盒子背景图 忘记密码 hzc=3
  .boxc {
    width: 100%;
    // padding-bottom: 40px;
    // height: 740px;
    // background: #fff;
    overflow: hidden;

    // 内容框
    .boxcs {
      // width: 1200px;
      // height: 630px;
      margin: auto;
      // margin-top: 40px;
      // background: #ffffff;
      overflow: hidden;
      position: relative;

      // 标题
      .boxcsHead {
        width: 80px;
        height: 18px;
        margin: 30px 0px 0px 30px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;
      }

      // 虚线
      .line {
        width: 960px;
        height: 1px;
        margin: auto;
        margin-top: 84px;
        background: #e3e3e3;
      }

      // 内容
      .boxcsCent {
        // width: 430px;
        // height: 240px;
        margin: auto;
        margin-top: 30px;

        // 输入框盒子
        .boxcsCents {
          width: 100%;
          height: 68px;
          margin-bottom: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff;
          border-radius: 10px;

          // span {
          //   width: auto;
          //   font-size: 14px;
          //   font-family: Microsoft YaHei;
          //   font-weight: 400;
          //   color: #27323f;
          // }
          div {
            width: 96px;
            height: 40px;
            display: flex;
            // margin-left: 14px;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff7f48;
            cursor: pointer;
          }

          button {
            outline: none;
            border: none;
            width: 96px;
            height: 40px;
            display: flex;
            margin-left: 14px;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff7f48;
            cursor: pointer;
            background: #fff;
          }
        }

        // 找回密码第四步确认图片盒子
        .zhQueren {
          width: 180px;
          height: 122px;
          margin: auto;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          img {
            width: 106px;
            // height: 40px;
          }

          p {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
          }
        }

        // 按钮
        .boxcsCentss {
          margin: 0 auto;
          width: 380px;
          height: 70px;
          background: linear-gradient(87deg, #0270e8 0%, #21b4f5 100%);
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }

      // 底部
      .boxcsFoot {
        margin-bottom: 30px;
        // width: 1140px;
        // height: 96px;
        margin: auto;
        // margin-top: 61px;
        background: #fff4ef;
        border-radius: 4px;
        overflow: hidden;

        .boxcsFoota {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27323f;
          margin: 20px 0px 10px 20px;
        }

        .boxcsFootb {
          width: auto;
          height: auto;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7b8196;
          margin: 0px 0px 3px 20px;
        }
      }

      // 定位手机号
      .phone {
        position: absolute;
        top: 213px;
        left: 397px;
        z-index: 99;
        width: 180px;
        height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27323f;

        p {
          width: auto;
          height: auto;
        }

        span {
          width: auto;
          height: auto;
        }
      }

      // 定位图片1
      .boxcsImga {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        left: 164px;
        z-index: 99;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片2
      .boxcsImgb {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        left: 432px;
        z-index: 99;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片3
      .boxcsImgc {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        right: 432px;
        z-index: 99;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 定位图片4
      .boxcsImgd {
        width: 68px;
        height: 68px;
        position: absolute;
        top: 98px;
        right: 164px;
        z-index: 99;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .diva {
    width: 96px;
    height: 40px;
    border: 1px solid #dddddd;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 获取验证码
  .divb {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a8abbe;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 2px;
    cursor: pointer;
  }

  .divb:hover {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    background: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #ff4027;
    color: #ff4027;
  }

  // 微信登录
  .weiXinName {
    width: 100%;
    height: 55px;
    text-align: center;
    line-height: 55px;
    font-size: 18px;
    margin-bottom: 17px;
  }

  // 扫描二维码
  .weiXinImg {
    width: 200px;
    height: 200px;
    border-radius: 2px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 二维码下方字体
  .weiXinNames {
    margin: auto;
    margin-top: 20px;
    text-align: center;
    width: 169px;
    height: 32px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b8196;
    line-height: 20px;
  }

  // 底部
  .weiXinFoot {
    margin: auto;
    margin-top: 33px;
    width: 380px;
    height: 70px;
    background: #f8f8f8;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    line-height: 70px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b8196;
    cursor: pointer;

    span {
      color: #ff4027;
    }
  }
}

.confirmPhone {
  display: flex;
  margin: 0 0 20px 0;
  // margin-left: 15px;
}

// /deep/.el-input__inner {
//   border: none !important;
//   background: #ffffff !important;
// }

/deep/.el-form-item__content {
  display: flex;
  height: 40px;
}

/deep/.el-form-item__error {
  // margin-left: 5%;
}

/deep/.el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  background-color: #ffffff !important;
}

.toLogin {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #2586f5;
  cursor: pointer;
  // margin-top: 20px;
}

// 密码、短信登录选择
.choice {
  // width: 85%;
  height: auto;
  margin: auto;
  // margin-top: 10px;
  display: flex;
  // justify-content: space-between;
  align-items: center;

  // 密码登录
  .choiceLeft {
    height: 40px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;

    .choiceLeftText {


      font-size: 22px;
      height: 26px;
      line-height: 26px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: bold;
      color: #0270e8;
    }

    .class1 {
      color: #0270e8;
      cursor: pointer;
    }

    .choiceLefts {
      // margin: auto;
      margin-top: 8px;
      width: 34px;
      height: 6px;
      background: linear-gradient(78deg, #0270e8 0%, #21b4f5 100%);
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
    }
  }



  // 分割线
  .choices {
    width: 1px;
    height: 16px;
    background: #dddddd;
    margin-bottom: 7px;
  }
}

.colf2 {
  // background: #ffffff;
}

.confirmBtn {
  padding: 20px 0;
}

.confirmBtnSecond {
  padding-bottom: 40px;
  margin-top: 60px;
}

.imgBox {
  position: absolute;
  left: -305px;
  bottom: 5px;
  width: 313px;
  height: 362px;

  .logo {
    margin: 0 auto;
    width: 134px;
    height: 35px;
    transform: translateY(-60px);
  }

  img {
    width: 100%;
  }
}

/deep/ .code {
  width: 380px;
  height: 66px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #eeeeee;

  .el-input__inner {
    height: 63px;
    border: none !important;
  }
}

// 输入框
.el-input {
  width: 380px;
  // margin: 0 auto;
  // height: 46px;
  // margin-bottom: 10px;
}

/deep/.el-input__inner {
  width: 100%;
  height: 66px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 0;
}

/deep/.el-form-item__content {
  display: flex;
  height: 66px;
  line-height: 66px;
}

/deep/.el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  background-color: #ffffff !important;
}

.off {
  position: absolute;
  top: 20px;
  right: 20px;
}

.loginback {
  width: 250px;
  position: absolute;
  left: -260px;
  top: 0px;
}
</style>
