<template>
    <div class="certificateView">
        <banners class="banner" />
        <div style="width: 100%; background: #fff;">
            <news :id="id" class="banner" />

        </div>
        <major class="banner" />
        <academy v-for="(item, index) in mList" :key="index" :whichSchoolId="item.id" :schoolName="item.typeName"
            class="banner" />


    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
import banners from "./banner.vue";
import news from "./news.vue";
import major from "./major.vue";
import academy from "./academy.vue";
import FootersPartner from "@/components/footersPartner.vue";

export default {
    components: { banners, news, major, academy, FootersPartner },

    data() {
        return {
            id: '',
            mList: []
        };
    },
    created() {

        this.id = localStorage.getItem('projectTypeId');
        know.selectProjectTypePullList({ parentId: this.id }).then((res) => {
            this.mList = res.data;
        });
    },
    mounted() { },
    methods: {

    },
};
</script>

<style lang="less" scoped></style>