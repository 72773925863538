<template>
  <div class="training">
    <div class="content1"></div>
    <div class="content2">
      <div class="content2-cont content">
        <div class="item">团企人才培训</div>
        <div class="item">聚学U人才库</div>
        <div class="item">职业技能提升行动</div>
        <div class="item">知识服务跨界合作</div>
      </div>
      <div class="content-tips">
        <div>
          聚学U专注职业教育10余年，为学员职考助力；同时，依托教学优势，为企业培训制定专属方案，凭借行业资源，为职业人才和企业搭建桥梁，匹配就业。
        </div>
        <div>
          基于自身优势资源和服务学员、企业的目的，推出聚学U人才库，打造职考“培训+就业”一站式服务。
        </div>
        <div>
          与人才服务企业深化战略合作，融合转化，促进知识服务升级；聚焦新职业，推进职业技能提升，培养人才，促就业，促进经济发展。
        </div>
      </div>
      <div class="title" style="margin-top: 80px">
        <span class="blue">团企定制服务&nbsp;</span>达成双赢局面
      </div>
      <div class="content2-box content">
        <el-image class="imgShow" :src="require('@/style/image/training/talent7.png')" fit="cover" />
        <div class="box">
          12年来，聚学U一直坚持了解企业真实需求，针对企业做数据采集、分析，对企业发展现状、培训诉求与企业资质匹配程度进行诊断，
          制定契合企业发展战略的培训方案，帮助企业培养各类人才。
          目前，已形成众多企业常年、多次（期）、多项目与聚学U合作的双赢局面。
        </div>
      </div>
    </div>
    <!--   <div class="title">
      <span class="blue">聚学云人才库&nbsp;</span>职业人士进阶求职平台
    </div>  
    <div class="content3 content">
      <div class="item">
        <img class="item-icon" src="@/style/image/training/imgIcon1.png" />
        <div class="item-title">垂直领域</div>
        <div class="item-text">专注服务建工、消安、教 师、医卫、财经人才</div>
      </div>
      <div class="item-line"></div>
      <div class="item">
        <img class="item-icon" src="@/style/image/training/imgIcon2.png" />
        <div class="item-title">简单快捷</div>
        <div class="item-text">岗位更新及时、沟通效率 更高、内容更明确</div>
      </div>
      <div class="item-line"></div>
      <div class="item">
        <img class="item-icon" src="@/style/image/training/imgIcon3.png" />
        <div class="item-title">安全可靠</div>
        <div class="item-text">入住企业以及注册人员必 须经过审核才可入住</div>
      </div>
      <div class="item-line"></div>
      <div class="item">
        <img class="item-icon" src="@/style/image/training/imgIcon4.png" />
        <div class="item-title">合作基数大</div>
        <div class="item-text">聚学云教育合作内训企业 好评如潮</div>
      </div>
    </div>
    <div class="title">
      <span class="blue">推进</span>职业技能提升&nbsp;<span class="blue"
        >助力</span
      >人才服务升级
    </div>
    <div class="content4 content">
      <div class="item">
        <img class="item-icon" src="@/style/image/training/imgIcon5.png" />
        <div class="item-title">培养人</div>
        <img class="item-line" src="@/style/image/training/icon1.png" />
        <div class="item-text">
          聚学云教育，专注于建工、消安、教 师、医卫、财经、法考、公考、考研
          等领域职业资格考前辅导培训和职业 技能提升培训，为各垂直领域培养了
          一批批高素质专业人才。
        </div>
      </div>
      <div class="item">
        <img class="item-icon" src="@/style/image/training/imgIcon6.png" />
        <div class="item-title">助就业</div>
        <img class="item-line" src="@/style/image/training/icon1.png" />
        <div class="item-text">
          聚学云教育紧跟时代职业发展趋势和人才市场需求变化，通过发挥自身人才培养和行业资源优势，为学员和企业牵线搭桥，帮助解决学员的择业就业需求和企业单位的人才招聘及培养需求。
        </div>
      </div>
      <div class="item">
        <img class="item-icon" src="@/style/image/training/imgIcon7.png" />
        <div class="item-title">促发展</div>
        <img class="item-line" src="@/style/image/training/icon1.png" />

        <div class="item-text">
          聚学云教育凭借全国230余家直营分校和职考培训智囊团的深厚实力，服务广大职考人士，持续为企事业单位输送高素质专业人才，为整个职业教育行业良性发展提供支持，助推经济发展。
        </div>
      </div>
    </div> -->
    <div class="title">
      <span class="blue">推动</span>合作共赢&nbsp;<span class="blue">共谋</span>人才发展
    </div>
    <div class="content5 content">
      <div class="item">
        <img class="imgShow" src="@/style/image/teachIng/talent8.png" />
        <div class="item-title">聚学U行业升级落地交流沙龙会</div>
        <div class="item-line"></div>
        <div class="item-text">
          为客户提供专业的项目知识培训，手把手教您学习新项目知识、如何销课。复制招生成功方案，辅助解决招生中遇到的各类难题。<!-- 到的问题，提升企业竞争力。线上线下结合，培训管理数字化，培训有效果、更有结果 -->
        </div>
      </div>
      <div class="item">
        <img class="imgShow" src="@/style/image/teachIng/talent9.png" />
        <div class="item-title">聚学U行业升级落地交流沙龙会</div>
        <div class="item-line"></div>
        <div class="item-text">
          “平台+资源”型网校助力教育培训机构轻松转型。招生引流、线上教学、考试测评、管理运维全方位赋能教育机构实现线上转型。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  async created() {},
  mounted: {},
}
</script>
<style lang="less" scoped>
.training {
  width: 100%;
  background: #fff;
  padding-bottom: 120px;
  /* 标题 */
  .title {
    font-size: 48px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: 400;
    color: #333;
    line-height: 48px;
    text-align: center;
    margin-top: 100px;
    .blue {
      font-weight: bold;
      color: #7a4ce7;
    }
  }
  .content {
    width: 1080px;
    margin: 48px auto 0px;
    display: flex;
  }

  .content1 {
    width: 100%;
    height: 480px;
    background: url('~@/style/image/training/imgBg1.png');
    background-size: 100% 100%;
  }
  .content2 {
    width: 100%;
    height: 1000px;
    background: url('~@/style/image/training/imgBg2.png');
    padding-top: 20px;
    background-size: 100% 100%;
    .content2-cont {
      height: 64px;
      justify-content: space-between;
      align-items: center;
      .item {
        width: 25%;
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        text-align: center;
      }
    }
    .content-tips {
      width: 1080px;
      margin: 40px auto 0px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #372c53;
      line-height: 32px;
      text-indent: 2em;
    }
    .content2-box {
      margin-top: 48px;
      position: relative;
      // overflow: hidden;
      .imgShow {
        width: 800px;
        height: 450px;
        overflow: hidden;
        z-index: 100;
      }
      .box {
        position: absolute;
        width: 624px;
        height: 450px;
        border-radius: 0px 0px 0px 0px;
        border: 2px solid rgba(123, 76, 231, 0.3);
        top: 40px;
        right: 0px;
        z-index: 10;
        padding: 40px 36px 0px 380px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #372c53;
        line-height: 32px;
      }
    }
  }
  .content3 {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 72px;
    .item {
      width: 198px;
      .item-icon {
        width: 180px;
        height: 240px;
        margin: 0px auto;
      }
      .item-title {
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-top: 40px;
        text-align: center;
      }
      .item-text {
        margin-top: 20px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
      }
    }
    .item-line {
      width: 1px;
      height: 360px;
      background: #dddddd;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 0px 48px;
    }
  }
  .content4 {
    justify-content: space-between;
    margin-top: 72px;
    .item {
      width: 288px;
      .item-icon {
        width: 120px;
        height: 120px;
        margin: 0px auto;
      }
      .item-title {
        font-size: 32px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #7a4ce7;
        line-height: 32px;
        margin-top: 40px;
        text-align: center;
      }
      .item-line {
        width: 288px;
        height: 8px;
        margin-top: 24px;
      }
      .item-text {
        margin-top: 16px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #372c53;
        line-height: 32px;
      }
    }
  }
  .content5 {
    justify-content: space-between;
    .item {
      padding: 32px 32px 0px;
      position: relative;
      width: 520px;
      height: 475px;
      background: #f4f1fd;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      .imgShow {
        width: 456px;
        height: 256px;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
      }
      .item-title {
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #7a4ce7;
        line-height: 24px;
        margin-top: 24px;
      }
      .item-line {
        width: 456px;
        height: 1px;
        background: #7a4ce7;
        border-radius: 0px 0px 0px 0px;
        margin-top: 24px;
        opacity: 1;
      }
      .item-text {
        margin-top: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #372c53;
        line-height: 24px;
      }
      .item-but {
        position: absolute;
        right: 0px;
        bottom: 31px;
        width: 140px;
        height: 40px;
        background: linear-gradient(90deg, #7a4ce7 0%, #b495ff 100%);
        border-radius: 20px 0px 0px 20px;
        opacity: 1;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>
