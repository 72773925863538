<template>
    <div class="certificateView">
        <navigation3 :id="id" class="navigation3" />
        <router-view style="margin-top: 30px;" :id="id" class="router-box" :key="$route.fullPath" />
        <submit v-if="projectType == 0 && isShowzx && ifNew" :id="id" />
        <submit1 v-if="projectType == 1 && isShowzx && ifNew" :id="id" />
        <submit2 v-if="projectType == 5 && isShowzx && ifNew" :id="id" />

        <footer class="footer">
            <FootersPartner />
        </footer>
    </div>
</template>

<script>
import {
    getProjectClassList,
    getProjectClassLis,
    selectInformationPcList,
} from "@/api/home";
import { getDomain } from "@/api/cookies";
import navigation3 from "./nav.vue";
import FootersPartner from "@/components/footersPartner.vue";
import submit from "@/views/gxGather/school/pages/submit.vue";
import submit1 from "@/views/gxGather/graduate/submitschool.vue";
import submit2 from "@/views/gxGather/abroad/submitschool.vue";

export default {
    components: { navigation3, FootersPartner, submit, submit1, submit2 },
    data() {
        return {
            is_huadong: true,
            projectType: 0,
            isShowzx: true,
            ifNew: true
        };
    },
    created() {

        this.id = this.$route.query.id;
        this.projectType = localStorage.getItem('projectType');
        // this.getList();
        console.log(this.$route.path, 'this.$routethis.$routethis.$route');

        this.$router.beforeEach((to, from, next) => {
            this.ifNew = false
            // 在路由导航之前执行的操作
            console.log('从', from.path, '导航到', to.path);
            if (to.path == '/gxGathers/index') {
                this.isShowzx = false
            } else {
                this.isShowzx = true
            }
            this.$nextTick(() => {
                this.ifNew = true
            })
            next(); // 必须调用 next() 来继续路由导航
        });
        if (this.$route.path == '/gxGathers/index') {
            this.isShowzx = false
        }
    },
    mounted() { },
    methods: {

    },
};
</script>

<style lang="less" scoped>
.certificateView {
    background: #F5F7F9;
}
</style>