<template>
  <!-- 底栏 -->
  <div>
    <div class="foot">
      <div class="foot_top">
        <div>
          <p>便捷入口</p>
          <p class="lj" @click="goNext(`/`)">网校首页</p>
          <p class="lj" @click="goNext(`/allsixth/mycurriculumsixth`)">我的课程</p>
          <p class="lj" @click="goNext(`/allnews`)">新闻中心</p>
          <p class="lj" @click="zhuce()">免费注册</p>
        </div>
        <div>
          <p>产品中心</p>
          <p class="lj" @click="goNext(`/allcurriculum/typeclasssixth`)">课程</p>
          <p class="lj" @click="goNext(`/quesTion/quesTionListsixth`)">题库</p>
          <p class="lj" @click="goNext(`/data/dataPackagesixth`)">学习资料</p>
          <p class="lj" @click="goNext(`/mysixth/mineCoupon`)">优惠券</p>
        </div>
        <div>
          <p>关于我们</p>
          <p class="lj" @click="goNext(`/agreement`)">注册协议</p>
          <p class="lj" @click="goNext(`/mysixth/accountSetting?type=4`)">企业简介</p>
          <p class="lj" @click="goNext(`/mysixth/feedback`)">意见反馈</p>
        </div>
        <div>
          <p>更多推荐</p>
          <p class="lj" @click="goNext(`/jingpin/courersixth`)">精品课程</p>

          <p class="lj" @click="goNext(`/allcurriculum/typeclasssixth`)">知识套餐</p>
          <p class="lj" @click="goNext(`/VIP/VIPClasssixth`)">VIP专属班</p>
        </div>
        <div class="ewm">
          <p>扫一扫添加</p>
          <div>
            <img :src="copyright.wechatMpName" alt="" />
          </div>
        </div>
        <div class="fshu"></div>
        <div class="foeetbom">
          <div><img :src="copyright.qyLogo" alt="" /></div>
          <div>
            <div><img src="@/assets/img/homesixth/Group 3814.png" />{{ copyright.mobile }}</div>
            <div>工作时间：9:00-18:00</div>
          </div>
          <div>
            <div><img src="@/assets/img/homesixth/Group 3815.png" />{{ copyright.kcServer }}</div>
            <div>课程咨询和服务</div>
          </div>
        </div>
      </div>
      <!--   <div class="fbshu"></div>
    <div class="fblist">
      <a href="javascript:void(0);">友情链接</a>
      <a
        target="_blank"
        v-for="(item, index) in ljlist"
        :key="index"
        :href="item.url"
        >{{ item.name }}</a
      >
    </div> -->
      <div class="fbshu"></div>
      <p class="bq" v-html="copyright.copyright"></p>
      <!-- <p class="bq bqb">鲁ICP备10037155号</p> -->
      <div v-if="false">
        <div class="left">
          <el-image class="left-logo" :src="copyright.qyLogo" fit="contain"></el-image>
          <div class="left-line"></div>
          <!-- <div class="left-text">{{ copyright.name }}版权所有</div> -->
          <div class="left-text" v-html="copyright.copyright"></div>
        </div>
        <div class="font-line"></div>
        <div class="content">
          <div class="content-left">
            <div class="title">快速导航</div>
            <div class="text" :class="path == `/` ? 'on ' : ''" @click="gopage(`/`, false)">网校首页</div>
            <div
              class="text"
              :class="path == `/all/mycurriculum` ? 'on ' : ''"
              @click="gopage(`/all/mycurriculum`, true)"
            >
              我的课程
            </div>
            <div
              class="text"
              :class="path == `/my/accountSetting` ? 'on ' : ''"
              @click="gopage(`/my/accountSetting?type=4`, true)"
            >
              关于我们
            </div>
          </div>
          <div class="content-right">
            <div class="title">联系我们</div>
            <div class="box" v-if="copyright.mobile">
              <div class="box-title"><i class="iconfont icon-dianhua"></i>{{ copyright.mobile }}</div>
              <div class="text">工作时间：9:00-18:00</div>
            </div>
            <div class="box" v-if="copyright.kcServer">
              <div class="box-title"><i class="iconfont icon-youxiang"></i>{{ copyright.kcServer }}</div>
              <div class="text">课程咨询和服务</div>
            </div>
          </div>
        </div>
        <div class="right" v-if="copyright.wechatMpName">
          <!--      <div class="title">公众号</div> -->
          <div class="right-img">
            <el-image class="img" :src="copyright.wechatMpName" fit="contain"></el-image>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="footer-zhicheng"></div> -->
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getDomain } from '@/api/cookies'
import { getInfo } from '@/api/cookies'
export default {
  data() {
    return {
      copyright: {},
      userInfo: null,
      path: '/',
      ljlist: [
        { name: '新里程网校', url: 'https://www.xlcwx.com/' },
        { name: '聚学云官网', url: 'http://www.juxueyun.com/' },
        { name: '聚学云网校', url: 'http://juxue.cc/' },
        { name: '山东省教育招生考试院', url: 'http://www.sdzk.cn/' },
      ],
    }
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      this.userInfo = getInfo()
      this.copyright = getDomain()
      this.path = newVal.path
    },
  },
  async created() {
    this.path = window.location.pathname
    this.userInfo = getInfo()
    this.copyright = getDomain()
  },
  methods: {
    zhuce() {
      Bus.$emit('handleRegister', true)
    },
    gopage(path, type) {
      if (this.path == path) {
        return
      }
      if (type) {
        let userInfo = getInfo()
        if (!userInfo) {
          Vue.prototype.goLoginView(false)
          // Vue.prototype.goLoginView(false);
          return
        }
        window.open(path, '_blank')
      } else {
        /* this.$router.push(path); */
        window.open(path, '_blank')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.footer-zhicheng {
  width: 100%;
  height: 440px;
  background: none !important;
  flex-shrink: 0;
}
// 底栏
.foot {
  width: 100%;
  height: 440px;
  background: #232426;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 20px;
  padding-top: 48px;
  position: absolute;
  bottom: 0;
  .foot_top {
    width: 1220px;
    margin: 0 auto;
    display: flex;
    padding-left: 50px;
    margin-bottom: 24px;
    div {
      width: 144px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #888888;
        margin-bottom: 16px;
      }
      p:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .fshu {
      width: 1px;
      height: 180px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.1;
      margin-left: 80px;
      margin-right: 60px;
    }
    .ewm {
      text-align: center;
      div {
        width: 120px;
        height: 120px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin: 0 auto;
        padding-top: 10px;
        img {
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 8px;
          margin-left: 10px;
        }
      }
    }
    .foeetbom {
      width: 200px;
      > div:nth-child(1) {
        // width: 123px;
        height: 48px;
        img {
          // width: 123px;
          height: 48px;
        }
        // background: url('~@/assets/img/homesixth/fb.png');
      }
      > div:nth-child(2) {
        height: 48px;
        margin-top: 28px;
        div:nth-child(1) {
          width: 200px;
          height: 24px;
          line-height: 24px;
          display: flex;
          font-size: 16px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;

          img {
            margin-right: 8px;
          }
        }
        div:nth-child(2) {
          width: 200px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #cccccc;
          margin-top: 4px;
          padding-left: 32px;
        }
      }
      > div:nth-child(3) {
        height: 48px;
        margin-top: 20px;
        div:nth-child(1) {
          width: 200px;
          height: 24px;
          line-height: 24px;
          display: flex;
          font-size: 16px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;

          img {
            margin-right: 8px;
          }
        }
        div:nth-child(2) {
          width: 200px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #cccccc;
          margin-top: 4px;
          padding-left: 32px;
        }
      }
    }
  }
  .fbshu {
    width: 1120px;
    height: 1px;
    background: #888888;
    border-radius: 0px 0px 0px 0px;
    opacity: 0.1;
    margin: 0 auto;
  }
  .fblist {
    width: 1120px;
    height: 36px;
    line-height: 36px;
    margin: 0 auto;
    a {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #888888;
      margin-right: 24px;
    }
    a:nth-child(1) {
      color: #fff;
    }
  }
}
.lj {
  cursor: pointer;
}
.lj:hover {
  color: #1061ff !important;
}
.bq {
  text-align: center;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #cccccc;
  margin-top: 40px;
}
.bqb {
  margin-top: 12px;
}
</style>
