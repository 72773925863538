<template>
    <div class="mainbox">

        <div class="apply_box_til">报名咨询</div>
        <div class="form">
            <el-form class="centent_form" :rules="rules" ref="form" :model="form" label-position="left">
                <el-form-item label="学生姓名： " prop="name">
                    <el-input v-model="form.name" placeholder="您的称呼"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="phone">
                    <el-input v-model="form.phone" placeholder="手机号码"></el-input>
                </el-form-item>
                <el-form-item label="验证码：" prop="code">
                    <el-input v-model="form.code" placeholder="验证码">
                        <span class="yCode" slot="suffix" v-if="!downTime" @click="codeClick">获取验证码</span>
                        <el-statistic v-else slot="suffix" :value="countDown" time-indices format="ss"
                            @finish="timeFinish">
                            <template slot="suffix">s</template>
                        </el-statistic>
                    </el-input>
                </el-form-item>
                <el-form-item prop="majorId" label="专业：">
                    <el-select :disabled="majorId ? true : false" @change="schoolChange()" v-model="form.majorId"
                        placeholder="请选择">
                        <el-option v-for="(item, index) in countryList" :key="index" :label="item.majorName"
                            :value="item.majorId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="schoolId" label="学校：">
                    <el-select v-model="form.schoolId" placeholder="请选择">
                        <el-option v-for="(item, index) in schoolList" :key="index" :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="education" label="学历：">
                    <el-select v-model="form.education" placeholder="请选择">
                        <el-option label="专科" value="专科" />
                        <el-option label="本科无学位" value="本科无学位" />
                        <el-option label="本科有学位" value="本科有学位" />
                        <el-option label="硕士" value="硕士" />
                        <el-option label="博士" value="博士" />

                    </el-select>
                </el-form-item>
                <el-form-item class="query" label="报名疑问：" prop="query">
                    <el-input v-model="form.query" type="textarea" :rows="2" placeholder="疑问"></el-input>
                </el-form-item>
            </el-form>
            <div @click="submitClick" class="apply_box_btn">提交</div>
        </div>

    </div>
</template>

<script>
import { Know } from "@/api/know";
import { loginSmsCode } from "@/api/login";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},
    props: {
        intentionMajor: '',
        majorId: '',
    },
    data() {
        return {
            countDown: Date.now() + 1000 * 60,
            downTime: false,
            search: {
                pageSize: 8,
                pageNum: 1,
                projectTypeId: ''
            },
            form: {},
            rules: {
                name: [{ required: true, message: " ", trigger: "blur" }],
                examStage: [{ required: true, message: " ", trigger: "blur" }],
                phone: [
                    { required: true, message: " ", trigger: "blur" },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: " ",
                        trigger: "blur",
                    },
                ],
                code: [{ required: true, message: " ", trigger: "blur" }],
                majorId: [{ required: true, message: " ", trigger: "change" }],
                schoolId: [{ required: true, message: " ", trigger: "change" }],
                education: [{ required: true, message: " ", trigger: "change" }],

            },
            newsList: [],
            qusetionList: [],
            countryList: [],
            schoolList: [],
        };
    },
    created() {

        this.search.projectTypeId = localStorage.getItem("projectTypeId");
        if (this.intentionMajor && this.intentionMajor != 'undefined') {
            this.form.intentionMajor = this.intentionMajor
        }
        if (this.majorId && this.majorId != 'undefined') {
            this.form.majorId = this.majorId
        }
        know.getContentNewPcList({ ...this.search }).then((res) => {
            this.newsList = res.rows
        });
        know.getSchoolProblemPcList({ ...this.search }).then((res) => {
            this.qusetionList = res.rows
        });
        // if(this.majorId){

        // }
        know.getBriefsTuitionPcList({ pageSize: 999, pageNum: 1, projectType: this.projectType }).then((res) => {
            this.countryList = res.data
        });
        know.getSchoolList({ pageSize: 999, pageNum: 1, majorId: this.form.majorId ? this.form.majorId : undefined, projectType: this.projectType }).then((res) => {
            this.schoolList = res.data
        });
    },
    mounted() {


    },
    methods: {
        schoolChange() {
            this.form.schoolId = ''
            know.getSchoolList({ pageSize: 999, pageNum: 1, majorId: this.form.majorId ? this.form.majorId : undefined, projectType: this.projectType }).then((res) => {
                this.schoolList = res.data
            });
        },
        // 获取验证码
        async codeClick() {

            this.countDown = Date.now() + 1000 * 60;
            if (!this.form.phone) {
                this.$message.error("请先输入手机号");
                return;
            } else {
                const res = await loginSmsCode(this.form.phone, "welcomePotential");
                if (res.code === 0) {
                    this.$message.success(res.msg);
                    this.downTime = true;
                } else if (res.code === 500) {
                    this.$message.error("请勿频繁获取！");
                }
            }
        },
        // 倒计时结束
        timeFinish(val) {
            if (val) return (this.downTime = false);
        },
        // 立即提交
        submitClick() {
            this.$refs["form"].validate(async (valid) => {
                if (valid) {

                    const foundItem = this.countryList.find(item => item.majorId === this.form.majorId);
                    if (foundItem) {
                        this.form.intentionMajor = foundItem.majorName;
                    } else {
                        this.form.intentionMajor = ''
                    }

                    const foundItems = this.schoolList.find(item => item.key == this.form.schoolId);
                    if (foundItems) {
                        this.form.intentionSchool = foundItems.value;
                    } else {
                        this.form.intentionSchool = ''
                    }
                    const res = await know.addStudyAbroadUserConsultation({
                        studentName: this.form.name,
                        mobile: this.form.phone,
                        mobileToken: this.form.code,
                        majorId: this.form.majorId,
                        intentionMajor: this.form.intentionMajor,
                        schoolId: this.form.schoolId,
                        intentionSchool: this.form.intentionSchool,
                        education: this.form.education,
                        query: this.form.query,
                        consultationClassify: localStorage.getItem("consultationClassify"),
                        projectType: "1",
                    });
                    if (res.code === 0) {
                        this.$message.success(res.msg);
                        this.form = {}
                        this.downTime = false
                    } else {
                        this.$message.error(res.msg);
                    }
                } else {
                    this.$message.error("请将信息填写完整 ！");
                }
            });
        },

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
    background: linear-gradient(180deg, #FFFFFF 0%, #DBDFE3 100%);
    border-radius: 3px 3px 3px 3px;
    background: linear-gradient(180deg, #FFFFFF 0%, #E1EEFC 100%) !important;
    background-size: 100% 100%;

    .apply_box_til {

        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 20px;
        color: #1061FF;
        text-align: center;
    }


    .form {
        padding: 0 20px;
        margin: 38px 0;



    }



}

img {
    display: block;
    width: 100%;
    height: 100%;
}

.apply_box_btn {
    width: 138px;
    height: 40px;
    background: #1061FF;
    border-radius: 20px 20px 20px 20px;
    margin: 0 auto;
    margin-top: 20px;
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.centent_form {
    // margin-top: 57px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    // justify-content: center;

    /deep/ .el-form-item:nth-last-child(1) {
        margin-right: 0;
    }

    /deep/ .el-input__suffix {
        right: 16px !important;
    }

    /deep/ .el-form-item {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;

        .el-form-item__label {
            width: 120px;
            font-size: 18px;
            font-family: PingFangSC-Medium-, PingFangSC-Medium;
            font-weight: normal;
            color: #444444;
            letter-spacing: 1px;
            padding: 0;
            flex-shrink: 0;
        }

        .el-form-item__label::before {
            content: "";
            margin-right: 0;
        }

        .el-form-item__content {
            width: 160px;
            color: #848484 !important;

            // margin-left: 0px !important;
            .el-input {
                width: 100%;
                color: #848484 !important;

                .el-input__inner {
                    width: 160px;
                    height: 34px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #DDDDDD;
                    padding: 0 10px;
                }

                .yCode {

                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 12px;
                    color: #1061FF;
                    cursor: pointer;
                }

                .el-statistic {
                    margin: 13px 5px 0 0;
                }

                .number {
                    font-size: 12px;
                    font-family: PingFangSC-Regular-, PingFangSC-Regular;
                    font-weight: normal;
                    color: #333333;
                    letter-spacing: 1px;
                }
            }

            ::placeholder {
                color: #848484 !important;
            }

            // height: 30px;
            // border-radius: 30px;
        }
    }

    /deep/ .query {
        width: 1060px;

        .el-form-item__content {
            width: 100%;
            color: #848484 !important;

            // margin-left: 0px !important;
            .el-input {
                width: 100%;
                color: #848484 !important;

                .el-input__inner {
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #DDDDDD;
                    padding: 0 10px;
                }
            }
        }
    }
}
</style>