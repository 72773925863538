<template>
  <div class="mainbox">
    <div class="mainboxs">
      <div class="news_til">
        <div class="news_til_left">
          <div class="news_til_left_logo">
            <img src="~@/assets/img/TechnicalCollege/31.png" alt="">
          </div>
          <div class="news_til_left_til">专升本学历热门专业</div>
        </div>
        <div @click="goNext('/gxGather/majorList')" class="news_til_right">查看更多 <img
            src="~@/assets/img/TechnicalCollege/48.png" alt=""></div>
      </div>
      <div class="line"></div>
      <div class="major_box">
        <div class="major_box_til">
          <div :class="whichNews == index + 1 ? 'active' : ''" v-for="(item, index) in majorList" :key="index"
            @mouseenter="chooseMajor(index + 1, item)">
            <img v-if="whichNews == index + 1" style="width: 170px;height: auto;position: absolute;"
              src="~@/assets/img/TechnicalCollege/50.png" alt="">
            <span class="elp">{{ item.majorName }}</span>
          </div>
        </div>
        <div class="major_connent">
          <div class="certificate">
            <div class="certificate_til">{{ itemDetail.majorName }}</div>
            <div v-html="ImgSizeLimitclear(itemDetail.majorIntroductory)" class="certificate_content ">

            </div>
          </div>
          <div class="academy">
            <div class="academy_til">
              <div class="academy_til_left">
                <img style="width: 16px;height: auto;margin-right: 6px;" src="~@/assets/img/TechnicalCollege/108.png"
                  alt="">
                <div>{{ itemDetail.majorName }}</div>
                <div style="color: #1061FF;">招生院校</div>
              </div>
              <div @click="goNext('/gxGather/academy')" class="academy_til_right">查看更多 <img
                  style="width: 10px;height: auto;" src="~@/assets/img/TechnicalCollege/48.png" alt=""></div>
            </div>
            <div class="academy_list">
              <div class="item" @click="goNext(`/gxGathers/index?id=${item.id}`)" v-for="(item, index) in schoolList"
                :key="index">
                <div style="margin-right: 10px;flex-shrink: 0;"> <img style="width: 44px;height: 44px;"
                    :src="item.schoolBadge" alt="">
                </div>
                <div class="elp">{{ item.schoolName }}</div>
              </div>

            </div>

          </div>
          <div class="question">
            <div class="question_list">
              <div class="question_til">
                <img style="width: 16px;height: auto;margin-right: 6px;" src="~@/assets/img/TechnicalCollege/108.png"
                  alt="">
                <div>{{ itemDetail.majorName }}</div>
                <div style="color: #1061FF;">常见问题</div>
              </div>
              <div class="question_content">
                <div v-for="(item, index) in questionList" :key="index" class="question_content_item">
                  <div class="q_ask">
                    <div>Q</div>
                    <div style="width: 234px;" class="elp">{{ item.questionName }}</div>
                  </div>
                  <div class="q_answer">
                    <div>A</div>
                    <div style="width: 260px;" class="twoEllipsis">{{ item.questionAnswer }}</div>
                  </div>
                  <div style="width: 100%;
height: 0px;
border: 1px dashed  #EEEEEE; margin: 10px 0;"></div>
                </div>
              </div>
            </div>
            <div @click="goNext(`/gxGather/detailIndex?majorId=${itemDetail.majorId}`)" class="question_btn">进入{{
              itemDetail.majorName }} >></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
  components: {},
  data() {
    return {
      whichNews: 1,
      projectCategoryId: '',
      projectType: '',
      itemDetail: {},
      majorList: [],
      schoolList: [],
      questionList: [],

    };
  },
  created() {
    this.projectCategoryId = localStorage.getItem('projectCategoryId');
    this.projectType = localStorage.getItem('projectType');
    this.getEnrollBriefsTuitionPcList()
  },
  mounted() {

  },
  methods: {
    chooseMajor(i, item) {
      this.whichNews = i
      this.itemDetail = item
      this.selectAllSchoolPcList()
      this.selectMajorProblemPcList()
    },
    getEnrollBriefsTuitionPcList(i) {
      know.getEnrollBriefsTuitionPcList({
        projectType: this.projectType,
        projectCategoryId: this.projectCategoryId ? this.projectCategoryId : undefined
      }).then((res) => {
        if (res.data) {
          this.majorList = res.data.slice(0, 7)
          this.itemDetail = res.data[0]
          this.selectAllSchoolPcList()
          this.selectMajorProblemPcList()

        }
      });
    },
    selectAllSchoolPcList() {
      know.selectAllSchoolPcList({
        projectType: 0,
        pageSize: 10, pageNum: 1, majorId: this.itemDetail.majorId
      }).then((res) => {
        if (res.rows) {
          this.schoolList = res.rows
        }
      });
    },
    selectMajorProblemPcList() {
      know.selectMajorProblemPcList({ projectType: 0, pageSize: 10, pageNum: 1, majorId: this.itemDetail.majorId }).then((res) => {
        if (res.rows) {
          this.questionList = res.rows
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.mainbox {
  width: 100%;
  background: #fff;
  margin-bottom: 20px;

  .mainboxs {
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
  }

  .news_til {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .news_til_left {
      display: flex;
      align-items: center;

      .news_til_left_logo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #E9F4FF;
      }

      .news_til_left_til {
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-left: 5px;
      }
    }

    .news_til_right {
      cursor: pointer;
      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 14px;
      color: #777777;
      display: flex;
      align-items: center;

      img {
        width: 10px;
        height: 10px;
        margin-left: 4px;
      }
    }
  }

  .line {
    width: 1200px;
    height: 0px;
    border-bottom: 1px solid #DCDCDC;
    margin-top: 10px;
  }

  .major_box {
    width: 100%;
    margin-top: 30px;

    .major_box_til {
      display: flex;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);

      >div {
        width: 172px;
        height: 60px;
        border-right: 1px solid #C8D6ED;
        background: #FFFFFF;
        cursor: pointer;
        // padding: 0 10px;

        span {
          display: block;
          position: relative;
          z-index: 99;
          margin: 0 auto;
          text-align: center;
          line-height: 60px;

          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          padding: 0 10px;
        }
      }

      >div:last-child {
        border-right: 0;
      }

      .active {
        // background: #1061FF;
        border-radius: 4px 4px 4px 4px;

        span {
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
          font-weight: normal;
          font-size: 20px;
          color: #FFFFFF;
        }
      }

    }



    .major_connent {
      padding: 20px;
      box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.16);
      display: flex;
      justify-content: space-between;

      .certificate {
        width: 300px;
        height: 380px;
        background: url('~@/assets/img/TechnicalCollege/51.png');
        background-size: 100% 100%;

        .certificate_til {
          text-align: center;
          height: 38px;
          line-height: 38px;

          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
          font-weight: bold;
          font-size: 16px;
          color: #4A608B;
        }

        .certificate_content {
          width: 100%;
          height: 318px;
          padding: 20px;
          padding-left: 30px;
          overflow-y: scroll;

          .line {
            position: relative;
            width: 0px;
            height: 287px;
            border: 1px dotted #C1CCE2;

            >div {
              position: absolute;
              left: -5px;
              top: 0;
              width: 10px;
              height: 10px;
              background: #C1CCE2;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              >div {
                width: 6px;
                height: 6px;
                background: #4A608B;
                border-radius: 50%;

              }
            }
          }

          .certificate_content_right {
            margin-left: 10px;

            .c_tone {
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
              font-weight: bold;
              font-size: 14px;
              color: #4A608B;
              // margin-top: 5px;
              margin-top: 15px;
            }

            .c_tone:nth-child(1) {
              margin-top: 5px;
            }

            .c_cone {
              width: 236px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: normal;
              font-size: 12px;
              color: #555555;
              line-height: 20px;
              margin-top: 10px;
              height: 100px;
            }
          }
        }
      }

      .academy {
        width: 444px;

        .academy_til {
          display: flex;
          justify-content: space-between;
          align-items: center;

          >div {
            display: flex;
            align-items: center;

            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #444444;
          }

          .academy_til_right {
            font-family: PingFangSC-Regular,
              PingFangSC-Regular;
            font-weight: normal;
            font-size: 14px;
            color: #777777;
            cursor: pointer;
          }

        }

        .academy_list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          >div {
            width: 212px;
            height: 58px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #DDDDDD;
            display: flex;
            align-items: center;
            margin-top: 12px;
            transition: transform 0.6s ease;
            cursor: pointer;
            padding: 7px 14px;
          }

          >div:hover {
            transform: scale(1.1);
            /* 放大至1.1倍原大小 */
          }
        }
      }

      .question {
        width: 326px;

        .question_list {
          width: 326px;
          height: 304px;
          background: #FFFFFF;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #DDDDDD;
          padding: 15px;

          .question_til {
            display: flex;
            align-items: center;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #444444;
          }

          .question_content {
            width: 100%;
            height: 230px;
            margin-top: 22px;
            overflow: scroll;
            position: relative;

            .question_content_item {
              // animation: scroll 8s linear infinite;
              position: relative;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: normal;
              font-size: 13px;
              color: #333333;
              // margin-top: 20px;

              .q_ask {
                display: flex;
                align-items: center;

                >div:nth-child(1) {
                  width: 16px;
                  height: 16px;
                  background: #FECD61;
                  border-radius: 3px 3px 3px 3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 11px;
                  margin-right: 5px;
                }
              }

              .q_answer {
                display: flex;
                align-items: baseline;
                margin-top: 5px;

                >div:nth-child(1) {
                  width: 16px;
                  height: 16px;
                  background: #E3E3E3;
                  border-radius: 3px 3px 3px 3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 11px;
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .question_btn {
          width: 326px;
          height: 58px;
          background: #F0F5FF;
          border-radius: 29px 29px 29px 29px;
          border: 1px solid #85ADFC;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #1061FF;
          margin-top: 18px;
          cursor: pointer;
        }

      }
    }
  }
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
    /* 根据内容高度调整 */
  }
}
</style>