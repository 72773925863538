// 添加token
function addToken(token) {
  sessionStorage.setItem("_token", token);
}
// 获取token
function getToken() {
  return sessionStorage.getItem("_token");
}
// 移除token
function removeToken() {
  sessionStorage.removeItem("_token");
}

export { addToken, getToken, removeToken };
