<template>
  <div class="box">
    <div class="boxk" />
    <div :class="srcs == 2 ? 'box boxs' : 'box boxs'">
      <!-- 导航栏 -->
      <div class="navgit">
        <!-- 宽度缩放65% -->
        <div class="navgits">
          <!-- 导航栏开头logo -->

          <div class="left">
            <img v-if="logo" class="navgitsImg" :src="logo" @click="shouy" fit="contain" />
            <!-- 导航栏中间内容 -->
            <el-menu menu-trigger="click" :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
              @select="handleSelect">
              <el-menu-item index="/">
                <div class="menu-box">首页</div>
              </el-menu-item>
              <el-menu-item index="/publicClass">
                <div class="menu-box">公开课</div>
              </el-menu-item>
              <el-menu-item index="/allcurriculum/typeclass">
                <div class="menu-box">课程分类</div>
              </el-menu-item>
              <el-menu-item index="/VIP/VIPClass">
                <div class="menu-box">VIP专属班</div>
              </el-menu-item>

              <!-- <el-menu-item index="/all/mycurriculum">我的课程</el-menu-item> -->
              <!-- <el-menu-item index="/all/study">学习记录</el-menu-item> -->
              <!-- <el-menu-item :class="
                activeIndex == '/mall/material' ||
                  activeIndex == '/mall/sourcePage' ||
                  activeIndex == '/mall/orderView' ||
                  activeIndex == '/mall/orderCar'
                  ? 'is-active'
                  : ''
              " index="/mall">
                <div class="menu-box">教辅商城</div>
              </el-menu-item> -->

              <el-menu-item
                v-if="userId && (userId == '5384' || userId == '244078' || userId == '369461' || userId == '188819')"
                index="/zg">
                <div class="menu-box">
                  <el-popover style="z-index: 99999 !important;" placement="top-start" trigger="hover">
                    <div class="wrap">
                      <div @click="goRoute(item)" v-for="(item, index) in mList" :key="index">
                        {{ item.typeName }}
                      </div>
                    </div>
                    <div slot="reference">资格证
                      <i style="font-size: 16px; width: 16px; margin-right: 0;margin-left: 2px;"
                        class="el-icon-arrow-down"></i>
                    </div>
                  </el-popover>
                </div>
              </el-menu-item>
              <el-menu-item v-else index="/agent/scroll">
                <div class="menu-box">资格证</div>
              </el-menu-item>
              <!-- <el-menu-item index="/all/mycurriculum">我的课程</el-menu-item> -->
              <!-- <el-menu-item index="/all/study">学习记录</el-menu-item> -->
              <el-menu-item index="/faculty">
                <div class="menu-box">师资力量</div>
              </el-menu-item>
              <el-menu-item :class="activeIndex == '/all/mydata' ||
                activeIndex == '/all/myexamination' ||
                activeIndex == '/all/myRoom' ||
                activeIndex == '/all/study'
                ? 'is-active'
                : ''
                " index="/all/mycurriculum">
                <div class="menu-box">学习中心</div>
              </el-menu-item>
              <!--   <el-submenu :index="data.path">
            <template slot="title">{{ data.name }}</template>
<el-menu-item v-for="(chil, chilIndex) in data.chil" :key="chilIndex" :index="chil.path">
  {{ chil.name }}
</el-menu-item>
</el-submenu> -->
              <el-menu-item :class="activeIndex == '/my/mineOrder' ||
                activeIndex == '/my/mineCoupon' ||
                activeIndex == '/my/mineExchange' ||
                activeIndex == '/my/feedback' ||
                activeIndex == '/my/accountSetting' ||
                activeIndex == '/my/mineNews' ||
                activeIndex == '/my/collect'
                ? 'is-active'
                : ''
                " index="/my/myInfo">
                <div class="menu-box">个人中心</div>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="right">
            <!-- 导航栏右侧输入框 -->
            <!--    <div class="navgitsBox">
            <input placeholder="请输入要搜索的内容" type="text" v-model="input" @keyup.enter="searchClick" />
            <div class="navgitsSearchImg" @click="searchClick">
              <i class="iconfont icon-sousuo"></i>
            </div>
          </div> -->
            <!-- <div class="pointer" @click="searchClick">搜索</div> -->
            <!-- 消息 订单 -->
            <div class="msg-box">
              <!-- <div class="msg-item" @click="gopage(`/my/mineOrder`)">
                <i class="iconfont icon-a-Group1530" />
                <div v-if="noRead > 0" class="noRead">{{ noRead }}</div>
              </div> -->
              <div v-if="userInfo" class="headersRightLeft" @click="gopage(`/mall/orderCar`)">
                <div class="text">
                  <i class="iconfont icon-a-Group1530" />
                </div>

                <div v-if="$store.state.count > 0" class="noRead">
                  {{ $store.state.count }}
                </div>
              </div>
              <div v-if="userInfo" class="headersRightLeft">
                <div class="text" @click="gopage(`/my/mineNews`)">
                  <i class="iconfont icon-xiaoxi" />
                </div>
                <div v-if="noRead > 0" class="noRead">{{ noRead }}</div>
              </div>
            </div>
            <!------------------------------------------ 登陆注册---------------------------------------- -->
            <div v-if="this.tok" class="headersRight">
              <div class="headersRights">
                <img :src="ruleForm.img" alt=""
                  @click="goNext(`/my/myInfo`)" /><!-- <span>{{ ruleForm.nickName }}</span> -->
                <span class="loginOut" @click="clogin">退出</span>
              </div>
              <!--   <el-dropdown class="avatar-container hover-effect" trigger="click">
            <div class="headersRights" @click="goNext(`/my/myInfo`)">
              <img :src="ruleForm.img" alt="" />  <span>{{ ruleForm.nickName }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="clogin">
                <span style="display: block">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
            </div>
            <div v-if="!this.tok" class="toke" @click="mss(1)">
              <img class="icon" src="@/assets/img/nav/icon.png" alt="" />
              <span class="spanL">登录/注册</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 登录弹框 -->
      <el-dialog class="loginDialog" :close-on-click-modal="false" title="" :visible.sync="showLogin"
        :before-close="handleClose">
        <new-login v-if="showLogin" :key="newloginKey" @toHome="toHome" @toRegister="toRegister"
          @showForgot="showForgot"></new-login>
      </el-dialog>
      <!-- 注册弹框 -->
      <el-dialog :close-on-click-modal="false" class="loginDialog" title="" :visible.sync="showRegister"
        :before-close="handleCloseRegister">
        <new-register v-if="showRegister" :key="registerKey" @handleShowLogin="handleShowLogin"></new-register>
      </el-dialog>
      <!-- 忘记密码弹框 -->
      <el-dialog class="loginDialog" title="" :close-on-click-modal="false" width="22%" :visible.sync="showForgotDialog"
        :before-close="handleCloseForgot">
        <for-got @dealForgot="dealForgot" :key="forgotKey"></for-got>
      </el-dialog>
      <!-- 赠送优惠券提示弹窗 -->
      <coupon-tips :couponTipsVisible="couponTipsVisible" :ouponList="ouponList"
        @couponTipsClose="couponTipsClose"></coupon-tips>
    </div>
  </div>
</template>
<script>
import { loginOut } from "@/api/user";
import { Know } from "@/api/know";
import couponTips from "./couponTips.vue";
let know = new Know();
import Bus from "@/api/bus";
import Vue from "vue";
import { getNewUserSendComponList } from "@/api/home";
import { setReady, getToken, getInfo, getDomainLogo } from "@/api/cookies";
import newLogin from "../views/login/newLogin.vue";
import newRegister from "../views/login/newRegister.vue";
import forGot from "../views/login/forget.vue";
import { selectTextbookCount } from "@/api/mykecheng";
export default {
  components: { newLogin, newRegister, forGot, couponTips },
  data() {
    return {
      userInfo: null,
      couponTipsVisible: false,
      forgotKey: 0,
      newloginKey: 0,
      registerKey: 0,
      showForgotDialog: false,
      mustBack: null,
      showRegister: false,
      showLogin: false,
      activeIndex: "/",
      // 导航栏选中颜色样式
      // 输入框
      input: "",
      // 基本资料
      ruleForm: {},
      // 是否有token
      tok: false,
      token: "",
      noRead: 0,
      userId: null,
      logo: "",
      data: {
        name: "我的题库",
        path: "/all/myexamination",
        chil: [
          { name: "学习资料", path: "/all/mydata" },
          { name: "我的题库", path: "/all/myexamination" },
          { name: "我的作业", path: "/all/myTask" },
        ],
      },
      loginInfo: null,
      ouponList: [],
      srcs: 2,
      shopNum: undefined,
      magnitude: undefined,
      mList: []
    };
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      this.activeIndex = newVal.path;
      this.userId = localStorage.getItem("userId");
      if (oldVal.path == "/login" || newVal.path == "/login") {
        this.token = getToken();
        if (this.token && this.token != "") {
          this.tok = true;
        } else {
          this.tok = false;
        }
      }
      if (oldVal.path == "/search") {
        this.input = "";
      }
      /*   { name: "学习资料", path: "/all/mydata" },
          { name: "我的题库", path: "/all/myexamination" },
          { name: "我的作业", path: "/all/myTask" }, */
      if (newVal.path == "/all/mydata") {
        this.data.name = "学习资料";
        this.data.path = "/all/mydata";
      } else if (newVal.path == "/all/myexamination") {
        this.data.name = "我的题库";
        this.data.path = "/all/myexamination";
      } else if (newVal.path == "/all/myTask") {
        this.data.name = "我的作业";
        this.data.path = "/all/myTask";
      }
      this.ruleForm = getInfo();
      know.getUnreadInformList(this.userId, undefined).then((res) => {
        this.noRead = res.msg;
      });
    },
  },
  async created() {
    this.userInfo = await getInfo();
    this.cacheVal = null;
    this.activeIndex = this.$route.path;
    this.logo = getDomainLogo();
    this.ruleForm = "";
    this.userId = localStorage.getItem("userId");
    this.token = getToken();
    this.ruleForm = getInfo();
    know.selectProjectTypeList().then((res) => {
      this.mList = res.data
      this.mList.unshift({ id: 0, typeName: '代理项目' })
    });
    know.getUnreadInformList(this.userId, undefined).then((res) => {
      setReady(res.msg);
      this.noRead = res.msg;
    });

    const shopRes = await selectTextbookCount();
    this.shopNum = shopRes.data;
    this.$store.commit("getNum", this.shopNum);

    if (this.token && this.token != "") {
      this.tok = true;
    } else {
      this.tok = false;
    }
    Bus.$on("readChane", () => {
      know.getUnreadInformList(this.userId, undefined).then((res) => {
        setReady(res.msg);
        this.noRead = res.msg;
      });
    });
    Bus.$on("handleLogin", (item) => {
      if (item) {
        this.cacheVal = item;
      }
      this.showLogin = true;
    });
    Bus.$on("toLoginPage", (bol) => {
      this.showLogin = bol;
    });
    Bus.$on("handleRegister", (bol) => {
      this.showRegister = bol;
    });
    this.loginInfo = sessionStorage.getItem("loginInfo");
    if (this.loginInfo == 1 && getInfo()) {
      /* this.getNewUserSendComponList() */
    }
    this.$forceUpdate();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    goRoute(item) {
      localStorage.setItem('projectTypeId', item.id);
      localStorage.setItem('consultationClassify', item.typeName);
      // localStorage.removeItem('consultationClassify');
      localStorage.removeItem('projectCategoryId');

      if (item.id == 0) {
        this.goNext('/agent/scroll')
      } else if (item.id == 2) {
        localStorage.setItem('projectType', 0);
        this.goNext(`/gxGather/indexs?id=${item.id}`)
      } else if (item.id == 30) {
        localStorage.setItem('projectType', 5);
        this.goNext(`/gxGather/abroad/indexs?id=${item.id}`)
      } else if (item.id == 7) {
        localStorage.setItem('projectType', 1);
        this.goNext(`/gxGather/graduate/indexs?id=${item.id}`)
      }


    },

    getNewUserSendComponList() {
      const data = {
        userid: getInfo().id,
      };
      getNewUserSendComponList(data).then((res) => {
        if (res.code == 0 && res.msg.length > 0) {
          this.couponTipsVisible = true;
        }
      });
    },
    /*  openCouponTips() {
      this.getNewUserSendComponList()
      this.couponTipsVisible = true

    }, */
    couponTipsClose() {
      this.couponTipsVisible = false;
    },

    handleCloseForgot() {
      this.forgotKey += 1;
      this.showForgotDialog = false;
    },
    handleCloseRegister() {
      this.registerKey += 1;
      this.showRegister = false;
    },
    mss(index) {
      if (index == 1) {
        this.cacheVal = "";
        this.showLogin = true;
        // this.$router.push("/login");
      }
      if (index == 2) {
        this.showRegister = true;
        // this.$router.push("/register");
      }
    },
    dealForgot() {
      this.showForgotDialog = false;
      this.showLogin = true;
    },
    showForgot() {
      this.showForgotDialog = true;
      this.showLogin = false;
    },
    handleClose() {
      this.newloginKey += 1;
      if (this.cacheVal == "mustBack") {
        this.$router.go(-1);
      }
      this.showLogin = false;
    },
    toRegister() {
      this.cacheVal = "";
      this.showLogin = false;
      this.showRegister = true;
    },
    toHome() {
      this.cacheVal = "";
      this.showLogin = false;
    },
    handleShowLogin(bol) {
      this.showLogin = bol;
      this.showRegister = false;
    },
    gopage(path) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      this.$router.push(path);
    },
    /* 菜单切换 */
    handleSelect(key, keyPath) {
      let userInfo = getInfo();
      for (let item of this.data.chil) {
        if (key == item.path) {
          this.data.name = item.name;
          this.data.path = item.path;
          break;
        }
      }
      if (!userInfo && (key == "/all/mycurriculum" || key == "/my/myInfo")) {
        Vue.prototype.goLoginView(false);
        return;
      }
      /*  this.data = {
        name: "我的题库",
        path: "/all/myexamination",
        chil: [
          { name: "学习资料", path: "/all/mydata" },
          { name: "我的题库", path: "/all/myexamination" },
          // { name: "我的作业", path: "/all/study" },
        ],
      }; */
      /* this.$router.push(key); */
      if (key == "/zg") {
        return
      }
      const routeUrl = this.$router.resolve({
        path: key,
      });
      window.open(key, "_blank");
    },
    // 点击logo图片跳转首页
    shouy() {
      this.$router.push({ path: "/" });
    },
    // 点击退出登录
    async clogin() {
      loginOut();
      localStorage.clear();

      window.location.reload();
      this.$router.replace(`/`);
    },
    searchClick() {
      if (location.pathname == "/search") {
        Bus.$emit("search", this.input);
      } else {
        this.$router.replace({ name: "搜索", params: { search: this.input } });
      }
    },
    handleScroll() {
      var yheight1 = window.pageYOffset;
      if (yheight1 > 0) {
        this.srcs = 1;
      } else {
        this.srcs = 2;
      }
    },
  },
};
</script>
<style lang="less">
.el-menu--popup {
  .el-menu-item {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #171920;
    // line-height: 90px;
    border: none !important;
    cursor: pointer;
  }

  .is-active {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff4027 !important;
    border: none !important;
  }
}
</style>
<style lang="less" scoped>
.box {
  width: 100%;
  /* height: 126px; */
  // height: 90px;
  background: #fff;
  position: relative;

  // 导航栏
  .navgit {
    width: 100%;
    height: 68px;

    // box-shadow: 0px 2px 8px 0px rgba(200, 200, 200, 0.4);
    // 宽度缩放60%
    .navgits {
      width: 1220px;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // 导航栏开头logo
      .navgitsImg {
        height: 40px;
        margin-right: 36px;
        cursor: pointer;
        flex-shrink: 0;
      }

      // 导航栏右侧输入框
      .navgitsBox {
        width: 200px;
        height: 36px;
        // background: #ffffff;
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        border: 1px solid #ff5e51;
        display: flex;
        align-items: center;
        overflow: hidden;

        /* margin-right: 32px; */
        .navgitsSearchImg {
          width: 40px;
          height: 36px;
          background-color: #ff4027;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon-sousuo {
            width: 16px;
            height: 16px;
            color: #ffffff;
          }
        }

        /deep/ input {
          // width: 300px;
          padding: 0 12px;
          width: 158px;
          height: 100%;
          outline: none;
          border: none;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #cccccc;
        }

        // 修改输入框预输入文字
        input::-webkit-input-placeholder {
          color: #ccc;
        }
      }
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }
  }
}

.el-menu-demo {
  width: 600px !important;
}

/deep/ .el-menu {
  width: 600px !important;
  border: none !important;
  margin-right: 24px !important;
  display: flex;
  flex-wrap: nowrap;

  .el-menu-item,
  .el-submenu__title,
  .el-menu-item {
    border: none !important;
    width: auto;
    height: auto;
    padding: 0px 12px;

    /* margin-right: 6px; */
    .menu-box {
      width: fit-content;
      margin: 10px auto;
      padding: 0px;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333;
      height: 40px;
      border-bottom: 2px solid #fff !important;
      line-height: 38px;
    }
  }

  .is-active,
  .is-active .el-submenu__title {
    border: none !important;

    .menu-box {
      color: #ff5d51 !important;
      border-bottom: 2px solid #ff5d51 !important;
    }
  }
}

/deep/.el-dialog {
  width: 480px;
  border-radius: 8px 8px 8px 8px;
}

/deep/.el-dialog__headerbtn {
  // margin-top: 20px;
}

/deep/.el-dialog__body {
  padding: 0 40px !important;
}

/* 订单消息 */
.msg-box {
  display: flex;
  margin: 0 16px;
  cursor: pointer;
  align-items: center;

  .iconfont {
    font-size: 12px !important;
    color: #a8abbe;
    margin-right: 4px;
  }

  .msg-item {
    margin-right: 25px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }

  // 右侧左盒子
  .headersRightLeft {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    margin-right: 20px;

    .text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .noRead {
      // height: 11px;
      background: #ff5206;
      border-radius: 2px;
      padding: 1px 3px;
      font-size: 9px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 11px;
      text-align: center;
      color: #ffffff;
      position: absolute;
      top: -11px;
      right: -10px;
      // width: 18px;
    }
  }

  .headersRightLeft:nth-last-child(1) {
    margin-right: 0;
  }
}

// 右侧 有token时
.headersRight {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .loginOut {
    margin-left: 8px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    cursor: pointer;
  }

  // 右侧右盒子
  .headersRights {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 34px;
      height: 34px;
      background: #0b5fa8;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

// 右侧 无token时
.toke {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    opacity: 1;
    margin-right: 12px;
    border-radius: 50%;
  }

  // 登录
  .spanL {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    cursor: pointer;
  }

  // 竖线
  div {
    width: 1px;
    height: 11px;
    background: #999999;
    margin: 0px 16px;
  }

  // 注册
  .spanR {
    width: auto;
    height: auto;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff4224;
    cursor: pointer;
  }
}

// 退出登录
.tuichu {
  position: absolute;
  width: 100px;
  height: 30px;
  top: 36px;
  right: 305px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
}

.boxs {
  background: rgba(255, 255, 255, 0.96);
  position: fixed;
  top: 0;
  z-index: 9999;
}

.boxk {
  height: 68px;
}

.wrap {
  >div {
    padding: 10px;
    cursor: pointer;

    &:hover {
      color: cornflowerblue;
    }
  }

}
</style>
