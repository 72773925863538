<template>
  <div style="display: flex;flex-direction: column; padding: 50px 0;">

    <div class="dis_flex_between" style="width: 100%">
      <el-input v-model="codePic" placeholder="请输入验证码" style="width: 80%" />
      <div class="diva">
        <img @click="codeObjs" :src="codeImg" alt="验证码">
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="close()">确 定</el-button>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { codeObj } from '@/api/register'
export default {
  components: {},
  data() {

    return {
      domainInfo: {}, // 机构信息
      codeKey: '',
      codePic: '',
      codeImg: '',
    }
  },

  created() {
    this.codeObjs()
  },
  methods: {
    codeObjs() {
      codeObj().then((res) => {
        this.codeImg = res.data.img
        this.codeKey = res.data.key
      })
    },
    close() {
      if (this.codePic && this.codeKey) {
        this.$emit('codeBack', this.codePic, this.codeKey)
      } else {
        this.$emit('codeBack')

      }
    }

  }
}
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
</style>
